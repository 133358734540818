import { Drawer, Form, Input, Select, Button, notification } from 'antd';
import { FC, useState } from 'react';
import { AddOnHandInventoryFormProps, AuthTokenType, DataProps, location, product, supplier } from '../utils/types';
import { getAuthToken } from '../utils/functions';
import axios, { AxiosResponse } from 'axios';
import { onhandUrl} from '../utils/network';
import { useGetLocation, useGetProduct, useGetProjects, useGetSupplier } from '../utils/hooks';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const { Option } = Select;

const AddOnHandInventoryForm: FC<AddOnHandInventoryFormProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose,
}) => {
  const { t } = useTranslation(); // Initialize the useTranslation hook
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<product[]>([]);
  const [locations, setLocations] = useState<location[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [project,setproject]=useState<any[]>([])
  const [selectedproject,setselectedProject]=useState<any[]>([])
  useGetProjects(setproject,setFetching)


  useGetProduct(setProducts, setFetching);
  useGetLocation(setLocations, setFetching);

  const onSubmit = async (values: DataProps) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

    try {
      const response: AxiosResponse = await axios.post(onhandUrl, values, headers);
      setLoading(false);

      if (response) {
        notification.success({
          message: t('Operation Success'), // Translate success message
          description: t('Inventory Added Successfully'), // Translate success description
        });
        onSuccessCallBack();
        form.resetFields();
      }
    } catch (error) {
      notification.error({
        message: t('Operation Error'), // Translate error message
      });
      setLoading(false);
      form.resetFields();
    }
  };

  const [fromlocation, setFromLocation] = useState<supplier[]>([]);
  useGetSupplier(setFromLocation, setFetching);

  return (
    <Drawer
      title={t('Add On Hand Inventory')} // Translate drawer title
      visible={isVisible}
      placement="right"
      onClose={onClose}
      footer={null}
    >
      <Form layout="vertical" onFinish={onSubmit} form={form}>
        <Form.Item label={t('Product')} name="sku_code_id">
          <Select>
            <Select.Option value="" disabled>{t('Select Product')}</Select.Option>
            {products.map(product => (
              <Select.Option key={product.id} value={product.id}>
                {product.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label={t('Location')} name="storage_location_id">
          <Select>
            <Select.Option value="" disabled>{t('Select Storage Location')}</Select.Option>
            {locations.map(location => (
              <Select.Option key={location.id} value={location.id}>
                {location.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={t('Project')} name="project_id">
          <Select>
            <Select.Option value="" disabled>{t('Select Project')}</Select.Option>
            {project.map(project => (
              <Select.Option key={project.id} value={project.id}>
                {project.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label={t('Supplier')} name="supplier_id">
          <Select>
            <Select.Option value="" disabled>{t('Select Supplier')}</Select.Option>
            {fromlocation.map(location => (
              <Select.Option key={location.id} value={location.id}>
                {location.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={t('Status')}
          name="status"
          rules={[{ required: true, message: t('Please Input Status of Inventory') }]}
        >
          <Input placeholder={t('Status')} type="status" />
        </Form.Item>

        <Form.Item
          label={t('Total Units')}
          name="totalunits"
          rules={[{ required: true, message: t('Please Input Total Units') }]}
        >
          <Input placeholder={t('Total Units')} type="totalunits" />
        </Form.Item>

        <Form.Item>
          <Button className="bbutton" htmlType="submit" type="primary" block loading={loading}>
            {t('Submit')}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddOnHandInventoryForm;