import React, { FC, useState, useEffect } from "react";
import { Button, Table, Modal, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useGetSupplier } from "../utils/hooks";
import { AuthTokenType, supplier } from "../utils/types";
import { getAuthToken, getSupplier } from "../utils/functions";
import AddSupplierForm from "../components/AddSupplierForm";
import axios from "axios";
import { suppliersUrl } from "../utils/network";
import ContentLayout from "../components/ContentLayout";
import ContentLayoutTabs from "../components/ContentLayoutTabs";

const Suppliers: FC = () => {
  const { t } = useTranslation(); // Translation hook

  const columns = [
    {
      title: t("ID"),
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Email"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("Address"),
      dataIndex: "address",
      key: "address",
    },
    {
      title: t("City"),
      dataIndex: "city",
      key: "city",
    },
    {
      title: t("Salesperson"),
      dataIndex: "sales_person_name",
      key: "sales_person_name",
    },
    {
      title: t("Salesperson Email"),
      dataIndex: "sales_person_email",
      key: "sales_person_email",
    },
    {
      title: t("Country"),
      dataIndex: "country",
      key: "country",
    },
    {
      title: t("Phone"),
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: t("Type"),
      dataIndex: "type",
      key: "type",
    },
 
    {
      title: t("Actions"),
      key: "actions",
      render: (text: string, record: supplier) => (
        <span>
          <a onClick={() => handleEdit(record)}>{t("Edit")}</a>
          <span> | </span>
          <a onClick={() => handleDelete(record.id)}>{t("Delete")}</a>
        </span>
      ),
    },
  ];

  const [suppliers, setSuppliers] = useState<supplier[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [formVisible, setFormVisible] = useState(false);
  const [editingSupplier, setEditingSupplier] = useState<supplier | null>(null);

  useGetSupplier(setSuppliers, setFetching);

  useEffect(() => {
    // When editingSupplier changes, open the form
    if (editingSupplier) {
      setFormVisible(true);
    }
  }, [editingSupplier]);


  const onCloseWithoutEditing = () => {
    setEditingSupplier(null); // Reset editingCategory when form is closed without editing    
  };

  const handleEdit = (record: supplier) => {
    setEditingSupplier(record);
  };

  const handleDelete = (supplierId: number) => {
    Modal.confirm({
      title: t("Confirm Deletion"),
      content: t("Are you sure you want to delete this supplier?"),
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;

          // Make a DELETE request to the API endpoint for deleting a supplier
          await axios.delete(`${suppliersUrl}/${supplierId}/`, headers);

          // After successful deletion, fetch updated suppliers
          getSupplier(setSuppliers, setFetching);
        } catch (error) {
          // Handle error if the deletion fails
          notification.error({
            message: "Delete Operation Error",
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: t("Yes"),
      cancelText: t("No"),
    });
  };

  const onCreateSupplier = () => {
    setFormVisible(true);
    setEditingSupplier(null); // Reset editingSupplier for new supplier
  };

  return (
    <ContentLayoutTabs // Wrap your content with ContentLayout component
      pageTitle={t("Suppliers")}
      buttontitle={t("Add Supplier")}
      setModalState={setFormVisible}
      dataSource={suppliers as any}
      columns={columns as any}
      fetching={fetching}
      includeFilters={true}
      model_name="supplier"
    >
      <AddSupplierForm
        onSuccessCallBack={() => {
          setFormVisible(false);
          getSupplier(setSuppliers, setFetching);
        }}
        isVisible={formVisible}
        onClose={() => setFormVisible(false)}
        editingSupplier={editingSupplier}
        onCloseWithoutEditing={onCloseWithoutEditing}
      />
    </ContentLayoutTabs>
  );
};

export default Suppliers;