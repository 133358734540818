import React, { FC, useEffect, useState } from "react";
import { Table, Button, Modal, notification, Tag } from "antd";
import { AuthTokenType, Shipment } from "../utils/types";
import { useGetShipments } from "../utils/hooks";
import { getAuthToken, getShipments } from "../utils/functions";
import axios from "axios";
import { shipmentURL, shipmentbulkURL } from "../utils/network";
import ContentLayout from "../components/ContentLayout";
import AddShipmentForm from "../components/AddShipmentForm";
import moment from "moment";
import { useTranslation } from "react-i18next";

const Shipments: FC = () => {
  const { t } = useTranslation(); // Initialize the useTranslation hook

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingShipment, setEditingShipment] = useState<Shipment | null>(null);
  const [shipments, setShipments] = useState<Shipment[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);

  const handleEdit = (shipment: Shipment) => {
    setEditingShipment(shipment);
    setDrawerVisible(true);
  };

  useGetShipments(setShipments, setFetching);
  useEffect(() => {
    // When editingProduct changes, open the form
    if (editingShipment) {
      setDrawerVisible(true);
    }
  }, [editingShipment]);

  const onCloseWithoutEditing = () => {
    setEditingShipment(null); // Reset editingCategory when form is closed without editing
  };

  const handleDelete = (shipmentId: number) => {
    Modal.confirm({
      title: t("Confirm Deletion"),
      content: t("Are you sure you want to delete this shipment?"),
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;

          // Make a DELETE request to the API endpoint for deleting a shipment
          await axios.delete(`${shipmentURL}/${shipmentId}`, headers);

          // After successful deletion, fetch updated shipments
          getShipments(setShipments, setFetching);
        } catch (error) {
          // Handle error if the deletion fails
          notification.error({
            message: t("Delete Operation Error"),
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: t("Yes"),
      cancelText: t("No"),
    });
  };

  const columns = [
    {
      title: t("Reference"),
      dataIndex: "reference",
      key: "reference",
      
    },


    {
      title: t("CI"),
      dataIndex: "ci",
      key: "ci",
    },
 
    {
      title: t("Supplier"),
      dataIndex: "supplier",
      key: "supplier",
    },
 
    {
      title: t("Total Containers"),
      dataIndex: "total_container",
      key: "total_container",
    },
 
    {
      title: t("Vessel Name"),
      dataIndex: "vessel_name",
      key: "vessel_name",
    },
 
    {
      title: t("Dispatch No"),
      dataIndex: "dispatch_no",
      key: "dispatch_no",
    },
 
    {
      title: t("DIN"),
      dataIndex: "din",
      key: "din",
    },

    {
      title: t("Comments"),
      dataIndex: "comments",
      key: "comments",
    },
    {
      title: t("Project"),
      dataIndex: "project1",
      key: "project1",
    },
    {
      title: t("Type of Material"),
      dataIndex: "type_of_material1",
      key: "type_of_material1",
      
    },
    {
      title: t("Custom Agency"),
      dataIndex: "custom_agency",
      key: "custom_agency",
    },
    {
      title: t("Bill of Lading"),
      dataIndex: "bill_of_lading",
      key: "bill_of_lading",
    },
    {
      title: t("Got Original BL?"),
      dataIndex: "got_original_bl",
      key: "got_original_bl",
      render: (gotOriginalBL: boolean) => (
        <Tag color={gotOriginalBL ? "green" : "red"}>
          {gotOriginalBL ? t("Yes") : t("No")}
        </Tag>
      ),
    },
    {
      title: t("Container ID"),
      dataIndex: "container_id",
      key: "container_id",
    },
    {
      title: t("Real ETD"),
      dataIndex: "real_etd1",
      key: "real_etd1",
    },
    {
      title: t("Real ETA"),
      dataIndex: "real_eta1",
      key: "real_eta1",
    },
    {
      title: t("Real Discharge"),
      dataIndex: "real_discharge1",
      key: "real_discharge1",
    },
    {
      title: t("Free Days"),
      dataIndex: "free_days",
      key: "free_days",
    },
    {
      title: t("Port of Discharge"),
      dataIndex: "port_of_discharge",
      key: "port_of_discharge",
    },
    {
      title: t("Finish Free Days"),
      dataIndex: "finish_free_days1",
      key: "finish_free_days1",
    },
    {
      title: t("Start Demurrage"),
      dataIndex: "start_demurrage1",
      key: "start_demurrage1",
    },
    {
      title: t("Daily Demurrage Cost"),
      dataIndex: "daily_demurrage_cost",
      key: "daily_demurrage_cost",
    },
    
    {
      title: t("Total Demurrage"),
      dataIndex: "total_demurrage",
      key: "total_demurrage",
    },
    {
      title: t("Return Date Available"),
      dataIndex: "return_avl",
      key: "return_avl",
      render: (return_avl: boolean) => (
        <Tag color={return_avl ? "green" : "red"}>
          {return_avl ? t("Yes") : t("No")}
        </Tag>
      ),
    },
    {
      title: t("Returned Date"),
      dataIndex: "returned_date1",
      key: "returned_date1",
    },
    {
      title: t("Demurrage until today"),
      key: "demurrage_until_today",
      render: (_: any, record: Shipment) => {
        const { return_avl, returned_date, start_demurrage, daily_demurrage_cost } = record;
        const currentDate = moment(return_avl && returned_date ? returned_date : new Date());
        const startDate = moment(start_demurrage);
        const daysDiff = currentDate.diff(startDate, "days");
        const demurrage = daysDiff * daily_demurrage_cost;

        return <span>{demurrage}</span>;
      },
    },
    {
      title: t("Actions"),
      key: "actions",
      render: (text: string, record: Shipment) => (
        <span>
          <a onClick={() => handleEdit(record)}>{t("Edit")}</a>
          <span> | </span>
          <a onClick={() => handleDelete(record.id)}>{t("Delete")}</a>
        </span>
      ),
    },
  ];



  const handleDeleteAll = async () => {
    Modal.confirm({
      title: t("Confirm Deletion"),
      content: t("Are you sure you want to delete all shipments?"),
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;

          // Make a DELETE request to the API endpoint for deleting all shipments
          await axios.delete(shipmentbulkURL, headers);

          // After successful deletion, fetch updated shipments
          getShipments(setShipments, setFetching);
        } catch (error) {
          // Handle error if the deletion fails
          notification.error({
            message: t("Delete Operation Error"),
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: t("Yes"),
      cancelText: t("No"),
    });
  };

  return (
    
    <ContentLayout
      pageTitle={t("Shipment Control")}
      buttontitle={t("Add Shipment")}
      setModalState={setDrawerVisible}
      dataSource={shipments as any}
      columns={columns as any}
      fetching={fetching}
      includeFilters={true}
      model_name="shipments"
      bulkDeleteFunction={handleDeleteAll} // Pass bulk delete function as prop
    >

      <AddShipmentForm
        onSuccessCallBack={() => {
          setDrawerVisible(false);
          getShipments(setShipments, setFetching);
        }}
        isVisible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        editingShipment={editingShipment}
        onCloseWithoutEditing={onCloseWithoutEditing}
      />  
    </ContentLayout>
  );
};

export default Shipments;