import { useContext,useEffect } from "react"
import { ActionTypes,AuthProps,UserType, purchaseProps, stocktransferProps } from "./types"
import { authHandler, getBudgetCodes,getBusinessLine,getCategory, getCategorydetails, getCeCos, getCurrency,getDeliveryTerms,getLocation, getOnhandInventory,getProcessCategorizations,getProcessSpecifications,getProduct, getProject, getProjectp, getRequirements, getRequirementsPivot, getRequirementsbuyer, getRequirementscecoowner, getRequirementscontroller, getRequirementsmanager, getSankey, getShipments, getStatuses, getSupplier, getTree, getUnitofmeasure, getUsers,getbuyers,getcecoowners,getdemmurage,getmanagers,getpurchase, getrequesters, getstocktransfer} from "./functions"
import { store } from "./store"




export const useAuth =async({errorCallBack,successCallBack}:AuthProps)=>{
    const{dispatch}=useContext(store)
    
    useEffect(()=>{
        const checkUser=async () => {
            const user: UserType|null=await authHandler()
            if(!user){
                if(errorCallBack){
                    errorCallBack()
                }
                return
            }
            if(successCallBack){
                dispatch({type:ActionTypes.UPDATE_USER_INFO,payload:user})
                successCallBack()
            }
            
        }
        checkUser()
    },[])
}


export const useGetUsers = (setUsers: (data: any) => void, setFetching: (val: boolean) => void) => {
  useEffect(() => {
    setFetching(true);
    getUsers(setUsers, setFetching);
  }, [setUsers, setFetching]);
};


export const useGetcecoowner = (setcecoowner: (data: any) => void, setFetching: (val: boolean) => void) => {
  useEffect(() => {
    setFetching(true);
    getcecoowners(setcecoowner, setFetching);
  }, [setcecoowner, setFetching]);
};


export const useGetmanagers = (setmanagers: (data: any) => void, setFetching: (val: boolean) => void) => {
  useEffect(() => {
    setFetching(true);
    getmanagers(setmanagers, setFetching);
  }, [setmanagers, setFetching]);
};

export const useGetbuyers = (setbuyers: (data: any) => void, setFetching: (val: boolean) => void) => {
  useEffect(() => {
    setFetching(true);
    getbuyers(setbuyers, setFetching);
  }, [setbuyers, setFetching]);
};

export const useGetrequesters = (setrequesters: (data: any) => void, setFetching: (val: boolean) => void) => {
  useEffect(() => {
    setFetching(true);
    getrequesters(setrequesters, setFetching);
  }, [setrequesters, setFetching]);
};

export const useGetCategory = (setCategory: (data: any) => void, setFetching: (val: boolean) => void) => {
    useEffect(() => {
      setFetching(true);
      getCategory(setCategory, setFetching);
    }, [setCategory, setFetching]);
  };
  
  export const useGetUnitofmeasure = (
    setUnitofmeasure: (data: any) => void,
    setFetching: (val: boolean) => void
  ) => {
    useEffect(() => {
      setFetching(true);
      getUnitofmeasure(setUnitofmeasure, setFetching);
    }, [setUnitofmeasure, setFetching]);
  };
  
  export const useGetCurrency = (setCurrency: (data: any) => void, setFetching: (val: boolean) => void) => {
    useEffect(() => {
      setFetching(true);
      getCurrency(setCurrency, setFetching);
    }, [setCurrency, setFetching]);
  };
  
  export const useGetLocation = (setLocation: (data: any) => void, setFetching: (val: boolean) => void) => {
    useEffect(() => {
      setFetching(true);
      getLocation(setLocation, setFetching);
    }, [setLocation, setFetching]);
  };
  

  export const useGetOnhandIventory = (setOnhandInventory: (data: any) => void, setFetching: (val: boolean) => void) => {
    useEffect(() => {
      setFetching(true);
      getOnhandInventory(setOnhandInventory, setFetching);
    }, [setOnhandInventory, setFetching]);
  };

  
  export const useGetSupplier = (setSupplier: (data: any) => void, setFetching: (val: boolean) => void) => {
    useEffect(() => {
      setFetching(true);
      getSupplier(setSupplier, setFetching);
    }, [setSupplier, setFetching]);
  };
  
  export const useGetProduct = (setProduct: (data: any) => void, setFetching: (val: boolean) => void) => {
    useEffect(() => {
      setFetching(true);
      getProduct(setProduct, setFetching);
    }, [setProduct, setFetching]);
  };
  
  export const useGetpurchase=(setpurchase:(data:purchaseProps[])=>void,setFetching:(val:boolean)=>void)=>{
    useEffect(()=>{
      getpurchase(setpurchase,setFetching)
    },[])
  
  }
  

  export const useGetstocktransfer=(setstocktransfer:(data:stocktransferProps[])=>void,setFetching:(val:boolean)=>void)=>{
    useEffect(()=>{
      getstocktransfer(setstocktransfer,setFetching)
    },[])
  
  }

  export const useGetProjects=(setProject:(data:any[])=>void,setFetching:(val:boolean)=>void)=>{
    useEffect(()=>{
      getProject(setProject,setFetching)
    },[])
  
  }


  export const useGetSankey=(setSankey:(data:any[])=>void,setFetching:(val:boolean)=>void)=>{
    useEffect(()=>{
      getSankey(setSankey,setFetching)
    },[])
  
  }


  export const useGetTree=(setTree:(data:any[])=>void,setFetching:(val:boolean)=>void)=>{
    useEffect(()=>{
      getTree(setTree,setFetching)
    },[])
  
  }


  export const useGetShipments=(setShipments:(data:any[])=>void,setFetching:(val:boolean)=>void)=>{
    useEffect(()=>{
      getShipments(setShipments,setFetching)
    },[])
  
  }

  export const useGetdemmurage=(setdemmurage:(data:any[])=>void,setFetching:(val:boolean)=>void)=>{
    useEffect(()=>{
      getdemmurage(setdemmurage,setFetching)
    },[])
  
  }




////Procurement

export const useGetBusinessLine=(setBusinessLine:(data:any[])=>void,setFetching:(val:boolean)=>void)=>{
  useEffect(()=>{
    getBusinessLine(setBusinessLine,setFetching)
  },[])

}



  export const useGetProcessCategorizations=(setProcessCategorizations:(data:any[])=>void,setFetching:(val:boolean)=>void)=>{
    useEffect(()=>{
      getProcessCategorizations(setProcessCategorizations,setFetching)
    },[])
  
  }


  export const useGetProcessSpecifications=(setProcessSpecifications:(data:any[])=>void,setFetching:(val:boolean)=>void)=>{
    useEffect(()=>{
      getProcessSpecifications(setProcessSpecifications,setFetching)
    },[])
  
  }

  export const useGetCeCos=(setCeCos:(data:any[])=>void,setFetching:(val:boolean)=>void)=>{
    useEffect(()=>{
      getCeCos(setCeCos,setFetching)
    },[])
  
  }


  export const useGetProjectp=(setProjectp:(data:any[])=>void,setFetching:(val:boolean)=>void)=>{
    useEffect(()=>{
      getProjectp(setProjectp,setFetching)
    },[])
  
  }

  export const useGetBudgetCodes=(setBudgetCodes:(data:any[])=>void,setFetching:(val:boolean)=>void)=>{
    useEffect(()=>{
      getBudgetCodes(setBudgetCodes,setFetching)
    },[])
  
  }

  export const useGetStatuses=(setStatuses:(data:any[])=>void,setFetching:(val:boolean)=>void)=>{
    useEffect(()=>{
      getStatuses(setStatuses,setFetching)
    },[])
  
  }

  export const useGetDeliveryTerms=(setDeliveryTerms:(data:any[])=>void,setFetching:(val:boolean)=>void)=>{
    useEffect(()=>{
      getDeliveryTerms(setDeliveryTerms,setFetching)
    },[])
  
  }


  export const useGetCategorydetails=(setCagtegorydetails:(data:any[])=>void,setFetching:(val:boolean)=>void)=>{
    useEffect(()=>{
      getCategorydetails(setCagtegorydetails,setFetching)
    },[])
  
  }

  export const useGetRequirements=(setRequirements:(data:any[])=>void,setFetching:(val:boolean)=>void)=>{
    useEffect(()=>{
      getRequirements(setRequirements,setFetching)
    },[])
  
  }


  
  export const useGetRequirementsbuyer=(setRequirementsbuyer:(data:any[])=>void,setFetching:(val:boolean)=>void)=>{
    useEffect(()=>{
      getRequirementsbuyer(setRequirementsbuyer,setFetching)
    },[])
  
  }

  export const useGetRequirementscontroller=(setRequirementscontroller:(data:any[])=>void,setFetching:(val:boolean)=>void)=>{
    useEffect(()=>{
      getRequirementscontroller(setRequirementscontroller,setFetching)
    },[])
  
  }

  export const useGetRequirementsmanager=(setRequirementsmanager:(data:any[])=>void,setFetching:(val:boolean)=>void)=>{
    useEffect(()=>{
      getRequirementsmanager(setRequirementsmanager,setFetching)
    },[])
  
  }


  export const useGetRequirementscecoowner=(setRequirementscecoowner:(data:any[])=>void,setFetching:(val:boolean)=>void)=>{
    useEffect(()=>{
      getRequirementscecoowner(setRequirementscecoowner,setFetching)
    },[])
  
  }









  export const useGetRequirementsPivot=(setRequirements:(data:any[])=>void,setFetching:(val:boolean)=>void)=>{
    useEffect(()=>{
      getRequirementsPivot(setRequirements,setFetching)
    },[])
  
  }
