import axios,{AxiosResponse} from "axios"
import {tokenName} from "./data"
import {BudgetCodeURL,CeCoURL, DeliveryTermURL, MeUrl,ProcessCategorizationURL,ProcessSpecificationURL,ProjectURL,RequirementURL,RequirementbURL,RequirementcURL,RequirementcoURL,RequirementgetViewSetURL,RequirementmURL,SankeyChartURL,StatusURL,TreeChartURL,UsersUrl, businesslineURL, buyersUrl, categoriesUrl, categorydetailsURL, cecoUrl, currenciesUrl,demmurageURL,locationsUrl, managersUrl, onhandUrl, productsUrl, projectsUrl, purchaseorderUrl,requestersUrl,shipmentURL,stocktransferorderUrl,suppliersUrl, unitofmeasuresUrl} from "./network"
import { AuthTokenType,CategoryDetailsprops,CeCo,DemurrageControl,OnhandInventoryProps,Pricebylocation,Projectp,Requirement,Shipment,UserType, category,product, purchaseProps, stocktransferProps} from "./types"
import { notification } from "antd"

import moment from "moment"

export const getAuthToken= ():AuthTokenType|null=>{
    const accessToken =localStorage.getItem(tokenName)
    if(!accessToken){
        return null
    }

    return {headers:{Authorization:`Bearer ${accessToken}`}}

}

export const logout =()=>{
    localStorage.removeItem(tokenName)
    window.location.href="/login"

}

export const authHandler=async ():Promise<UserType | null>=>{
    const headers=getAuthToken()
        if(!headers){
            return null
    }
    const response:AxiosResponse =await axios.get(MeUrl,headers).catch(
        (e)=>{}
    ) as AxiosResponse
    if(response){
        return response.data as UserType
    }
    return null

}


export const getUsers = async (
  setUsers: (data: any) => void,
  setFetching: (val: boolean) => void
) => {
  const headers = getAuthToken() as AuthTokenType;
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(UsersUrl, headers);
    setUsers(response.data);
  } finally {
    setFetching(false);
  }
};




export const getcecoowners = async (
  setUsers: (data: any) => void,
  setFetching: (val: boolean) => void
) => {
  const headers = getAuthToken() as AuthTokenType;
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(cecoUrl, headers);
    setUsers(response.data);
  } finally {
    setFetching(false);
  }
};


export const getmanagers = async (
  setUsers: (data: any) => void,
  setFetching: (val: boolean) => void
) => {
  const headers = getAuthToken() as AuthTokenType;
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(managersUrl, headers);
    setUsers(response.data);
  } finally {
    setFetching(false);
  }
};


export const getbuyers = async (
  setUsers: (data: any) => void,
  setFetching: (val: boolean) => void
) => {
  const headers = getAuthToken() as AuthTokenType;
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(buyersUrl, headers);
    setUsers(response.data);
  } finally {
    setFetching(false);
  }
};

export const getrequesters = async (
  setUsers: (data: any) => void,
  setFetching: (val: boolean) => void
) => {
  const headers = getAuthToken() as AuthTokenType;
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(requestersUrl, headers);
    setUsers(response.data);
  } finally {
    setFetching(false);
  }
};


export const getCategory = async (
  setCategory: (data: any) => void,
  setFetching: (val: boolean) => void
) => {
  const headers = getAuthToken() as AuthTokenType;
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(categoriesUrl, headers);

    const data=(response.data as category[]).map((item) => ({...item,
      par:item.parent?.name
    
    }))
    
    setCategory(data);
  } finally {
    setFetching(false);
  }
};

export const getUnitofmeasure = async (
  setUnitofmeasure: (data: any) => void,
  setFetching: (val: boolean) => void
) => {
  const headers = getAuthToken() as AuthTokenType;
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(unitofmeasuresUrl, headers);
    setUnitofmeasure(response.data);
  } finally {
    setFetching(false);
  }
};

export const getCurrency = async (
  setCurrency: (data: any) => void,
  setFetching: (val: boolean) => void
) => {
  const headers = getAuthToken() as AuthTokenType;
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(currenciesUrl, headers);
    setCurrency(response.data);
  } finally {
    setFetching(false);
  }
};

export const getLocation = async (
  setLocation: (data: any) => void,
  setFetching: (val: boolean) => void
) => {
  const headers = getAuthToken() as AuthTokenType;
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(locationsUrl, headers);
    setLocation(response.data);
  } finally {
    setFetching(false);
  }
};


export const getSupplier = async (
  setSupplier: (data: any) => void,
  setFetching: (val: boolean) => void
) => {
  const headers = getAuthToken() as AuthTokenType;
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(suppliersUrl, headers);
    setSupplier(response.data);
  } finally {
    setFetching(false);
  }
};

export const getProduct = async (
  setProduct: (data: any) => void,
  setFetching: (val: boolean) => void
) => {
  const headers = getAuthToken() as AuthTokenType;
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(productsUrl, headers);
    const data=(response.data as product[]).map((item) => ({...item,
      uom:item.sku_uom.name,
      curr:item.currency.name,
      cat:item.category.parent?.name,
      subcat:item.category.name
  
    }))

    setProduct(data);
  } finally {
    setFetching(false);
  }
};

export const getpurchase=async (setpurchase:(data:purchaseProps[])=>void,setFetching:(val:boolean)=>void)=>{
  const headers=getAuthToken() as AuthTokenType
  const response:AxiosResponse =await axios.get(purchaseorderUrl,headers).catch(
    (e)=>{ notification.error({
        message:"Operation Error",
        description:e.response?.data.error
    })}
    ) as AxiosResponse<purchaseProps>
if(response){
  const data=(response.data as purchaseProps[]).map((item) => ({...item,
    expected_delivery_date:moment(item.expected_delivery_date).format('DD-MM-YYYY'),
    actual_delivery_date:moment(item.actual_delivery_date).format('DD-MM-YYYY'),
    departure_date:moment(item.departure_date).format('DD-MM-YYYY'),
    origin:item.from_location?.name,
    project1:item.project?.name,
    portfolio:item.project?.portfolio,
    destination:item.to_location?.name
  }))
  setpurchase(data) 
  setFetching(false)

}

}

export const getstocktransfer=async (setstocktransfer:(data:stocktransferProps[])=>void,setFetching:(val:boolean)=>void)=>{
  const headers=getAuthToken() as AuthTokenType
  const response:AxiosResponse =await axios.get(stocktransferorderUrl,headers).catch(
    (e)=>{ notification.error({
        message:"Operation Error",
        description:e.response?.data.error
    })}
    ) as AxiosResponse<stocktransferProps>
if(response){
  const data=(response.data as stocktransferProps[]).map((item) => ({...item,
    expected_delivery_date:moment(item.expected_delivery_date).format('DD-MM-YYYY'),
    actual_delivery_date:moment(item.actual_delivery_date).format('DD-MM-YYYY'),
    origin:item.from_location?.name,
    portfolio:item.project?.portfolio,
    project1:item.project?.name,
    destination:item.to_location?.name
  }))
  setstocktransfer(data) 
  setFetching(false)

}

}



export const getOnhandInventory=async (setOnhandInventory:(data:OnhandInventoryProps[])=>void,setFetching:(val:boolean)=>void)=>{
  const headers=getAuthToken() as AuthTokenType
  const response:AxiosResponse =await axios.get(onhandUrl,headers).catch(
    (e)=>{ notification.error({
        message:"Operation Error",
        description:e.response?.data.error
    })}
    ) as AxiosResponse<OnhandInventoryProps>
  const data=(response.data as OnhandInventoryProps[]).map((item) => ({...item,
    created_at:moment(item.created_at).format('DD-MM-YYYY'),
    
    code:item.sku_code.code,
    name:item.sku_code.name,
    subname:item.sku_code.subname,
    brand:item.supplier?.name,
    project1:item.project?.name,
    cat:item.sku_code.category.parent.name,
    subcat:item.sku_code.category.name,
    storage:item.storage_location.name,
  }))
  setOnhandInventory(data) 
  setFetching(false)

}


export const getProject = async (
  setProject: (data: any) => void,
  setFetching: (val: boolean) => void
) => {
  const headers = getAuthToken() as AuthTokenType;
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(projectsUrl, headers);
    setProject(response.data);
  } finally {
    setFetching(false);
  }
};


export const getShipments = async (
  setShipments: (data: any) => void,
  setFetching: (val: boolean) => void
) => {
  const headers = getAuthToken() as AuthTokenType;
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(shipmentURL, headers);
    const data=(response.data as Shipment[]).map((item) => ({...item,
      real_etd1:moment(item.real_etd).format('DD-MM-YYYY'),
      real_eta1:moment(item.real_eta).format('DD-MM-YYYY'),
      real_discharge1:moment(item.real_discharge).format('DD-MM-YYYY'),
      finish_free_days1:moment(item.finish_free_days).format('DD-MM-YYYY'),
      start_demurrage1:moment(item.start_demurrage).format('DD-MM-YYYY'),
      returned_date1:moment(item.returned_date).format('DD-MM-YYYY'),
      project1:item.project?.name,
      type_of_material1:item.type_of_material?.name,


    }))

    setShipments(data);
  } finally {
    setFetching(false);
  }
};


export const getSankey = async (
  setSankey: (data: any) => void,
  setFetching: (val: boolean) => void
) => {
  const headers = getAuthToken() as AuthTokenType;
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(SankeyChartURL, headers);
    setSankey(response.data);
  } finally {
    setFetching(false);
  }
};


export const getTree = async (
  setTree: (data: any) => void,
  setFetching: (val: boolean) => void
) => {
  const headers = getAuthToken() as AuthTokenType;
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(TreeChartURL, headers);
    setTree(response.data);
  } finally {
    setFetching(false);
  }
};




///////////////////Procurement

export const getBusinessLine = async (setBusinessLine: (data: any) => void, setFetching: (val: boolean) => void) => {
  const headers = getAuthToken() as AuthTokenType;

  setFetching(true);

  try {
    const response = await axios.get(businesslineURL,headers);
    setBusinessLine(response.data);
  } finally {
    setFetching(false);
  }
};



export const getProcessCategorizations = async (setProcessCategorizations: (data: any) => void, setFetching: (val: boolean) => void) => {
  const headers = getAuthToken() as AuthTokenType;

  setFetching(true);

  try {
    const response = await axios.get(ProcessCategorizationURL,headers);
    setProcessCategorizations(response.data);
  } finally {
    setFetching(false);
  }
};

export const getProcessSpecifications = async (setProcessSpecifications: (data: any) => void, setFetching: (val: boolean) => void) => {
  setFetching(true);
  const headers = getAuthToken() as AuthTokenType;

  try {
    const response = await axios.get(ProcessSpecificationURL,headers);
    setProcessSpecifications(response.data);
  } finally {
    setFetching(false);
  }
};

export const getCeCos = async (setCeCos: (data: any) => void, setFetching: (val: boolean) => void) => {
  setFetching(true);
  const headers = getAuthToken() as AuthTokenType;

  try {
    const response = await axios.get(CeCoURL,headers);
    const data=(response.data as CeCo[]).map((item) => ({...item,
      ceco_owner1:item.ceco_owner?.fullname,
  
    }))

    setCeCos(data);
  } finally {
    setFetching(false);
  }
};


export const getProjectp = async (setProjectp: (data: any) => void, setFetching: (val: boolean) => void) => {
  setFetching(true);
  const headers = getAuthToken() as AuthTokenType;

  try {
    const response = await axios.get(ProjectURL,headers);
    const data=(response.data as Projectp[]).map((item) => ({...item,
      project_manager1:item.project_manager?.fullname,
  
    }))
  
    setProjectp(data);
  } finally {
    setFetching(false);
  }
};

export const getBudgetCodes = async (setBudgetCodes: (data: any) => void, setFetching: (val: boolean) => void) => {
  setFetching(true);
  const headers = getAuthToken() as AuthTokenType;

  try {
    const response = await axios.get(BudgetCodeURL,headers);
    setBudgetCodes(response.data);
  } finally {
    setFetching(false);
  }
};

export const getStatuses = async (setStatuses: (data: any) => void, setFetching: (val: boolean) => void) => {
  setFetching(true);
  const headers = getAuthToken() as AuthTokenType;

  try {
    const response = await axios.get(StatusURL,headers);
    setStatuses(response.data);
  } finally {
    setFetching(false);
  }
};

export const getDeliveryTerms = async (setDeliveryTerms: (data: any) => void, setFetching: (val: boolean) => void) => {
  setFetching(true);
  const headers = getAuthToken() as AuthTokenType;

  try {
    const response = await axios.get(DeliveryTermURL,headers);
    setDeliveryTerms(response.data);
  } finally {
    setFetching(false);
  }
};



export const getCategorydetails = async (setCategorydetails: (data: any) => void, setFetching: (val: boolean) => void) => {
  setFetching(true);
  const headers = getAuthToken() as AuthTokenType;

  try {
    const response = await axios.get(categorydetailsURL,headers);
    const data=(response.data as CategoryDetailsprops[]).map((item) => ({...item,
      budgetcode1:item.budget_code?.code,
    }))
    setCategorydetails(data);
  } finally {
    setFetching(false);
  }
};

export const getRequirements = async (setRequirements: (data: any) => void, setFetching: (val: boolean) => void) => {
  setFetching(true);
  const headers = getAuthToken() as AuthTokenType;

  try {
    const response = await axios.get(RequirementgetViewSetURL,headers);
    const data=(response.data as Requirement[]).map((item) => ({...item,
      original_date1:moment(item.original_date).format('DD-MM-YYYY'),
      process_categorization1:item.process_categorization?.name,
      process_specification1:item.process_specification?.name,
      ceco1:item.ceco?.name,
      buyer1:item.buyer?.fullname,
      requester1:item.requester?.fullname,
      project1:item.project?.code_name,
      projectclient:item.project?.client,
      project_manager1:item.project?.project_manager?.fullname,
      projectmw:item.project?.mw,
      budgetcode1:item.budget_code?.code,
      status1:item.status?.name,
      currency1:item.currency?.name,
      deliveryterm1:item.delivery_term?.term,
      days: moment().diff(moment(item.original_date), 'days'),
      cerrada: item.po_number ? 'Closed' : 'Open' 

    }))

    setRequirements(data);
  } finally {
    setFetching(false);
  }
};


export const getRequirementsPivot = async (setRequirements: (data: any) => void, setFetching: (val: boolean) => void) => {
  setFetching(true);
  const headers = getAuthToken() as AuthTokenType;

  try {
    const response = await axios.get(RequirementURL,headers);
    const data=(response.data as Requirement[]).map((item) => ({...item,
      original_date:moment(item.original_date).format('DD-MM-YYYY'),
      process_categorization:item.process_categorization?.name,
      process_specification:item.process_specification?.name,
      ceco:item.ceco?.name,
      net_value : Number(item.net_value),
      buyer:item.buyer?.fullname,
      requester:item.requester?.fullname,
      project:item.project?.code_name,
      projectclient:item.project?.client,
      projectproject_manager:item.project?.project_manager,
      projectmw:item.project?.mw,
      budget_code:item.budget_code?.code,
      status:item.status?.name,
      currency:item.currency?.name,
      deliveryterm:item.delivery_term?.term,
      days: moment().diff(moment(item.original_date), 'days')

    }))

    setRequirements(data);
  } finally {
    setFetching(false);
  }
};


export const getdemmurage = async (
  setdemmurage: (data: any) => void,
  setFetching: (val: boolean) => void
) => {
  const headers = getAuthToken() as AuthTokenType;
  setFetching(true);

  try {
    const response: AxiosResponse = await axios.get(demmurageURL, headers);
    const data=(response.data as DemurrageControl[]).map((item) => ({...item,
      shipping_date1:moment(item.shipping_date).format('DD-MM-YYYY'),
      delivery_date1:moment(item.delivery_date).format('DD-MM-YYYY'),
      free_days_expiration1:moment(item.free_days_expiration).format('DD-MM-YYYY'),
      demurrage_start1:moment(item.demurrage_start).format('DD-MM-YYYY'),
      demurrage_payment_date1:moment(item.demurrage_payment_date).format('DD-MM-YYYY'),

    }))

    setdemmurage(data);
  } finally {
    setFetching(false);
  }
};







export const getRequirementsbuyer = async (setRequirementsbuyer: (data: any) => void, setFetching: (val: boolean) => void) => {
  setFetching(true);
  const headers = getAuthToken() as AuthTokenType;

  try {
    const response = await axios.get(RequirementbURL,headers);
    const data=(response.data as Requirement[]).map((item) => ({...item,
      original_date1:moment(item.original_date).format('DD-MM-YYYY'),
      process_categorization1:item.process_categorization?.name,
      process_specification1:item.process_specification?.name,
      ceco1:item.ceco?.name,
      buyer1:item.buyer?.fullname,
      requester1:item.requester?.fullname,
      project1:item.project?.code_name,
      projectclient:item.project?.client,
      project_manager1:item.project?.project_manager?.fullname,
      projectmw:item.project?.mw,
      budgetcode1:item.budget_code?.code,
      status1:item.status?.name,
      currency1:item.currency?.name,
      deliveryterm1:item.delivery_term?.term,
      days: moment().diff(moment(item.original_date), 'days'),
      cerrada: item.po_number ? 'Closed' : 'Open' 

    }))

    setRequirementsbuyer(data);
  } finally {
    setFetching(false);
  }
};


export const getRequirementscontroller = async (setRequirementscontroller: (data: any) => void, setFetching: (val: boolean) => void) => {
  setFetching(true);
  const headers = getAuthToken() as AuthTokenType;

  try {
    const response = await axios.get(RequirementcURL,headers);
    const data=(response.data as Requirement[]).map((item) => ({...item,
      original_date1:moment(item.original_date).format('DD-MM-YYYY'),
      process_categorization1:item.process_categorization?.name,
      process_specification1:item.process_specification?.name,
      ceco1:item.ceco?.name,
      buyer1:item.buyer?.fullname,
      requester1:item.requester?.fullname,
      project1:item.project?.code_name,
      projectclient:item.project?.client,
      project_manager1:item.project?.project_manager?.fullname,
      projectmw:item.project?.mw,
      budgetcode1:item.budget_code?.code,
      status1:item.status?.name,
      currency1:item.currency?.name,
      deliveryterm1:item.delivery_term?.term,
      days: moment().diff(moment(item.original_date), 'days'),
      cerrada: item.po_number ? 'Closed' : 'Open' 

    }))

    setRequirementscontroller(data);
  } finally {
    setFetching(false);
  }
};



export const getRequirementscecoowner = async (setRequirementscecoowner: (data: any) => void, setFetching: (val: boolean) => void) => {
  setFetching(true);
  const headers = getAuthToken() as AuthTokenType;

  try {
    const response = await axios.get(RequirementcoURL,headers);
    const data=(response.data as Requirement[]).map((item) => ({...item,
      original_date1:moment(item.original_date).format('DD-MM-YYYY'),
      process_categorization1:item.process_categorization?.name,
      process_specification1:item.process_specification?.name,
      ceco1:item.ceco?.name,
      buyer1:item.buyer?.fullname,
      requester1:item.requester?.fullname,
      project1:item.project?.code_name,
      projectclient:item.project?.client,
      project_manager1:item.project?.project_manager?.fullname,
      projectmw:item.project?.mw,
      budgetcode1:item.budget_code?.code,
      status1:item.status?.name,
      currency1:item.currency?.name,
      deliveryterm1:item.delivery_term?.term,
      days: moment().diff(moment(item.original_date), 'days'),
      cerrada: item.po_number ? 'Closed' : 'Open' 

    }))

    setRequirementscecoowner(data);
  } finally {
    setFetching(false);
  }
};

export const getRequirementsmanager = async (setRequirementsmanager: (data: any) => void, setFetching: (val: boolean) => void) => {
  setFetching(true);
  const headers = getAuthToken() as AuthTokenType;

  try {
    const response = await axios.get(RequirementmURL,headers);
    const data=(response.data as Requirement[]).map((item) => ({...item,
      original_date1:moment(item.original_date).format('DD-MM-YYYY'),
      process_categorization1:item.process_categorization?.name,
      process_specification1:item.process_specification?.name,
      ceco1:item.ceco?.name,
      buyer1:item.buyer?.fullname,
      requester1:item.requester?.fullname,
      project1:item.project?.code_name,
      projectclient:item.project?.client,
      project_manager1:item.project?.project_manager?.fullname,
      projectmw:item.project?.mw,
      budgetcode1:item.budget_code?.code,
      status1:item.status?.name,
      currency1:item.currency?.name,
      deliveryterm1:item.delivery_term?.term,
      days: moment().diff(moment(item.original_date), 'days'),
      cerrada: item.po_number ? 'Closed' : 'Open' 

    }))

    setRequirementsmanager(data);
  } finally {
    setFetching(false);
  }
};