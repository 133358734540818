import { Drawer, notification } from "antd";
import { FC, useState } from "react";
import { AuthTokenType, DataProps, AddGroupFormModalProps } from "../utils/types";
import { Form, Input, Select, Button } from 'antd';
import { getAuthToken } from "../utils/functions";
import axios, { AxiosResponse } from "axios";
import { GroupAddViewUrl } from "../utils/network";
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const { Option } = Select;

const AddGroupForm: FC<AddGroupFormModalProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose
}) => {
  const { t } = useTranslation(); // Initialize the useTranslation hook
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values: DataProps) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

    try {
      const response: AxiosResponse = await axios.post(GroupAddViewUrl, values, headers);
      setLoading(false);

      notification.success({
        message: t("Operation Success"), // Translate the success message
        description: t("Group Created Successfully") // Translate the success description
      });

      onSuccessCallBack();
      form.resetFields();
    } catch (error) {
      notification.error({
        message: t("Operation Error"), // Translate the error message
      });

      setLoading(false);
      form.resetFields();
    }
  };

  return (
    <Drawer
      title={t("Add Group")} // Translate the title
      visible={isVisible}
      onClose={onClose}
      width={360}
    >
      <Form
        layout="vertical"
        onFinish={onSubmit}
        form={form}
      >
        <Form.Item
          label={t("Name")} 
          name="name"
          rules={[{ required: true, message: t('Please input Group Name!') }]} 
        >
          <Input placeholder={t("Name")} type="name" /> 
        </Form.Item>
        <Form.Item>
          <Button className="bbutton" htmlType='submit' type="primary" block loading={loading}>{t("Submit")}</Button> {/* Translate the button text */}
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddGroupForm;