import React, { FC, useState, useEffect } from "react";
import { Button, Table, Modal, notification } from "antd";
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import { useGetProjects } from "../utils/hooks";
import { AuthTokenType, project } from "../utils/types";
import { getAuthToken, getProject } from "../utils/functions";
import AddProjectsForm from "../components/AddProjectsForm";
import axios from "axios";
import { projectsUrl } from "../utils/network";
import ContentLayout from "../components/ContentLayout";
import ContentLayoutTabs from "../components/ContentLayoutTabs";

const Projects: FC = () => {
  const { t } = useTranslation(); // Initialize the useTranslation hook

  const columns = [
    {
      title: t("ID"),
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Portfolio"),
      dataIndex: "portfolio",
      key: "portfolio",
    },
    {
      title: t("Person Responsible"),
      dataIndex: "person_responsible",
      key: "person_responsible",
    },
    {
      title: t("City"),
      dataIndex: "city",
      key: "city",
    },
    {
      title: t("Actions"),
      key: "actions",
      render: (text: string, record: project) => (
        <span>
          <a onClick={() => handleEdit(record)}>{t("Edit")}</a>
          <span> | </span>
          <a onClick={() => handleDelete(record.id)}>{t("Delete")}</a>
        </span>
      ),
    },
  ];

  const [project, setProject] = useState<project[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingProject, setEditingProject] = useState<project | null>(null);

  useGetProjects(setProject, setFetching);

  useEffect(() => {
    // When editingCustomer changes, open the modal
    if (editingProject) {
      setDrawerVisible(true);
    }
  }, [editingProject]);

  const onCloseWithoutEditing = () => {
    setEditingProject(null); // Reset editingCategory when form is closed without editing
  };

  const handleEdit = (record: project) => {
    setEditingProject(record);
  };

  const handleDelete = (projectID: number) => {
    Modal.confirm({
      title: t("Confirm Deletion"),
      content: t("Are you sure you want to delete this customer?"),
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;
          // Make a DELETE request to the API endpoint for deleting a customer
          await axios.delete(`${projectsUrl}/${projectID}/`, headers);

          // After successful deletion, fetch updated customers
          getProject(setProject, setFetching);
        } catch (error) {
          // Handle error if the deletion fails
          notification.error({
            message: t("Delete Operation Error"),
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: t("Yes"),
      cancelText: t("No"),
    });
  };


  return (
    <ContentLayoutTabs
      pageTitle={t("Projects")}
      buttontitle={t("Add Project")}
      setModalState={setDrawerVisible}
      dataSource={project as any}
      columns={columns as any}
      fetching={fetching}
      includeFilters={true}
      model_name="project" // Assuming you don't need filters in ContentLayout
    >
      <AddProjectsForm
        onSuccessCallBack={() => {
          setDrawerVisible(false);
          getProject(setProject, setFetching);
        }}
        isVisible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        editingProject={editingProject}
        onCloseWithoutEditing={onCloseWithoutEditing}
      />
    </ContentLayoutTabs>
  );
};

export default Projects;