import React, { FC, useState, useEffect } from "react";
import { Drawer, notification, Form, Input, Button, Select } from "antd";
import { AuthTokenType, DataProps, AddBudgetCodeFormProps, AddCategoryDetailsFormProps, BudgetCode, BusinessLineprops } from "../utils/types";
import { getAuthToken } from "../utils/functions";
import axios, { AxiosResponse } from "axios";
import { BudgetCodeURL, categorydetailsURL } from "../utils/network";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import { useGetBudgetCodes, useGetBusinessLine } from "../utils/hooks";
const { Option } = Select;

const AddCategoryDetails: FC<AddCategoryDetailsFormProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose,
  editingCategorydDetails,
  onCloseWithoutEditing, // new prop
}) => {
  const { t } = useTranslation(); // Initialize the useTranslation hook
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [fetching, setFetching] = useState<boolean>(true);

  const [budgetcode, setbudgetcode] = useState<BudgetCode[]>([]);
  useGetBudgetCodes(setbudgetcode, setFetching);

  const [businessline, setbusinessline] = useState<BusinessLineprops[]>([]);
  useGetBusinessLine(setbusinessline, setFetching);



  useEffect(() => {
    if (editingCategorydDetails) {
      form.setFieldsValue({...editingCategorydDetails,
        budget_code_id: editingCategorydDetails.budget_code?.id,
        businessline_id: editingCategorydDetails.businessline?.id,
    });
    } else {
      form.resetFields();
    }
  }, [editingCategorydDetails, form]);

  const handleFormClose = () => {
    form.resetFields();
    onClose?.();
    if (!form.isFieldsTouched()) {
      onCloseWithoutEditing?.(); // Notify the parent about closing without editing
    }
  };

  const onSubmit = async (values: DataProps) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

    try {
      let response: AxiosResponse;

      if (editingCategorydDetails) {
        // Editing location
        response = await axios.put(`${categorydetailsURL}/${editingCategorydDetails.id}/`, values, headers);
      } else {
        // Adding new location
        response = await axios.post(categorydetailsURL + '/', values, headers);
      }

      setLoading(false);

      if (response) {
        onSuccessCallBack?.();
        onClose?.(); // Close the drawer
      }
    } catch (error) {
      notification.error({
        message: t("Operation Error"), // Translate the error message
      });
      setLoading(false);
    }
  };

  return (
    <Drawer
      title={editingCategorydDetails ? t("Edit Record") : t("Add Record")} 
      visible={isVisible}
      onClose={handleFormClose}
      destroyOnClose
      width={360}
    >
      <Form
        layout="vertical"
        onFinish={onSubmit}
        form={form}
        onValuesChange={() => setHasChanges(true)}
      >
        <Form.Item
          label={t("Business Line")} 
          name="businessline_id"
          rules={[{ required: true, message: t('Please input the Business Line!') }]}
        >
          <Select placeholder={t("Business Line")}>
            {businessline.map((item, index) => <Option value={item.id} key={index}>{item.name}</Option>)}
          </Select>
        </Form.Item>
        <Form.Item
          label={t("Category")} 
          name="category"
          rules={[{ required: true, message: t('Please input the Category!') }]}
        >
          <Input placeholder={t("Category")} /> 
        </Form.Item>
        <Form.Item
          label={t("Sub Category")} 
          name="subcategory"
          rules={[{ required: true, message: t('Please input the Sub Category!') }]}
        >
          <Input placeholder={t("Sub Category")} /> 
        </Form.Item>
        <Form.Item
          label={t("Attachment Names")} 
          name="attachments"
          rules={[{ required: true, message: t('Please input the Attachment Names!') }]}
        >
          <Input placeholder={t("Attachments")} /> 
        </Form.Item>

        <Form.Item
          label={t("Budget Code")}
          name="budget_code_id"
          rules={[{ required: true, message: t('Please input the Budget Code!') }]}
        >
          <Select placeholder={t("Budget Code")}>
            {budgetcode.map((item, index) => <Option value={item.id} key={index}>{item.code}</Option>)}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button className="bbutton" htmlType="submit" type="primary" block loading={loading}>
            {editingCategorydDetails ? t("Update") : t("Submit")} 
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddCategoryDetails;