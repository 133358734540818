import { useState, ChangeEvent } from "react"
import {getAuthToken, getstocktransfer, } from "../utils/functions"
import {AuthTokenType, stocktransferProps,} from "../utils/types"
import { useGetstocktransfer } from "../utils/hooks"
import { Button,Drawer,Input, Modal, Table, notification } from "antd"
import axios,{AxiosResponse} from "axios"
import { onhandUrl, sendstoUrl, stocktransferorderUrl, viewstoUrl} from "../utils/network"
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileSaver from "file-saver"; // Import FileSaver.js library
import EditTransferOrderDrawer from "../components/EditTransferOrderDrawer"
import { useTranslation } from "react-i18next"



const formatstocktransferDataProps = (
  stocktransferdata: stocktransferProps[],
  onCloseOrder: (stocktransferid: stocktransferProps) => void,
  onChangeAdd: (value: Date | null, stocktransfer_id: number) => void,
  onViewDetails: (item: stocktransferProps) => void,
  onDeleteOrder: (item: stocktransferProps) => void,
  onEditOrder: (item: stocktransferProps) => void,
  t: (key: string) => string // Translate function
) => {
  return stocktransferdata.map((item) => {
    let actionLabel = "";
    let actionHandler = () => {};

    if (item.status === "Open") {
      actionLabel = t("Intransit");
      actionHandler = () => onCloseOrder(item);
    } else if (item.status === "Intransit") {
      actionLabel = t("Close");
      actionHandler = () => onCloseOrder(item);
    }

    const changeDeliveryDateInput = item.status === "Intransit" ? (
      <div>
        <Input
          style={{ width: "150px" }}
          type="date"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChangeAdd(e.target.valueAsDate, item.id)
          }
        />
      </div>
    ) : null;

    return {
      ...item,
      key: item.id,
      change_delivery_date: changeDeliveryDateInput,
      action: (
        <div>
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={actionHandler}
          >
            {actionLabel}
          </span>{" "}
          |{" "}
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => onViewDetails(item)}
          >
            {t("Details")}
          </span>{" "}
          |{" "}
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => onDeleteOrder(item)}
          >
            {t("Delete")}
          </span>{" "}
          |{" "}
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => onEditOrder(item)}
          >
            {t("Edit")}
          </span>
        </div>
      ),
    };
  });
};



function StocktransferOrderReview(){
  const[modalState,setModalState]=useState(false)
  const[fetching,setFetching]=useState(true)
  const { t } = useTranslation(); // Initialize the useTranslation hook

  const [stocktransfer,setstocktransfer]=useState<stocktransferProps[]>([])
  const [stocktransferadd,setstocktransferadd]=useState<{[key:number]:Date|null}>([])
  useGetstocktransfer(setstocktransfer,setFetching)

  const [loading,setLoading]=useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState<stocktransferProps | null>(null);

  
  const [editDrawerVisible, setEditDrawerVisible] = useState(false);
  const [editingItem, setEditingItem] = useState<stocktransferProps | null>(null);

  const [editItem, setEditItem] = useState<stocktransferProps | null>(null); // Add editItem state

  const onEditOrder = (item: stocktransferProps) => {
    setEditingItem(item);
    setEditDrawerVisible(true);
  };



  const [speedDialOpen, setSpeedDialOpen] = useState(false);



      const downloadsto= async (transferorder:stocktransferProps|any)=>{

        const datatosend={
          transfer_order_id:transferorder.id,
        }
      
          const headers=getAuthToken() as AuthTokenType
          
      
        const response:AxiosResponse =await axios.post(viewstoUrl,datatosend,headers).catch(
            (e)=>{ notification.error({
                message:"Operation Error",
                description:e.response?.data.error
            })
      
        }
            ) as AxiosResponse
          const pdfBlob = new Blob([response.data], { type: "application/pdf" });
          FileSaver.saveAs(pdfBlob, `Transfer_Order_${datatosend.transfer_order_id}.pdf`);
                     setLoading(false)
      
          }
    



  const showModal = (item: stocktransferProps) => {
    setCurrentItem(item);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };




      const stocktransferordercolumns = [
        {
          title: t("ID"),
          dataIndex: "id",
          key: "id",
        },
        {
          title: t("Portfolio"),
          dataIndex: "portfolio",
          key: "portfolio",
        },
        {
          title: t("Project"),
          dataIndex: "project1",
          key: "project1",
        },
        {
          title: t("Expected Delivery Date"),
          dataIndex: "expected_delivery_date",
          key: "expected_delivery_date",
          render: (text: string) => <div style={{ width: "10vw" }}>{text}</div>,
        },
        {
          title: t("Actual Delivery Date"),
          dataIndex: "actual_delivery_date",
          key: "actual_delivery_date",
          render: (text: string) => <div style={{ width: "10vw" }}>{text}</div>,
        },
        {
          title: t("Origin Warehouse"),
          dataIndex: "origin",
          key: "origin",
          filters: stocktransfer.map((item) => ({
            text: item.from_location ? item.from_location.name : t("N/A"),
            value: item.from_location ? item.from_location.name : t("N/A"),
          })),
          onFilter: (value: any, record: any) =>
            record.from_location.name.toLowerCase().includes(value.toLowerCase()),
        },
        {
          title: t("Destination"),
          dataIndex: "destination",
          key: "destination",
          filters: stocktransfer.map((item) => ({
            text: item.to_location ? item.to_location.name : t("N/A"),
            value: item.to_location ? item.to_location.name : t("N/A"),
          })),
          onFilter: (value: any, record: any) =>
            record.to_location.name.toLowerCase().includes(value.toLowerCase()),
        },
        {
          title: t("Internal Reference"),
          dataIndex: "internal_reference",
          key: "internal_reference",
        },
        {
          title: t("Transport Company"),
          dataIndex: "transport_company",
          key: "transport_company",
        },
        {
          title: t("Truck Plate"),
          dataIndex: "truck_plate",
          key: "truck_plate",
        },
        {
          title: t("Guide Number"),
          dataIndex: "guide_number",
          key: "guide_number",
        },
        {
          title: t("Container Id"),
          dataIndex: "container_id",
          key: "container_id",
        },
        {
          title: t("Status"),
          dataIndex: "status",
          key: "status",
        },
        {
          title: t("Change Delivery Date"),
          dataIndex: "change_delivery_date",
          key: "change_delivery_date",
        },
        {
          title: t("Actions"),
          dataIndex: "action",
          key: "action",
          render: (text: string) => <div style={{ width: "20vw" }}>{text}</div>,
        },
        ];


        
        const Closeorder = async (stocktransferorder: stocktransferProps | any) => {
          let statusToUpdate = "";
        
          if (stocktransferorder.status === "Open") {
            statusToUpdate = "Intransit";
            for (let i = 0; i < stocktransferorder.stocktransferorder_items.length; i++) {
              const datatosend2inv = {
                sku_code_id: stocktransferorder.stocktransferorder_items[i].item.id,
                storage_location_id: stocktransferorder.from_location.id,
                project_id: stocktransferorder.project.id,
                container_id: stocktransferorder.container_id,
                supplier_id: stocktransferorder.stocktransferorder_items[i].supplier.id,
                status: "Available",
                totalunits: -stocktransferorder.stocktransferorder_items[i].quantity
              };
              const datatosend2inv2 = {
                sku_code_id: stocktransferorder.stocktransferorder_items[i].item.id,
                storage_location_id: stocktransferorder.to_location.id,
                project_id: stocktransferorder.project.id,
                container_id: stocktransferorder.container_id,
                supplier_id: stocktransferorder.stocktransferorder_items[i].supplier.id,
                status: "Intransit",
                totalunits: stocktransferorder.stocktransferorder_items[i].quantity
              };

              
        
              const headers = getAuthToken() as AuthTokenType;
              await axios.post(onhandUrl, datatosend2inv, headers).catch((e) => {
                notification.error({
                  message: "Operation Error",
                  description: e.response?.data.error
                });
              });
              await axios.post(onhandUrl, datatosend2inv2, headers).catch((e) => {
                notification.error({
                  message: "Operation Error",
                  description: e.response?.data.error
                });
              });
            }
            
            
          } else if (stocktransferorder.status === "Intransit") {
            statusToUpdate = "Closed";
            for (let i = 0; i < stocktransferorder.stocktransferorder_items.length; i++) {
              const datatosend2inv = {
                sku_code_id: stocktransferorder.stocktransferorder_items[i].item.id,
                storage_location_id: stocktransferorder.to_location.id,
                project_id: stocktransferorder.project.id,
                container_id: stocktransferorder.container_id,
                supplier_id: stocktransferorder.stocktransferorder_items[i].supplier.id,
                status: "Intransit",
                totalunits: -stocktransferorder.stocktransferorder_items[i].quantity
              };

              const datatosend2inv2 = {
                sku_code_id: stocktransferorder.stocktransferorder_items[i].item.id,
                storage_location_id: stocktransferorder.to_location.id,
                project_id: stocktransferorder.project.id,
                container_id: stocktransferorder.container_id,
                supplier_id: stocktransferorder.stocktransferorder_items[i].supplier.id,
                status: "Available",
                totalunits: stocktransferorder.stocktransferorder_items[i].quantity
              };

        
              const headers = getAuthToken() as AuthTokenType;
              await axios.post(onhandUrl, datatosend2inv, headers).catch((e) => {
                notification.error({
                  message: "Operation Error",
                  description: e.response?.data.error
                });
              })
              await axios.post(onhandUrl, datatosend2inv2, headers).catch((e) => {
                notification.error({
                  message: "Operation Error",
                  description: e.response?.data.error
                });
              });
            }
          }
        
          const datatosend:any = {
            id: stocktransferorder.id,
            status: statusToUpdate,
          };
          
          if (statusToUpdate === 'Closed') {
            datatosend.actual_delivery_date = stocktransferadd[stocktransferorder.id];
          }

        
          setLoading(true);
          const headers = getAuthToken() as AuthTokenType;
        
          const response: AxiosResponse = await axios.patch(
            stocktransferorderUrl + "/" + datatosend.id,
            datatosend,
            headers
          ).catch((e) => {
            notification.error({
              message: "Operation Error",
              description: e.response?.data.error,
            });
          }) as AxiosResponse;
        
          setLoading(false);
        
          if (response) {
            notification.success({
              message: t("Operation Success"),
              description: "Order " + statusToUpdate + " Successfully",
            });
          }
        
          getstocktransfer(setstocktransfer, setFetching);
        };

        const DeleteOrder = async (stocktransferorder: stocktransferProps | any) => {
          const confirmDeletion = () => {
            Modal.confirm({
              title: "Confirm Deletion",
              content: "Are you sure you want to delete this stock transfer order?",
              onOk: async () => {
                try {
                  const datatosend = {
                    id: stocktransferorder.id,
                  };
        
                  const headers = getAuthToken() as AuthTokenType;
        
                  const response: AxiosResponse = await axios.delete(stocktransferorderUrl + '/' + datatosend.id, headers );
        
                  setLoading(false);
        
                  if (response) {
                    notification.success({
                      message: "Operation Success",
                      description: "Order Deleted Successfully"
                    });
                  }
        
                  getstocktransfer(setstocktransfer, setFetching);
                } catch (error) {
                  notification.error({
                    message: "Operation Error",
           
                  });
                }
              },
              onCancel: () => {
                // Do nothing if the user cancels the deletion
              },
              okText: "Yes",
              cancelText: "No",
            });
          };
        
          confirmDeletion();
        };    
        const onCloseWithoutEditing = () => {
          setEditItem(null); // Reset editingCategory when form is closed without editing
        };

             
  const onChangeAdd=(value:Date|null,stocktransfer_id:number)=>{
          setstocktransferadd({
            ...stocktransferadd,
            [stocktransfer_id]:value
          })

        }
        const [searchQuery, setSearchQuery] = useState("");

        const filteredData = stocktransfer.filter((record) =>
        Object.values(record).some((value) =>
          value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
      );

      const downloadCSV = () => {
        // Convert data to CSV format
        const csvContent = stocktransferordercolumns.map(column => column.title).join('\t') + '\n' +
          filteredData.map(row => stocktransferordercolumns.map(column => {
            return `"${row[column.dataIndex as keyof stocktransferProps]}"`;
          }).join('\t')).join('\n');
      
        // Create a Blob object containing the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      
        // Create a temporary URL for the Blob
        const url = window.URL.createObjectURL(blob);
      
        // Create a link element
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", "data.csv");
      
        // Append the link to the document body and trigger the click event
        document.body.appendChild(link);
        link.click();
      
        // Clean up by revoking the temporary URL
        window.URL.revokeObjectURL(url);
      };
      
        return (
          <>
                        <EditTransferOrderDrawer
              isVisible={editDrawerVisible}
              onClose={() => setEditDrawerVisible(false)}
              editingTransfers={editingItem}
              onSuccessCallBack={() => {
                setEditDrawerVisible(false);
                getstocktransfer(setstocktransfer, setFetching);

              }}
              onCloseWithoutEditing={onCloseWithoutEditing}

            />

          <div className="card">
              <div className="cardHeader">
                  <h2 className="headContent">{t("Transfer Orders")}</h2>
                  <div className="rightContent">
                  <Input
              placeholder="Search..."
              onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}
              style={{ marginRight: '10px' }}
            />
            <Button onClick={downloadCSV}>Download</Button>

                        </div>
              
              </div>
              <br/>
              <Table className="custom-table"
              
              dataSource={(formatstocktransferDataProps(stocktransfer,Closeorder,onChangeAdd,showModal,DeleteOrder,onEditOrder,t)).filter(record =>
                Object.values(record).some(value =>
                  value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
                ))}

              columns={stocktransferordercolumns}
              
               
              size="small"
              loading ={fetching}/>
<Drawer
  title="Details"
  visible={isModalVisible}
  placement="right"
  onClose={handleOk}
  style={{ borderRadius: '10px' }} // Add rounded corners to the drawer
>
  {currentItem && (
    <>
      <div style={{ marginBottom: '20px', borderBottom: '1px solid #e8e8e8', paddingBottom: '10px' }}>
        <p style={{ fontSize: '16px', marginBottom: '8px', fontWeight: 'bold' }}>ID: {currentItem.id}</p>
        <p style={{ fontSize: '16px', marginBottom: '8px' }}>From Location: {currentItem.from_location?.name}</p>
        <p style={{ fontSize: '16px', marginBottom: '8px' }}>To Location: {currentItem.to_location?.name}</p>
      </div>
      <div>
        <p style={{ fontSize: '16px', marginBottom: '8px', fontWeight: 'bold' }}>Items:</p>
        <Table
          className="custom-table"
          dataSource={currentItem.stocktransferorder_items}
          columns={[
            {
              title: t('Item Name'),
              dataIndex: ['item', 'name'],
              key: 'name',
              render: (text: string) => <span>{JSON.stringify(text)}</span>,
            },
            {
              title: t('Sub Product'),
              dataIndex: ['item', 'subname'],
              key: 'subname',
              render: (text: string) => <span>{JSON.stringify(text)}</span>,
            },
            {
              title: t('Quantity'),
              dataIndex: 'quantity',
              key: 'quantity',
              render: (text: number) => <span>{JSON.stringify(text)}</span>,
            },
          ]}
          pagination={false}
          bordered
          size="small"
          style={{ marginTop: '20px' }}
        />
      </div>
    </>
  )}
  <SpeedDial
  ariaLabel="SpeedDial"
  icon={<SpeedDialIcon />}
  onClose={() => setSpeedDialOpen(false)}
  onOpen={() => setSpeedDialOpen(true)}
  open={speedDialOpen}
  direction="up"
  FabProps={{ size: 'small' }} 
>

<SpeedDialAction
key="Download"
icon={<PictureAsPdfIcon />}
tooltipTitle="Download "
onClick={() => downloadsto(currentItem)}
/>
</SpeedDial>
</Drawer>
          </div>
          </> 
        )
    }
    
    export default StocktransferOrderReview