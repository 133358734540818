import React, { FC, useState, useEffect } from "react";
import { Button, Table, Modal, notification, Tag } from "antd";
import { useGetCurrency } from "../utils/hooks";
import { AuthTokenType, currency } from "../utils/types";
import { getAuthToken, getCurrency } from "../utils/functions";
import AddCurrencyForm from "../components/AddCurrencyForm";
import axios from "axios";
import { currenciesUrl } from "../utils/network";
import ContentLayout from "../components/ContentLayout";
import { useTranslation } from 'react-i18next';
import ContentLayoutTabs from "../components/ContentLayoutTabs";

const Currencies: FC = () => {
  const { t, i18n } = useTranslation();
  const columns = [
    {
      title: t("ID"),
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Is Active"),
      dataIndex: "is_active",
      key: "is_active",
      render: (isActive: boolean) => (
        <Tag color={isActive ? "green" : "red"}>
          {isActive ? t("Yes") : t("No")}
        </Tag>
      ),
    },
    {
      title: t("Actions"),
      key: "actions",
      render: (text: string, record: currency) => (
        <span>
          <a onClick={() => handleEdit(record)}>{t("Edit")}</a>
          <span> | </span>
          <a onClick={() => handleDelete(record.id)}>{t("Delete")}</a>
        </span>
      ),
    },
  ];

  const [currencies, setCurrencies] = useState<currency[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingCurrency, setEditingCurrency] = useState<currency | null>(null);

  useGetCurrency(setCurrencies, setFetching);

  useEffect(() => {
    // When editingCurrency changes, open the modal
    if (editingCurrency) {
      setDrawerVisible(true);
    }
  }, [editingCurrency]);

  const onCloseWithoutEditing = () => {
    setEditingCurrency(null); // Reset editingCurrency when form is closed without editing
  };

  const handleEdit = (record: currency) => {
    setEditingCurrency(record);
  };

  const handleDelete = (currencyId: number) => {
    Modal.confirm({
      title: t("Confirm Deletion"),
      content: t("Are you sure you want to delete this currency?"),
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;
          // Make a DELETE request to the API endpoint for deleting a currency
          await axios.delete(`${currenciesUrl}/${currencyId}/`, headers);

          // After successful deletion, fetch updated currencies
          getCurrency(setCurrencies, setFetching);
        } catch (error) {
          // Handle error if the deletion fails
          notification.error({
            message: t("Delete Operation Error"),
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: t("Yes"),
      cancelText: t("No"),
    });
  };

  const onCreateCurrency = () => {
    setDrawerVisible(true);
    setEditingCurrency(null); // Reset editingCurrency for new currency
  };

  return (
    <ContentLayoutTabs
      pageTitle={t("Currencies")}
      buttontitle={t("Add Currency")}
      setModalState={setDrawerVisible}
      dataSource={currencies as any} 
      columns={columns as any}
      fetching={fetching}
      includeFilters={true}
      model_name="currency" // Assuming you don't need filters in ContentLayout
    >
      <AddCurrencyForm
        onSuccessCallBack={() => {
          setDrawerVisible(false);
          getCurrency(setCurrencies, setFetching);
        }}
        isVisible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        editingCurrency={editingCurrency}
        onCloseWithoutEditing={onCloseWithoutEditing}
      />
    </ContentLayoutTabs>
  );
};

export default Currencies;