import React, { FC, useState, useEffect } from "react";
import { Modal, notification, Tag } from "antd";
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import {useGetBusinessLine, useGetProcessCategorizations } from "../utils/hooks";
import { AuthTokenType, BusinessLineprops, ProcessCategorization} from "../utils/types";
import { getAuthToken, getBusinessLine, getLocation, getProcessCategorizations } from "../utils/functions";

import axios from "axios";
import { businesslineURL, ProcessCategorizationURL} from "../utils/network";
import ContentLayoutTabs from "../components/ContentLayoutTabs";
import AddBusinessLine from "./AddBusinessLine";

const BusinessLine: FC = () => {
  const { t } = useTranslation(); // Initialize the useTranslation hook

  const columns = [
    {
      title: t("ID"), // Translate the title
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("Name"), // Translate the title
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Dual Approval"),
      dataIndex: "dual_approval",
      key: "dual_approval",
      render: (isActive: boolean) => (
        <Tag color={isActive ? "green" : "red"}>
          {isActive ? t("Yes") : t("No")}
        </Tag>
      ),
    },

    {
      title: t("Actions"), // Translate the title
      key: "actions",
      render: (text: string, record: BusinessLineprops) => (
        <span>
          <a onClick={() => handleEdit(record)}>{t("Edit")}</a>
          <span> | </span>
          <a onClick={() => handleDelete(record.id)}>{t("Delete")}</a>
        </span>
      ),
    },
  ];

  const [Businesslines, setBusinesslines] = useState<BusinessLineprops[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingbusinesslines, seteditingbusinesslines] = useState<BusinessLineprops | null>(null);

  useGetBusinessLine(setBusinesslines, setFetching);

  useEffect(() => {
    // When editingLocation changes, open the modal
    if (editingbusinesslines) {
      setDrawerVisible(true);
    }
  }, [editingbusinesslines]);

  const handleEdit = (record: BusinessLineprops) => {
    seteditingbusinesslines(record);
  };

  const handleDelete = (businesslineid: number) => {
    Modal.confirm({
      title: t("Confirm Deletion"), // Translate the title
      content: t("Are you sure you want to delete this record?"), // Translate the content
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;
          // Make a DELETE request to the API endpoint for deleting a location
          await axios.delete(`${businesslineURL}/${businesslineid}/`, headers);

          // After successful deletion, fetch updated locations
          getBusinessLine(setBusinesslines, setFetching);
        } catch (error) {
          // Handle error if the deletion fails
          notification.error({
            message: t("Delete Operation Error"), // Translate the message
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: t("Yes"), // Translate the text
      cancelText: t("No"), // Translate the text
    });
  };

  const onCloseWithoutEditing = () => {
    seteditingbusinesslines(null); // Reset editingCategory when form is closed without editing
  };


  return (
    <ContentLayoutTabs
      pageTitle={t("Business Line")} // Translate the title
      buttontitle={t("Add Business Line")} // Translate the title
      setModalState={setDrawerVisible}
      dataSource={Businesslines as any}
      columns={columns as any}
      fetching={fetching}
      includeFilters={true}
      model_name="businessline"

    >
      <AddBusinessLine
        onSuccessCallBack={() => {
          setDrawerVisible(false);
          getBusinessLine(setBusinesslines, setFetching);
        }}
        isVisible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        editingBusinessLine={editingbusinesslines}
        onCloseWithoutEditing={onCloseWithoutEditing}
      />
    </ContentLayoutTabs>
  );
};

export default BusinessLine;