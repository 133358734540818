import React, { useEffect, useState } from "react";
import { Drawer, Tree, Button } from "antd";
import { DownloadOutlined, FolderOutlined, FileOutlined } from "@ant-design/icons";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { getAuthToken } from "../utils/functions";
import { AuthTokenType } from "../utils/types";
import { purchaseorderfilesUrl } from "../utils/network";
import FileSaver from "file-saver";

interface FileData {
  id: number;
  purchaseorder: string;
  folder: "Commercial invoice" | "Purchase order" | "Insurance" | "Packing list" | "Bill of lading" | "Certificate of origin" | "Others";
  pdf_file: string;
  created_at: string;
}

interface PurchaseOrderFilesDrawerProps {
  purchaseorderId: number | null;
  visible: boolean;
  onClose: () => void;
}

const PurchaseOrderFilesDrawer: React.FC<PurchaseOrderFilesDrawerProps> = ({ purchaseorderId, visible, onClose }) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<FileData[]>([]);
  const [treeData, setTreeData] = useState<any[]>([]);

  const fetchFiles = async () => {
    try {
      const data = { purchaseorder_id: purchaseorderId };
      const headers = getAuthToken() as AuthTokenType;
      const response = await axios.post<FileData[]>(purchaseorderfilesUrl + '/get_files_by_purchaseorder', data, headers);
      setFiles(response.data);
      const uniqueFolders = Array.from(new Set(response.data.map((file: FileData) => file.folder)));
      const newTreeData = uniqueFolders.map((folder: string) => ({
        title: (
          <div style={{ display: "flex", alignItems: "center" }}>
            <FolderOutlined style={{ color: "#1890ff", marginRight: 8 }} />
            <span>{folder}</span>
          </div>
        ),
        key: folder,
        children: response.data
          .filter((file: FileData) => file.folder === folder)
          .map((file: FileData) => ({
            title: (
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                <FileOutlined style={{ color: "#52c41a", marginRight: 8 }} />
                <span style={{ flex: 1 }}>{file.pdf_file.substring(file.pdf_file.lastIndexOf('/') + 1)}</span>
                <Button
                  type="link"
                  icon={<DownloadOutlined />}
                  onClick={() => handleDownload(file.pdf_file, file.pdf_file.substring(file.pdf_file.lastIndexOf('/') + 1))}
                />
              </div>
            ),
            key: file.pdf_file,
          }))
      }));
      setTreeData(newTreeData);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  useEffect(() => {
    if (visible && purchaseorderId) {
      fetchFiles();
    }
  }, [visible, purchaseorderId]);

  const handleDownload = async (fileUrl: string, fileName: string) => {
    try {
      const response = await axios.get(fileUrl, {
        responseType: 'blob',
        headers: {
          'Authorization': `Bearer ${getAuthToken()}`
        }
      });
  
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      FileSaver.saveAs(pdfBlob, fileName);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };  

  
  return (
    <Drawer
      title={`${t('Files for Purchase Order ID')} ${purchaseorderId}`}
      placement="right"
      visible={visible}
      onClose={onClose}
      width="50%"
      height="100%"
    >
      <div style={{ overflowY: "auto", height: "100%" }}>
        <Tree
          showIcon
          defaultExpandAll={false}
          treeData={treeData}
          switcherIcon={<FolderOutlined style={{ color: "#1890ff" }} />}
        />
      </div>
    </Drawer>
  );
};

export default PurchaseOrderFilesDrawer;