import React, { useState } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { AiFillDashboard } from "react-icons/ai";
import { LuUserCheck } from "react-icons/lu";
import { MdOutlineBorderColor } from "react-icons/md";
import { SiSaltproject } from "react-icons/si";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import LanguageIcon from '@mui/icons-material/Language';
import logo from '../assets/logo.png'; // Import the logo image
import InventoryIcon from '@mui/icons-material/Inventory';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ScaleIcon from '@mui/icons-material/Scale';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import PersonIcon from '@mui/icons-material/Person';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import { VscProject } from "react-icons/vsc";
import ReorderIcon from '@mui/icons-material/Reorder';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import LabelIcon from '@mui/icons-material/Label';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MapIcon from '@mui/icons-material/Map';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CategoryIcon from '@mui/icons-material/Category';
import { Tooltip } from 'antd';
import { logout } from '../utils/functions';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import { MdCategory } from "react-icons/md";
import { MdOutlineHotelClass } from "react-icons/md";
import { TbReportAnalytics } from "react-icons/tb";
import { GrStatusPlaceholderSmall } from "react-icons/gr";
import { GiDeliveryDrone } from "react-icons/gi";
import { SiSimpleanalytics } from "react-icons/si";
const MysideBar = () => {
  
  const { t, i18n } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();

  // Function to toggle between English and Spanish
  const toggleLanguage = () => {
    const currentLanguage = i18n.language;
    let nextLanguage = 'en'; // Default to English if current language isn't recognized
  
    switch (currentLanguage) {
      case 'en':
        nextLanguage = 'es';
        break;
      case 'es':
        nextLanguage = 'cs';
        break;
      case 'cs':
        nextLanguage = 'en';
        break;
      default:
        break;
    }
  
    i18n.changeLanguage(nextLanguage);
    localStorage.setItem('language', nextLanguage);
  };
  
  // Assuming you have the user's role stored in localStorage
  const userRole = localStorage.getItem('token1');
  const fullname = localStorage.getItem('token3');
  const submodule = localStorage.getItem('token4');
  const handleMouseEnter = () => {
    setCollapsed(false);
  };

  // Function to handle sidebar expand on mouse leave
  const handleMouseLeave = () => {
    setCollapsed(true);
  };


  const handleDownloadTemplate = () => {
    // Download the template file
    saveAs(process.env.PUBLIC_URL + '/data_template.xlsx', 'data_template.xlsx');
  };


  const isAdmin = () => {
    // Assuming 'admin' is the role that signifies admin privileges
    return userRole === 'admin';
  };


  return (
    <div style={{ display: "flex", height: "100vh"  }}
>

      <Sidebar className="MysideBar" collapsed={collapsed}   >
      <div className="logo-container">
          <img src={logo} alt="logo" className="logo" />
        </div>

        <Menu >

          <MenuItem className="menu1" icon={<MenuRoundedIcon />} onClick={() => setCollapsed(!collapsed)}>
          <h3 style={{ fontSize: "13px" }}><b>{fullname}</b></h3>
          </MenuItem>
          <hr  /> 
          {submodule === "logistics" || submodule === "both" ? (
  <>
            <MenuItem className={location.pathname === "/" ? "activeMenuItem" : ""} icon={<DashboardIcon style={{ color: location.pathname === "/" ? "grey" : "#a9a9a9" }} />} component={<Link to="/" />}>Inventory & Control</MenuItem>
            </>
        ) : null}
     
  {submodule === "logistics" || submodule === "both" ? (
  <>
{userRole === "admin" || userRole === "employee" ? (
  <>
            <MenuItem className={location.pathname === "/ltabs" ? "activeMenuItem" : ""} icon={<AllInclusiveIcon style={{ color: location.pathname === "/ltabs" ? "grey" : "#a9a9a9" }} />} component={<Link to="/ltabs" />}>{t("Master Data")}</MenuItem>

</>
        ) : null}
                 </>
) : null}
        
        {submodule === "logistics" || submodule === "both" ? (
  <>

        {userRole === "admin" || userRole === "employee" ? (
  <>

            <SubMenu label={t("incomingPO")} icon={<ShoppingCartIcon style={{ color: "#a9a9a9" }} />}>
              <MenuItem className={location.pathname === "/purchase" ? "activeMenuItem" : ""} icon={<ReorderIcon style={{ color: location.pathname ==="/purchase" ? "grey" : "#a9a9a9" }} />} component={<Link to="/purchase" />}>{t("createOrder")}</MenuItem>
              <MenuItem className={location.pathname === "/purchasereview" ? "activeMenuItem" : ""} icon={<BackupTableIcon style={{ color: location.pathname ==="/purchasereview" ? "grey" : "#a9a9a9" }} />} component={<Link to="/purchasereview" />}>{t("purchaseOrders")}</MenuItem>
              <MenuItem className={location.pathname === "/purchasefiles" ? "activeMenuItem" : ""} icon={<FolderOpenIcon style={{ color: location.pathname ==="/purchasefiles" ? "grey" : "#a9a9a9" }} />} component={<Link to="/purchasefiles" />}>{t("uploadFiles")}</MenuItem>
              <MenuItem className={location.pathname === "/demmurage" ? "activeMenuItem" : ""} icon={<AiFillDashboard style={{ color: location.pathname ==="/demmurage" ? "grey" : "#a9a9a9" }} />} component={<Link to="/demmurage" />}>{t("Dem. Control")}</MenuItem>
              <MenuItem className={location.pathname === "/demmurageanalysis" ? "activeMenuItem" : ""} icon={<SiSimpleanalytics style={{ color: location.pathname ==="/demmurageanalysis" ? "grey" : "#a9a9a9" }} />} component={<Link to="/demmurageanalysis" />}>{t("Dem. Analytics")}</MenuItem>           
           
            </SubMenu>
                 
 </>
        ) : null}
                         </>
) : null}

{submodule === "logistics" || submodule === "both" ? (
  <>

        {userRole === "admin" || userRole === "employee" ? (
  <>


            <SubMenu label={t("transfer")} icon={<MoveUpIcon style={{ color: "#a9a9a9" }} />}>
              <MenuItem className={location.pathname === "/transfers" ? "activeMenuItem" : ""} icon={<ViewCarouselIcon style={{ color: location.pathname ==="/transfers" ? "grey" : "#a9a9a9" }} />} component={<Link to="/transfers" />}>{t("createOrder")}</MenuItem>
              <MenuItem className={location.pathname === "/transfersreview" ? "activeMenuItem" : ""} icon={<FormatListNumberedIcon style={{ color: location.pathname ==="/transfersreview" ? "grey" : "#a9a9a9" }} />} component={<Link to="/transfersreview" />}>{t("transferOrders")}</MenuItem>
              <MenuItem className={location.pathname === "/transferfiles" ? "activeMenuItem" : ""} icon={<FolderOpenIcon style={{ color: location.pathname ==="/transferfiles" ? "grey" : "#a9a9a9" }} />} component={<Link to="/transferfiles" />}>{t("uploadFiles")}</MenuItem>
 
            </SubMenu>
</>
        ) : null}     
                         </>
) : null}

{submodule === "logistics" || submodule === "both" ? (
  <>

{userRole === "admin" || userRole === "employee" ? (
  <>

            <MenuItem className={location.pathname === "/onhand" ? "activeMenuItem" : ""} icon={<InventoryIcon style={{ color: location.pathname === "/onhand" ? "grey" : "#a9a9a9" }} />} component={<Link to="/onhand" />}>{t("Inventory")}</MenuItem>
          </>
        ) : null}     
                         </>
) : null}


{submodule === "logistics" || submodule === "both" ? (
  <>

{userRole === "admin" || userRole === "employee" ? (
  <>


            <MenuItem className={location.pathname === "/shipments" ? "activeMenuItem" : ""} icon={<LocalShippingIcon style={{ color: location.pathname === "/shipments" ? "grey" : "#a9a9a9" }} />} component={<Link to="/shipments" />}>{t("shipments")}</MenuItem>
          </>
        ) : null}  
                         </>
) : null}



{submodule === "procurement" || submodule === "both" ? (
  <>
{userRole === "admin" || userRole === "employee" ? (
  <>

            <SubMenu label={t("Procurement")} icon={<MoveUpIcon style={{ color: "#a9a9a9" }} />}>
              <MenuItem className={location.pathname === "/ptabs" ? "activeMenuItem" : ""} icon={<MdCategory style={{ color: location.pathname ==="/ptabs" ? "grey" : "#a9a9a9" }} />} component={<Link to="/ptabs" />}>{t("Key Data")}</MenuItem>
              <MenuItem className={location.pathname === "/requirement" ? "activeMenuItem" : ""} icon={<MdOutlineBorderColor style={{ color: location.pathname ==="/requirement" ? "grey" : "#a9a9a9" }} />} component={<Link to="/requirement" />}>{t("Requirement")}</MenuItem>
              <MenuItem className={location.pathname === "/rfqtabs" ? "activeMenuItem" : ""} icon={<SiSaltproject style={{ color: location.pathname ==="/rfqtabs" ? "grey" : "#a9a9a9" }} />} component={<Link to="/rfqtabs" />}>{t("RFQ")}</MenuItem>
              <MenuItem className={location.pathname === "/analytics" ? "activeMenuItem" : ""} icon={<SiSimpleanalytics style={{ color: location.pathname ==="/analytics" ? "grey" : "#a9a9a9" }} />} component={<Link to="/analytics" />}>{t("Analytics")}</MenuItem>           
            </SubMenu>
</>
        ) : null}     
                      </>
) : null}

    {/* Conditionally render User Management section only if the user is admin */}
    {isAdmin() && (
            <>
                <SubMenu label={t("userManagement")} icon={<PersonIcon style={{ color: "#a9a9a9" }} />}>
                  <MenuItem className={location.pathname === "/users" ? "activeMenuItem" : ""} icon={<PersonAddIcon style={{ color: location.pathname === "/users" ? "grey" : "#a9a9a9" }} />} component={<Link to="/users" />}>{t("addUsers")}</MenuItem>
                  <MenuItem className={location.pathname === "/permissions" ? "activeMenuItem" : ""} icon={<GroupAddIcon style={{ color: location.pathname === "/permissions" ? "grey" : "#a9a9a9" }} />} component={<Link to="/permissions" />}>{t("permissions")}</MenuItem>
                </SubMenu>

            </>
          )}

  <MenuItem onClick={toggleLanguage} icon={<LanguageIcon style={{ color: "#a9a9a9" }} />}>
  {t('Change Language')}
</MenuItem>

{userRole === "admin" || userRole === "employee" ? (
  <>

  <MenuItem onClick={handleDownloadTemplate} icon={<BackupTableIcon style={{ color: "#a9a9a9" }} />}>
  {t("Download Template")}
</MenuItem>
</>
) : null}     


  <MenuItem onClick={logout} icon={<LogoutOutlinedIcon style={{ color: location.pathname === "/logout" ? "grey" : "#757575" }} />}> {t("logout")}</MenuItem>


        </Menu>
        <div style={{ position: "relative",  color: "#a9a9a9",marginTop:"30vh" }}>
        {t("designCredit")}        
        </div>
      </Sidebar>
    </div>
  );
};

export default MysideBar;