import React, { FC, useState, useEffect } from "react";
import { Drawer, notification, Form, Input, Button, Select } from "antd";
import { AddProjectpFormProps, AuthTokenType, DataProps, UserProps} from "../utils/types";
import { getAuthToken } from "../utils/functions";
import axios, { AxiosResponse } from "axios";
import { ProjectURL, locationsUrl } from "../utils/network";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import { useGetUsers, useGetmanagers } from "../utils/hooks";
const { Option } = Select;

const AddProjectP: FC<AddProjectpFormProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose,
  editingProjectps,
  onCloseWithoutEditing, // new prop
}) => {
  const { t } = useTranslation(); // Initialize the useTranslation hook
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (editingProjectps) {
      form.setFieldsValue(editingProjectps);
    } else {
      form.resetFields();
    }
  }, [editingProjectps, form]);

  const handleFormClose = () => {
    form.resetFields();
    onClose?.();
    if (!form.isFieldsTouched()) {
      onCloseWithoutEditing?.(); // Notify the parent about closing without editing
    }
  };

  const onSubmit = async (values: DataProps) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

    try {
      let response: AxiosResponse;

      if (editingProjectps) {
        // Editing location
        response = await axios.put(`${ProjectURL}/${editingProjectps.id}/`, values, headers);
      } else {
        // Adding new location
        response = await axios.post(ProjectURL + '/', values, headers);
      }

      setLoading(false);

      if (response) {
        onSuccessCallBack?.();
        onClose?.(); // Close the drawer
      }
    } catch (error) {
      notification.error({
        message: t("Operation Error"), // Translate the error message
      });
      setLoading(false);
    }
  };
  
  const [manager, setmanager] = useState<UserProps[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);

  useGetmanagers(setmanager, setFetching);


  return (
    <Drawer
      title={editingProjectps ? t("Edit Project") : t("Add Project")} 
      visible={isVisible}
      onClose={handleFormClose}
      destroyOnClose
      width={360}
    >
      <Form
        layout="vertical"
        onFinish={onSubmit}
        form={form}
        onValuesChange={() => setHasChanges(true)}
      >
        <Form.Item
          label={t("Code Name")} 
          name="code_name"
          rules={[{ required: true, message: t('Please input the project code name!') }]}
        >
          <Input placeholder={t("Code Name")} /> 
        </Form.Item>
        <Form.Item
          label={t("Client")} 
          name="client"
          rules={[{ required: true, message: t('Please input the client!') }]}
        >
          <Input placeholder={t("Client")} /> 
        </Form.Item>
        <Form.Item
          label={t("Project Manager")} 
          name="project_manager_id"
          rules={[{ required: true, message: t('Please input the Project Manager!') }]}
        >
         <Select defaultValue="" >
            <Option value="" >{t("Project Manager")}</Option>
            {
              manager.map((item, index) => <Option value={item.id} key={index}>{item.fullname}</Option>)
            }
          </Select>
 
        </Form.Item>
        <Form.Item
          label={t("MW")} 
          name="mw"
          rules={[{ required: true, message: t('Please input the MW!') }]}
        >
          <Input placeholder={t("MW")} /> 
        </Form.Item>

        <Form.Item>
          <Button className="bbutton" htmlType="submit" type="primary" block loading={loading}>
            {editingProjectps ? t("Update") : t("Submit")} 
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddProjectP;