import React, { FC, useState, useEffect } from "react";
import { Button, Table, Modal, notification, Tag } from "antd";
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import { useGetUnitofmeasure } from "../utils/hooks";
import { AuthTokenType, unitofmeasure } from "../utils/types";
import { getAuthToken, getUnitofmeasure } from "../utils/functions";
import axios from "axios";
import { unitofmeasuresUrl } from "../utils/network";
import AddUnitofmeasureForm from "../components/AddUnitofmeasureForm";
import ContentLayout from "../components/ContentLayout";
import ContentLayoutTabs from "../components/ContentLayoutTabs";

const Unitofmeasure: FC = () => {
  const { t } = useTranslation(); // Initialize the useTranslation hook

  const columns = [
    {
      title: t("ID"),
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Is Active"),
      dataIndex: "is_active",
      key: "is_active",
      render: (isActive: boolean) => (
        <Tag color={isActive ? "green" : "red"}>
          {isActive ? t("Yes") : t("No")}
        </Tag>
      ),
    },

    {
      title: t("Actions"),
      key: "actions",
      render: (text: string, record: unitofmeasure) => (
        <span>
          <a onClick={() => handleEdit(record)}>{t("Edit")}</a>
          <span> | </span>
          <a onClick={() => handleDelete(record.id)}>{t("Delete")}</a>
        </span>
      ),
    },
  ];

  const [unitofmeasures, setUnitofmeasures] = useState<unitofmeasure[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingUnitofmeasure, setEditingUnitofmeasure] = useState<unitofmeasure | null>(null);

  useGetUnitofmeasure(setUnitofmeasures, setFetching);

  useEffect(() => {
    // When editingUnitofmeasure changes, open the modal
    if (editingUnitofmeasure) {
      setDrawerVisible(true);
    }
  }, [editingUnitofmeasure]);

  const handleEdit = (record: unitofmeasure) => {
    setEditingUnitofmeasure(record);
  };

  const handleDelete = (unitofmeasureId: number) => {
    Modal.confirm({
      title: t("Confirm Deletion"),
      content: t("Are you sure you want to delete this unit of measure?"),
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;
          // Make a DELETE request to the API endpoint for deleting a unit of measure
          await axios.delete(`${unitofmeasuresUrl}/${unitofmeasureId}/`, headers);

          // After successful deletion, fetch updated unit of measures
          getUnitofmeasure(setUnitofmeasures, setFetching);
        } catch (error) {
          // Handle error if the deletion fails
          notification.error({
            message: t("Delete Operation Error"),
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: t("Yes"),
      cancelText: t("No"),
    });
  };
  const onCloseWithoutEditing = () => {
    setEditingUnitofmeasure(null); // Reset editingCategory when form is closed without editing
  };


  const onCreateUnitofmeasure = () => {
    setDrawerVisible(true);
    setEditingUnitofmeasure(null); // Reset editingUnitofmeasure for new unit of measure
  };

  return (
    <ContentLayoutTabs
      pageTitle={t("Unit of Measures")}
      buttontitle={t("Add UOM")}
      setModalState={setDrawerVisible}
      dataSource={unitofmeasures as any}
      columns={columns as any}
      fetching={fetching}
      includeFilters={true} 
      model_name="unitofmeasure"// Assuming you don't need filters in ContentLayout
    >
      <AddUnitofmeasureForm
        onSuccessCallBack={() => {
          setDrawerVisible(false);
          getUnitofmeasure(setUnitofmeasures, setFetching);
        }}
        isVisible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        editingUnitofmeasure={editingUnitofmeasure}
        onCloseWithoutEditing={onCloseWithoutEditing}
      />
    </ContentLayoutTabs>
  );
};

export default Unitofmeasure;