import React, { useEffect, useState, ChangeEvent, useRef } from "react";
import { getAuthToken } from "../utils/functions";
import { message, Popconfirm,Modal, List, Typography } from "antd";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";

import {
  AddRemovePurchaseItemProps,
  AuthTokenType,
  currency,
  location,
  product,
  purchaseProps,
  supplier,
} from "../utils/types";
import { useGetProduct, useGetLocation, useGetSupplier, useGetProjects } from "../utils/hooks";
import { Button, Form, Input, Table, notification, Select, Switch } from "antd";
import axios, { AxiosResponse } from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { onhandUrl, purchaseorderfilesUrl, purchaseorderUrl } from "../utils/network";
import { ForkRight } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const formatProductProps = (
  product: product[],
  onAddItem: (productdata: product) => void,
  onChangeQty: (value: number, product_id: number) => void
) => {
  return product.map((item) => ({
    ...item,
    key: item.id,
    action: (
      <div>
        <Input
          style={{ width: "8vw" }}
          type="number"
          min={1}
          defaultValue={1}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChangeQty(parseInt(e.target.value), item.id as any)
          }
        />
        <Button
          style={{ width: "50px" }}
          onClick={() => onAddItem(item)}
        >
          +
        </Button>
      </div>
    ),
  }));
};

const formatPurchaseDataProps = (
  purchasedata: purchaseProps[],
  onRemoveItem: (productid: number) => void,
  onChangeQty: (value: number, product_id: number) => void
) => {
  return purchasedata.map((item) => ({
    ...item,
    key: item.id,
    action: (
      <div>
        <Input
          style={{ width: "5vw" }}
          type="number"
          min={1}
          max={item.quantity}
          defaultValue={1}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChangeQty(parseInt(e.target.value), item.id)
          }
        />
        <Button
          style={{ width: "50px" }}
          onClick={() => onRemoveItem(item.id)}
        >
          -
        </Button>
      </div>
    ),
  }));
};

function PurchaseOrder() {
  const [fetching, setFetching] = useState(true);
  const [product, setProduct] = useState<product[]>([]);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [purchasedata, setPurchaseData] = useState<purchaseProps[]>([]);
  const [purchaseitemqty, setPurchaseItemQty] =
    useState<AddRemovePurchaseItemProps>({});
  const [purchaseitemqdataty, setPurchaseItemDataQty] =
    useState<{ [key: number]: number }>({});

  const [fromlocation, setFromLocation] = useState<supplier[]>([]);
  const [fromlocationselected, setFromLocationSelected] = useState("");
  const [loading, setLoading] = useState(false);

  const [edd, Setedd] = useState<Date | null>(new Date());
  const [depdate, Setdepdate] = useState<Date | null>(new Date());

  const [tolocationselected,setToLocationSelected]=useState("")
  const [tolocation,setToLocation]=useState<location[]>([])
  const [project,setproject]=useState<any[]>([])
  const [selectedproject,setselectedProject]=useState<any[]>([])
  useGetProjects(setproject,setFetching)

  const { t } = useTranslation(); // Initialize the useTranslation hook



  const handleCostChange = (value: string, productId: number) => {
    const newCost = parseFloat(value);
    setProduct((prevProducts) =>
      prevProducts.map((product) =>
        product.id === productId ? { ...product, sku_buy_cost: newCost } : product
      )
    );
  };
  const productcolumns:any = [
    {
      title: t("Code"),
      dataIndex: "code",
      key: "code",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }:any) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={t("Search code")}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<ForkRight />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            {t("Search")}
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              confirm({ closeDropdown: false }); // This will reset the filters and trigger a table update
            }}
            size="small"
            style={{ width: 90 }}
          >
            {t("Reset")}
          </Button>
        </div>
      ),
      filterIcon: (filtered:boolean) => <ForkRight style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value:any, record:any) => record.code.toString().toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: t("Name"),
      dataIndex: "name",
      key: "name",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }:any) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={t("Search name")}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<ForkRight />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            {t("Search")}
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              confirm({ closeDropdown: false }); // This will reset the filters and trigger a table update
            }}
            size="small"
            style={{ width: 90 }}
          >
            {t("Reset")}
          </Button>
        </div>
      ),
      filterIcon: (filtered:boolean) => <ForkRight style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value:any, record:any) => record.name.toString().toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: t("Sub Product"),
      dataIndex: "subname",
      key: "subname",
    },
      {
      title: t("Cost"),
      dataIndex: "sku_buy_cost",
      key: "sku_buy_cost",
      render: (cost: number, record: product) => (
        <Input
          style={{ width: "5vw" }}
          type="number"
          value={cost}
          onChange={(e) => handleCostChange(e.target.value, record.id)}
        />
      ),
    },
    {
      title: t("Currency"),
      dataIndex: "currency",
      key: "currency",
      render: (currency: currency) => currency.name,

    },
    {
      title: t("Actions"),
      dataIndex: "action",
      key: "action",
    },
  ];

  const purchaseordercolumns = [
    {
      title: t("Name"),
      dataIndex: "item",
      key: "item",
    },
    {
      title: t("Sub Product"),
      dataIndex: "subname",
      key: "subname",
    },

    {
      title: t("Quantity"),
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: t('Amount'),
      dataIndex: 'amount',
      key: 'amount',
    },

    {
      title: t("Actions"),
      dataIndex: "action",
      key: "action",
    },
  ];

  useGetProduct(setProduct, setFetching);

  useGetSupplier(setFromLocation, setFetching);
  useGetLocation(setToLocation, setFetching);

  const additemtopurchasedata = (productdata: product) => {
    const qty = purchaseitemqty[productdata.id as any] || 1;

    let _purchasedata: purchaseProps[] = [];

    const id = purchasedata.filter(
      (id) => id.id === (productdata.id as any)
    );
    if (id.length > 0) {
      _purchasedata = purchasedata.map((id) => {
        if (id.id === (productdata.id as any)) {
          const _qty = id.quantity + qty;
          return {
            ...id,
            quantity: _qty,
            amount:productdata.sku_buy_cost*(_qty),


          };
        }
        return id;
      });
    } else {
      const temppurchasedata: purchaseProps = {
        key: productdata.id as any,
        item: productdata.name as any,
        subname: productdata.subname as any,
        id: productdata.id as any,
        quantity: qty,
        cost:productdata.sku_buy_cost,

        amount:productdata.sku_buy_cost*qty,
     
     

      };

      _purchasedata = [...purchasedata, temppurchasedata];
    }
    setPurchaseData(_purchasedata);
  };

  const removeitemfrompurchasedata = (productid: number) => {
    const qty = purchaseitemqdataty[productid] || 1;
    let _purchasedata: purchaseProps[] = [];
    const item = purchasedata.filter((item) => item.id === productid)[0];

    if (qty >= item.quantity) {
      _purchasedata = purchasedata.filter((item) => item.id !== productid);
    } else {
      _purchasedata = purchasedata.map((item) => {
        if (item.id === productid) {
          return {
            ...item,
            quantity: item.quantity - qty,
            amount:(item.cost)*(item.quantity-qty)         


          };
        }
        return item;
      });
    }
    setPurchaseData(_purchasedata);
  };

  const changepurchaseqty = (value: number, product_id: number) => {
    setPurchaseItemQty({
      ...purchaseitemqty,
      [product_id]: value,
    });
  };

  const changepurchaseremoveqty = (value: number, product_id: number) => {
    setPurchaseItemDataQty({
      ...purchaseitemqty,
      [product_id]: value,
    });
  };

  const clearPurchaseOrderData = () => {
    setPurchaseData([]);
    setPurchaseItemDataQty([]);
    form.resetFields();
  };


  const submitsto = async () => {
    const filesForRecord = fileLists[1]?.files || [];
  
    // Check if there are no files
    if (filesForRecord.length === 0) {
      notification.error({
        message: t("Please atttach files"),
      });
      return;
    }

    if (purchasedata.length < 1) {
      notification.error({
        message: t("Add Items to Order"),
      });
    } else {
      
      
      const datatosend2inv= purchasedata.map((item) => ({
        sku_code_id: item.key,
        storage_location_id: tolocationselected,
        supplier_id: fromlocationselected,
        internal_reference: form.getFieldValue("internal_reference"),
        po_number: form.getFieldValue("po_number"),
        bill_of_lading: form.getFieldValue("bill_of_lading"),
        project_id: selectedproject,
        status: "InManufacturing",
        totalunits: item.quantity
      }))
      const headers = getAuthToken() as AuthTokenType;
      try {
        for (const data of datatosend2inv) {
          await axios.post(onhandUrl, data, headers );
        }
      } catch (e) {
        notification.error({
          message: "Operation Error",
        });
      }
      const datatosend = {
        from_location_id: fromlocationselected,
        to_location_id: tolocationselected,
        expected_delivery_date: edd,
        actual_delivery_date: edd,
        status: "Open",
        po_number: form.getFieldValue("po_number"),
        departure_date: form.getFieldValue("departure_date"),
      free_days: form.getFieldValue("free_days"),
      total_price: form.getFieldValue("total_price"),
      certificate_of_origin: form.getFieldValue("certificate_of_origin"),
      downpayment: form.getFieldValue("downpayment"),
      bol: form.getFieldValue("bol"),
      din_paid: form.getFieldValue("din_paid"),
      material: form.getFieldValue("material"),
      origin_country: form.getFieldValue("origin_country"),
      origin_port: form.getFieldValue("origin_port"),
      destination_port: form.getFieldValue("destination_port"),
      container_id: form.getFieldValue("container_id"),
      project_id: selectedproject,
      shipping_company: form.getFieldValue("shipping_company"),
      material_reference: form.getFieldValue("material_reference"),
      bill_of_lading: form.getFieldValue("bill_of_lading"),
      measure: form.getFieldValue("measure"),
      din_number: form.getFieldValue("din_number"),
        purchaseorder_items_data: purchasedata.map((item) => ({
          item_id: item.key,
          quantity: item.quantity,
          amount:item.amount
        })),
      };

      setLoading(true);


      try {
        const response: AxiosResponse = await axios.post(
          purchaseorderUrl,
          datatosend,
          headers
        );
        console.log("Response data:", response.data.id); 
        handleUploadImages(response.data.id)
        notification.success({
          message: t("Operation Success"),
          description: t("Order Created Successfully"),
        });
      } catch (e) {
        notification.error({
          message: "Operation Error",
        });
        form.resetFields();
      } finally {
        form.resetFields();
        setLoading(false);
      }
    }
  };
  const [form] = Form.useForm();

  const [fileLists, setFileLists] = useState<{ [key: number]: { files: any[], folder: string } }>({});
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedPurchaseOrderId, setSelectedPurchaseOrderId] = useState<number | null>(null);
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const [currentRecordId, setCurrentRecordId] = useState<number>(1);
  const [selectedFolder, setSelectedFolder] = useState<string>('Others');
  
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleOpenUploadModal = (recordId: number) => {
    setCurrentRecordId(recordId);
    setUploadModalVisible(true);
  };

  const handleUploadImages = async (recordId: number) => {

    if (!fileLists[1]?.files.length) return;
    const headers = getAuthToken() as AuthTokenType;
    const folder = selectedFolder;
    const files = fileLists[1].files;
    try {
      const formData = new FormData();
      formData.append("purchaseorder_id", recordId.toString());
      formData.append("folder", folder);
      files.forEach((file: any) => {
        formData.append("files", file);
      });
      await axios.post(purchaseorderfilesUrl, formData, headers);
      message.success(t("Files submitted successfully"));
      setFileLists(prevFileLists => ({
        ...prevFileLists,
        [recordId]: { files: [], folder: 'Others' },
      }));
      setUploadModalVisible(false);
    } catch (error) {
      console.error("Error submitting files:", error);
      message.error(t("Failed to submit files"));
      setFileLists(prevFileLists => ({
        ...prevFileLists,
        [recordId]: { files: [], folder: 'Others' },
      }));
    }
  };

  const handleFileChange = (recordId: number, files: FileList | null) => {
    if (!files) return;
    const newFiles = Array.from(files);
    setFileLists(prevFileLists => ({
      ...prevFileLists,
      [recordId]: {
        files: [...(prevFileLists[recordId]?.files || []), ...newFiles],
        folder: selectedFolder
      },
    }));
    const fileNames = newFiles.map(file => file.name).join(", ");
    message.success(t(`Uploaded Files for Purchase order ID ${recordId}: ${fileNames}`));
  };

  const handleFolderChange = (folder: string) => {
    setSelectedFolder(folder);
  };

  const handleDeleteImage = (recordId: number, file: any) => {
    const newFileList = fileLists[recordId].files.filter((f: any) => f !== file);
    setFileLists(prevFileLists => ({
      ...prevFileLists,
      [recordId]: {
        ...prevFileLists[recordId],
        files: newFileList
      }
    }));
    message.success(t(`Deleted File ${file.name}`));
  };

  return (
    <div className="ordercreation">
      <div className="cardcart1">
        <div className="cardHeader">
          <h2 className="headContent">{t("Products")}</h2>
        
        </div>
        <br/>
        <Table
          className="custom-table"
          size="small"
          dataSource={formatProductProps(
            product,
            additemtopurchasedata,
            changepurchaseqty
          )}
          columns={productcolumns}
        />
      </div>
      <br />

      <div className="cardcart2">
        <div className="cardHeader">
          <h2 className="headContent">{t("Purchase Order")}</h2>
        </div>
        <br/>
        <Table
          className="custom-table"
          size="small"
          dataSource={formatPurchaseDataProps(
            purchasedata,
            removeitemfrompurchasedata,
            changepurchaseremoveqty
          )}
          columns={purchaseordercolumns}
        />
        <Form layout="vertical" form={form}>
          <br/>
          <Form.Item  name="from_location_id"
          rules={[
            {
              required: true,
              message: 'Please select a supplier',
            },
          ]}>
            <Select
              defaultValue=""
              onChange={(value) => setFromLocationSelected(value)}
            >
              <Option value="">{t("Supplier")}</Option>
              {fromlocation.map((item, index) => (
                <Option value={item.id} key={index}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item  name="to_location_id"
          rules={[
            {
              required: true,
              message: 'Please select a Warehouse',
            },
          ]}>
            <Select
              defaultValue=""
              onChange={(value) => setToLocationSelected(value)}
            >
              <Option value="">{t("Warehouse")}</Option>
              {tolocation.map((item, index) => (
                <Option value={item.id} key={index}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
          name="project"
          rules={[{ required: true, message: 'Please Select the Project!' }]}
        >
<Select defaultValue="" onChange={(value: any) => setselectedProject(value)}>
    <Option value="" >{t("Project")}</Option>
    {
        project.map((item,index)=><Option value={item.id}key={index}>{item.name}</Option>)
    }
</Select>
        </Form.Item>

          <Form.Item label={t('Delivery Date')} name="expected_delivery_date">
            <DatePicker selected={edd} onChange={(date) => Setedd(date)} />
          </Form.Item>
          {showAdditionalFields && (
            <>          
            <Form.Item
          label=""
          name="po_number"
          rules={[{ required:true, message: 'Please input the PO Number!' }]}
        >
          <Input placeholder="Purchase Order" />
        </Form.Item>
        
        <Form.Item
          label=""
          name="total_price"
          rules={[{ required: true, message: 'Please input the Total Price!' }]}
        >
          <Input placeholder={t("Total Price")} />
        </Form.Item>

        <Form.Item label={t('Departure Date')} name="departure_date">
          <DatePicker selected={depdate} onChange={(date) => Setdepdate(date)} />
        </Form.Item>
        <Form.Item
          label=""
          name="free_days"
          rules={[{ required: false, message: 'Please input the Free Days!' }]}
        >
          <Input placeholder={t("Free Days")} />
        </Form.Item>
        <Form.Item
          label=""
          name="material"
          rules={[{ required: true, message: 'Please input the Category!' }]}
        >
<Select placeholder={t("Category")}>
        <Option value="Modules">Modules</Option>
        <Option value="Trackers">Trackers</Option>
        <Option value="Inverters">Inverters</Option>
        <Option value="Main sub stations">Main sub stations</Option>
        <Option value="Cables">Cables</Option>
        <Option value="Earthing">Earthing</Option>
        <Option value="CCTV">CCTV</Option>
      </Select>
              </Form.Item>
        <Form.Item
          label=""
          name="certificate_of_origin"
          rules={[{ required: false, message: 'Please input Certificate of Origin!' }]}
        >
          <Input placeholder={t("Certificate of Origin")} />
        </Form.Item>
        <Form.Item label={t("Bill of Lading?")} name="bol" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item label={t("Downpayment")} name="downpayment" valuePropName="checked">
          <Switch />
          </Form.Item>

          <Form.Item label={t("Second Payment")} name="secondpayment" valuePropName="checked">
          <Switch />         
          </Form.Item>
 
          <Form.Item label={t("Third Payment")} name="thirdpayment" valuePropName="checked">
          <Switch />          
        </Form.Item>
        <Form.Item label={t("Din Paid")} name="din_paid" valuePropName="checked">
          <Switch />
        </Form.Item>

        <Form.Item
          label=""
          name="bill_of_lading"
          rules={[{ required: false, message: 'Please input the Bill of Lading!' }]}
        >
          <Input placeholder={t("Bill of Lading")} />
        </Form.Item>
        <Form.Item
          label=""
          name="material_reference"
          rules={[{ required: false, message: 'Please input the Material Reference!' }]}
        >
          <Input placeholder={t("Material Reference")} />
        </Form.Item>
        <Form.Item
          label=""
          name="origin_country"
          rules={[{ required: false, message: 'Please input the Origin Country!' }]}
        >
          <Input placeholder={t("Origin Country")} />
        </Form.Item>
        <Form.Item
          label=""
          name="origin_port"
          rules={[{ required: false, message: 'Please input the Origin Port!' }]}
        >
          <Input placeholder={t("Origin Port")} />
        </Form.Item>
        <Form.Item
          label=""
          name="destination_port"
          rules={[{ required: false, message: 'Please input the Destination Port!' }]}
        >
          <Input placeholder={t("Destination Port")} />
        </Form.Item>
        <Form.Item
          label=""
          name="container_id"
          rules={[{ required: false, message: 'Please input the Container ID!' }]}
        >
          <Input placeholder={t("Container Quantity")} />
        </Form.Item>

        <Form.Item
          label=""
          name="shipping_company"
          rules={[{ required: false, message: 'Please input the Shipping Company!' }]}
        >
          <Input placeholder={t("Shipping Company")} />
        </Form.Item>
        <Form.Item
          label=""
          name="din_number"
          rules={[{ required: false, message: 'Please input the DIN Number!' }]}
        >
          <Input placeholder={t("DIN Number")} />
        </Form.Item>
        <Button type="default" onClick={() => handleOpenUploadModal(currentRecordId)}>
          {t("Upload Files")}
        </Button>
        <br/>
        <br/>
        </>
          )}

          <Button type="text" onClick={() => setShowAdditionalFields(!showAdditionalFields)}>
            {showAdditionalFields ? t("Hide Additional Fields") : t("Show Additional Fields")}
          </Button>

        </Form>

        <br/>
        <Button className="bbutton" type="primary" onClick={submitsto}>
          {t("Create Order")}
        </Button>
        <Button className="bbuttonclear"  danger onClick={clearPurchaseOrderData}>
          {t("Clear")}
        </Button>
        <Modal
        title={t("Upload Files")}
        visible={uploadModalVisible}
        onCancel={() => setUploadModalVisible(false)}
        onOk={() => setUploadModalVisible(false)}
      >
        <Select
          defaultValue="Files"
          style={{ width: '100%', marginBottom: 16 }}
          onChange={handleFolderChange}
        >
          <Option value="Files">{t("Files")}</Option>
         </Select>
        <input
          type="file"
          multiple
          onChange={(e) => handleFileChange(currentRecordId!, e.target.files)}
          ref={fileInputRef}
        />
        <List
          size="small"
          bordered
          dataSource={currentRecordId !== null && fileLists[currentRecordId] ? fileLists[currentRecordId].files : []}
          renderItem={(file: any) => (
            <List.Item
              actions={[
                <Popconfirm
                  title={t("Are you sure to delete this file?")}
                  onConfirm={() => handleDeleteImage(currentRecordId!, file)}
                  okText={t("Yes")}
                  cancelText={t("No")}
                >
                  <DeleteOutlined />
                </Popconfirm>
              ]}
            >
              <Typography.Text>{file.name}</Typography.Text>
            </List.Item>
          )}
          style={{ marginTop: 16 }}
        />
      </Modal>

      </div>
    </div>
  );
}

export default PurchaseOrder;




