import React, { FC, useState, useEffect } from "react";
import { Modal, notification } from "antd";
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import { useGetCategorydetails } from "../utils/hooks";
import { AuthTokenType,CategoryDetailsprops} from "../utils/types";
import { getAuthToken, getCategorydetails } from "../utils/functions";
import axios from "axios";
import { categorydetailsURL } from "../utils/network";
import ContentLayoutTabs from "../components/ContentLayoutTabs";
import AddCategoryDetails from "./AddCategorydetails";

const CategoryDetails: FC = () => {
  const { t } = useTranslation(); // Initialize the useTranslation hook

  const columns = [
    {
      title: t("ID"), // Translate the title
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("Business Line"), // Translate the title
      dataIndex: ["businessline","name"],
      key: ["businessline","name"],
    },
    {
      title: t("Category"), // Translate the title
      dataIndex: "category",
      key: "category",
    },
    {
      title: t("Sub Category"), // Translate the title
      dataIndex: "subcategory",
      key: "subcategory",
    },
    {
      title: t("Attachments"), // Translate the title
      dataIndex: "attachments",
      key: "attachments",
    },

    {
      title: t("Budget Code"), // Translate the title
      dataIndex: "budgetcode1",
      key: "budgetcode1",
    },
    {
      title: t("Actions"), // Translate the title
      key: "actions",
      render: (text: string, record: CategoryDetailsprops) => (
        <span>
          <a onClick={() => handleEdit(record)}>{t("Edit")}</a>
          <span> | </span>
          <a onClick={() => handleDelete(record.id)}>{t("Delete")}</a>
        </span>
      ),
    },
  ];

  const [categorydetails, setCategorydetails] = useState<CategoryDetailsprops[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingcategorydetails, setEditingcategorydetails] = useState<CategoryDetailsprops | null>(null);

  useGetCategorydetails(setCategorydetails, setFetching);

  useEffect(() => {
    // When editingLocation changes, open the modal
    if (editingcategorydetails) {
      setDrawerVisible(true);
    }
  }, [editingcategorydetails]);

  const handleEdit = (record: CategoryDetailsprops) => {
    setEditingcategorydetails(record);
  };

  const handleDelete = (CategorydetailsId: number) => {
    Modal.confirm({
      title: t("Confirm Deletion"), // Translate the title
      content: t("Are you sure you want to delete this record?"), // Translate the content
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;
          // Make a DELETE request to the API endpoint for deleting a location
          await axios.delete(`${categorydetailsURL}/${CategorydetailsId}/`, headers);

          // After successful deletion, fetch updated locations
          getCategorydetails(setCategorydetails, setFetching);
        } catch (error) {
          // Handle error if the deletion fails
          notification.error({
            message: t("Delete Operation Error"), // Translate the message
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: t("Yes"), // Translate the text
      cancelText: t("No"), // Translate the text
    });
  };

  const onCloseWithoutEditing = () => {
    setEditingcategorydetails(null); // Reset editingCategory when form is closed without editing
  };


  return (
    <ContentLayoutTabs
      pageTitle={t("Business Line-Category")} // Translate the title
      buttontitle={t("Add Record")} // Translate the title
      setModalState={setDrawerVisible}
      dataSource={categorydetails as any}
      columns={columns as any}
      fetching={fetching}
      includeFilters={true}
      model_name="categorydetails"
    >
      <AddCategoryDetails
        onSuccessCallBack={() => {
          setDrawerVisible(false);
          getCategorydetails(setCategorydetails, setFetching);
        }}
        isVisible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        editingCategorydDetails={editingcategorydetails}
        onCloseWithoutEditing={onCloseWithoutEditing}
      />
    </ContentLayoutTabs>
  );
};

export default CategoryDetails;