import React, { useState, useEffect } from 'react';
import { Tabs, Button } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import RequirementsController from './RequirementsController';
import RequirementsManager from './RequirementsManager';
import RequirementsBuyers from './RequirementsBuyers';
import RequirementsCECOOwner from './RequirementsCECOOwner';

const { TabPane } = Tabs;

const RFQtabs: React.FC = () => {
  const [activeKey, setActiveKey] = useState<string | null>(null);
  const [designation, setDesignation] = useState<string | null>(null);
  const [userRole, setUserRole] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true); // New loading state

  useEffect(() => {
    // Fetch designation and userRole from localStorage
    const storedDesignation = localStorage.getItem('token5');
    const storedUserRole = localStorage.getItem('token1');
    setDesignation(storedDesignation);
    setUserRole(storedUserRole);
    setLoading(false); // Set loading to false after fetching data
  }, []);

  const tabContents = [
    { key: 'tab1', content: <RequirementsCECOOwner />, role: 'ceco_owner' },
    { key: 'tab2', content: <RequirementsManager />, role: 'manager' },
    { key: 'tab3', content: <RequirementsController />, role: 'controller' },
    { key: 'tab4', content: <RequirementsBuyers />, role: 'buyer' },
  ];

  const filteredTabs = tabContents.filter(
    tab => userRole === 'admin' || tab.role === designation
  );

  // Set activeKey dynamically based on filtered tabs
  useEffect(() => {
    if (filteredTabs.length > 0 && !activeKey) {
      setActiveKey(filteredTabs[0].key); // Set the active key to the first valid tab
    }
  }, [filteredTabs, activeKey]);

  const currentIndex = filteredTabs.findIndex(tab => tab.key === activeKey);

  const handleNext = () => {
    if (currentIndex < filteredTabs.length - 1) {
      setActiveKey(filteredTabs[currentIndex + 1].key);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setActiveKey(filteredTabs[currentIndex - 1].key);
    }
  };

  // Conditional rendering moved to JSX
  return (
    <div className="card">
      <div className="cardHeader">
        <h2 className="headContent">Approvals</h2>
        <div className="navigation-buttons">
          <Button onClick={handlePrevious} disabled={currentIndex === 0}>
            <LeftOutlined />
          </Button>
          <Button onClick={handleNext} disabled={currentIndex === filteredTabs.length - 1}>
            <RightOutlined />
          </Button>
        </div>
      </div>
      {!loading && filteredTabs.length > 0 ? (
        <Tabs activeKey={activeKey!} onChange={setActiveKey} type="card" className='predicted-tab'>
          {filteredTabs.map(tab => (
            <TabPane tab="" key={tab.key}>
              {tab.content}
            </TabPane>
          ))}
        </Tabs>
      ) : null}
    </div>
  );
};

export default RFQtabs;