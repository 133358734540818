import React, { FC, useState } from "react";
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import { useGetRequirementsPivot } from "../utils/hooks";
import { Requirement } from "../utils/types";


// create Plotly renderers via dependency injection
const PlotlyRenderers = createPlotlyRenderers(Plot);

const Analysis: FC = () => {
  const [pivotState, setPivotState] = useState({});
  const [Requirements, setRequirements] = useState<Requirement[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  useGetRequirementsPivot(setRequirements, setFetching);

  let data: any = [];
  if (Requirements.length > 0) {
    const columnNames = Object.keys(Requirements[0]);
    data = [columnNames, ...Requirements.map(item => Object.values(item))];
  }

  return (
    <div className="card">
      <h2 className="headContent">Analysis</h2>
      {fetching ? (
        <p>Loading...</p>
      ) : (
        // Wrap PivotTableUI in a div with overflow-x: auto for horizontal scrolling
        <div className="pivot-table-container">
          <PivotTableUI
            data={data}
            onChange={(s) => setPivotState(s)}
            renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
            {...pivotState}
          />
        </div>
      )}
    </div>
  );
};

export default Analysis;