import React, { FC, useState, useEffect } from "react";
import { Drawer, notification, Form, Input, Button, DatePicker, Switch, Select } from "antd";
import { useTranslation } from "react-i18next"; // Import the translation hook
import { Shipment, AddShipmentFormProps, DataProps, AuthTokenType, category } from "../utils/types";
import { useGetCategory, useGetProjects, useGetShipments } from "../utils/hooks";
import { getAuthToken} from "../utils/functions";
import axios, { AxiosResponse } from "axios";
import { shipmentURL } from "../utils/network";
import dayjs from "dayjs"; // Import dayjs
const { Option } = Select;

const AddShipmentForm: FC<AddShipmentFormProps> = ({
  isVisible,
  onSuccessCallBack,
  onClose,
  editingShipment,
  onCloseWithoutEditing,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [fetching, setFetching] = useState<boolean>(true);

  const { t } = useTranslation(); // Initialize the translation hook

  const [shipment, setShipments] = useState<Shipment[]>([]);
  useGetShipments(setShipments, setFetching);

  const [project, setProject] = useState<any[]>([])
  const [selectedProject, setSelectedProject] = useState<any[]>([])
  useGetProjects(setProject, setFetching)

  const [categories, setCategories] = useState<category[]>([]);
  useGetCategory(setCategories, setFetching);

  useEffect(() => {
    if (editingShipment) {
      form.setFieldsValue({
        ...editingShipment,
        project_id: editingShipment.project?.id,
        type_of_material_id: editingShipment.type_of_material?.id,
        real_etd: editingShipment.real_etd ? dayjs(editingShipment.real_etd) : null,
        real_eta: editingShipment.real_eta ? dayjs(editingShipment.real_eta) : null,
        real_discharge: editingShipment.real_discharge ? dayjs(editingShipment.real_discharge) : null,
        finish_free_days: editingShipment.finish_free_days ? dayjs(editingShipment.finish_free_days) : null,
        start_demurrage: editingShipment.start_demurrage ? dayjs(editingShipment.start_demurrage) : null,
        returned_date: editingShipment.returned_date ? dayjs(editingShipment.returned_date) : null,
      });
    } else {
      form.resetFields();
    }
  }, [editingShipment, form]);

  const onSubmit = async (values: DataProps) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

    try {
      let response: AxiosResponse;

      if (editingShipment) {
        response = await axios.put(`${shipmentURL}/${editingShipment.id}`, values, headers);
      } else {
        response = await axios.post(shipmentURL, values, headers);
      }

      setLoading(false);

      if (response) {
        form.resetFields()
        onSuccessCallBack?.();
        onClose?.(); // Close the drawer
      }
    } catch (error) {
      notification.error({
        message: t("Operation Error"), // Translate the error message
      });
      setLoading(false);
    }
  };

  const handleFormClose = () => {
    form.resetFields();
    onClose?.();
    if (!form.isFieldsTouched()) {
      onCloseWithoutEditing?.(); // Notify the parent about closing without editing
    }
  };

  return (
    <Drawer
      title={editingShipment ? t("Edit Shipment") : t("Add Shipment")} // Translate the title
      visible={isVisible}
      onClose={handleFormClose}
      destroyOnClose
      width={360}
    >
      <Form layout="vertical" onFinish={onSubmit} form={form}>
        <Form.Item label={t("Reference")} name="reference" rules={[{ required: true, message: t("Please input the reference!") }]}>
          <Input placeholder={t("Reference")} />
        </Form.Item>

        <Form.Item label={t("CI")} name="ci" rules={[{ required: true, message: t("Please input the CI!") }]}>
          <Input placeholder={t("CI")} />
        </Form.Item>

        <Form.Item label={t("Supplier")} name="supplier" rules={[{ required: true, message: t("Please input the supplier!") }]}>
          <Input placeholder={t("Supplier")} />
        </Form.Item>

        <Form.Item label={t("Total Container")} name="total_container" rules={[{ required: true, message: t("Please input the Container Numbers!") }]}>
          <Input placeholder={t("total_container")} />
        </Form.Item>

        <Form.Item label={t("Vessel Name")} name="vessel_name" rules={[{ required: true, message: t("Please input the Vessel Name!") }]}>
          <Input placeholder={t("Vessel Name")} />
        </Form.Item>

        <Form.Item label={t("Dispatch Number")} name="dispatch_no" rules={[{ required: true, message: t("Please input the Dispatch Number!") }]}>
          <Input placeholder={t("Dispatch Number")} />
        </Form.Item>

        <Form.Item label={t("DIN")} name="din" rules={[{ required: true, message: t("Please input the DIN!") }]}>
          <Input placeholder={t("DIN")} />
        </Form.Item>

        <Form.Item label={t("Comments")} name="comments">
          <Input.TextArea placeholder={t("Comments")} />
        </Form.Item>

        <Form.Item
          name="project_id"
          rules={[{ required: false, message: t("Please Select the Project!") }]}
        >
          <Select defaultValue="" onChange={(value: any) => setSelectedProject(value)}>
            <Option value="" >{t("Project")}</Option>
            {
              project.map((item, index) => <Option value={item.id} key={index}>{item.name}</Option>)
            }
          </Select>
        </Form.Item>

        <Form.Item
          label=""
          name="type_of_material_id"
          rules={[{ required: true, message: t("Please select the category!") }]}
        >
          <Select defaultValue="" >
            <Option value="" >{t("Material Type")}</Option>
            {
              categories.map((item, index) => <Option value={item.id} key={index}>{item.name}</Option>)
            }
          </Select>
        </Form.Item>

        <Form.Item label={t("Custom Agency")} name="custom_agency" rules={[{ required: true, message: t("Please input the custom agency!") }]}>
          <Input placeholder={t("Custom Agency")} />
        </Form.Item>
        <Form.Item label={t("Bill of Lading")} name="bill_of_lading" rules={[{ required: true, message: t("Please input the bill of lading!") }]}>
          <Input placeholder={t("Bill of Lading")} />
        </Form.Item>
        <Form.Item label={t("Got Original BL?")} name="got_original_bl" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item label={t("Container ID")} name="container_id" rules={[{ required: true, message: t("Please input the container ID!") }]}>
          <Input placeholder={t("Container ID")} />
        </Form.Item>
        <Form.Item label={t("Real ETD")} name="real_etd" rules={[{ required: true, message: t("Please select the real ETD!") }]}>
          <DatePicker onChange={(date) => form.setFieldsValue({ real_etd: date ? dayjs(date) : null })} />
        </Form.Item>
        <Form.Item label={t("Real ETA")} name="real_eta" rules={[{ required: true, message: t("Please select the real ETA!") }]}>
          <DatePicker onChange={(date) => form.setFieldsValue({ real_eta: date ? dayjs(date) : null })} />
        </Form.Item>
        <Form.Item label={t("Real Discharge")} name="real_discharge" rules={[{ required: true, message: 'Please select the real discharge!' }]}>
          <DatePicker onChange={(date) => form.setFieldsValue({ real_discharge: date ? dayjs(date) : null })} />
        </Form.Item>
        <Form.Item label={t("Free Days")} name="free_days" rules={[{ required: true, message: 'Please input the free days!' }]}>
          <Input type="number" placeholder="Free Days" />
        </Form.Item>
        <Form.Item label={t("Port of Discharge")} name="port_of_discharge" rules={[{ required: true, message: 'Please input the port of discharge!' }]}>
          <Input placeholder="Port of Discharge" />
        </Form.Item>
        <Form.Item label={t("Daily Demurrage Cost")} name="daily_demurrage_cost" rules={[{ required: true, message: 'Please input the daily demurrage cost!' }]}>
          <Input type="number" placeholder="Daily Demurrage Cost" />
        </Form.Item>
        <Form.Item label={t("Got Return Date?")} name="return_avl" valuePropName="checked">
          <Switch />
        </Form.Item>

        <Form.Item label={t("Returned Date")} name="returned_date" rules={[{ required: true, message: 'Please select the returned date!' }]}>
          <DatePicker onChange={(date) => form.setFieldsValue({ returned_date: date ? dayjs(date) : null })} />
        </Form.Item>
        <Form.Item>
        <Button className="bbutton" htmlType="submit" type="primary" loading={loading}>
  {editingShipment ? t("Update") : t("Submit")}
</Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddShipmentForm;