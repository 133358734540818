import { useEffect, useState, ChangeEvent } from "react"
import {getAuthToken } from "../utils/functions"
import { AddRemovestocktransferItemProps, AuthTokenType, InventoryProps, location,  stocktransferProps } from "../utils/types"
import { useGetLocation, useGetProjects } from "../utils/hooks"
import { Button, Form, Input, Table, notification } from "antd"
import axios,{AxiosResponse} from "axios"
import { InventoryUrl, stocktransferorderUrl} from "../utils/network"
import DatePicker from "react-datepicker";
import { ForkRight } from "@mui/icons-material";

import {Select} from 'antd'
import { useTranslation } from "react-i18next"
const {Option} = Select


const formatInventoryProps=(inventory:InventoryProps[],
  onAddItem:(inventorydata:InventoryProps)=>void,
  onChangeQty:(value:number,inventory_id:number)=>void
  )=>{
  return inventory.map(item=>(

    {
      ...item,
      key:item.id,
      action:<div>
      <Input
      style={{width: "5vw"}}
      type="number"
      min={1}
      
      max={(item.total_units as number)}
      defaultValue={1}
      onChange={(e:ChangeEvent<HTMLInputElement>)=>onChangeQty(parseInt(e.target.value),item.id)}
      />
      <Button style={{width: "50px"}} onClick={()=>onAddItem(item)}>+</Button>
      </div>

    }
  )
    )
}

const formatstocktransferProps=(stocktransfer:stocktransferProps[],
  onRemoveItem:(inventoryid:number)=>void,
  onChangeQty:(value:number,inventory_id:number)=>void)=>{
  return stocktransfer.map(item=>(

    {
      ...item,
      key:item.id,
      action:<div>
      <Input
      style={{width: "8vw"}}
                type="number"
                min={1}
                max={item.quantity}
                defaultValue={1}
                onChange={(e:ChangeEvent<HTMLInputElement>)=>onChangeQty(parseInt(e.target.value),item.id)}
                />
                <Button style={{width: "50px"}} onClick={()=>onRemoveItem(item.id)}>-</Button>
      </div>

    }
  )
    )
}


function StocktransferOrder(){
        
      const[fetching,setFetching]=useState(true)
      const [inventory,setInventory]=useState<InventoryProps[]>([])
      const [stocktransfer,setstocktransfer]=useState<stocktransferProps[]>([])
      const [stocktransferitemqty,setstocktransferItemQty]=useState<AddRemovestocktransferItemProps>([])
      const [stocktransferitemdataqty,setstocktransferItemDataQty]=useState<{[key:number]:number}>([])
      
      const [fromlocation,setFromLocation]=useState<location[]>([])
      const [fromlocationselected,setFromLocationSelected]=useState("")
      const [tolocationselected,setToLocationSelected]=useState("")
      const [pps,Setpps]=useState("50")
      const [type,SetType]=useState("")
      const [depdate, Setdepdate] = useState<Date | null>(new Date());
      const [showAdditionalFields, setShowAdditionalFields] = useState(false);

      const [loading,setLoading]=useState(false)
      
      const [edd,Setedd]=useState<Date | null>(new Date())
     
      const [tolocation,setToLocation]=useState<location[]>([])
      const [location,setLocation]=useState<location[]>([])
      const [project,setproject]=useState<any[]>([])
      const [selectedproject,setselectedProject]=useState<any[]>([])
      useGetLocation(setLocation,setFetching)
      useGetProjects(setproject,setFetching)
  
      const { t } = useTranslation(); // Initialize the useTranslation hook

      const inventorycolumns:any = [
        {
          title: t("Code"),
          dataIndex: "code",
          key: "code",
          filters: inventory.map((item) => ({ text: item.name, value: item.name })),
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
              <Input
                placeholder={t('Search name')}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => confirm()}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              />
              <Button
                type="primary"
                onClick={() => confirm()}
                icon={<ForkRight />}
                size="small"
                style={{ width: 90, marginRight: 8 }}
              >
                {t('Search')}
              </Button>
              <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
                {t('Reset')}
              </Button>
            </div>
          ),
          filterIcon: (filtered: boolean) => <ForkRight style={{ color: filtered ? '#1890ff' : undefined }} />,
          onFilter: (value: string, record: InventoryProps) => record.name.toLowerCase().includes(value.toLowerCase()),

        },      
        {
          title: t('Name'),
          dataIndex: 'name',
          key: 'name',
          filters: inventory.map((item) => ({ text: item.name, value: item.name })),
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
              <Input
                placeholder={t('Search name')}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => confirm()}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              />
              <Button
                type="primary"
                onClick={() => confirm()}
                icon={<ForkRight />}
                size="small"
                style={{ width: 90, marginRight: 8 }}
              >
                {t('Search')}
              </Button>
              <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
                {t('Reset')}
              </Button>
            </div>
          ),
          filterIcon: (filtered: boolean) => <ForkRight style={{ color: filtered ? '#1890ff' : undefined }} />,
          onFilter: (value: string, record: InventoryProps) => record.name.toLowerCase().includes(value.toLowerCase()),
        },
        {
          title: t("Sub Product"),
          dataIndex: "subname",
          key: "subname",
        },
    
        {
          title: t('Brand'),
          dataIndex: 'supplier_name',
          key: 'supplier_name',
        },

        {
          title: t('Inventory'),
          dataIndex: 'total_units',
          key: 'total_units',
        },
        {
          title: t('Actions'),
          dataIndex: 'action',
          key: 'action',
        },
      ];

      const stocktransferordercolumns = [
        {
          title: t('Name'),
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: t("Sub Product"),
          dataIndex: "subname",
          key: "subname",
        },
    

        {
          title: t('Brand'),
          dataIndex: 'supplier_name',
          key: 'supplier_name',
        },
        
        {
            title: t('Quantity'),
            dataIndex: 'quantity',
            key: 'quantity',
          },
          {
            title: t('Actions'),
            dataIndex: 'action',
            key: 'action',
          },
        ];

      
      const getInventory=async ()=>{
        const headers=getAuthToken() as AuthTokenType
        const response:AxiosResponse =await axios.post(InventoryUrl,{ location_id: fromlocationselected,project_id: selectedproject },headers).catch(
          (e)=>{ notification.error({
              message:"Operation Error",
              description:e.response?.data.error
          })}
          ) as AxiosResponse<InventoryProps>
          
          setInventory(response.data) 
      }
  
      useEffect(() => {
        if (fromlocationselected && selectedproject) {
          setInventory([]);
          getInventory();
        }
      }, [fromlocationselected, selectedproject]);    
const additemtostocktransfer=(inventorydata:InventoryProps)=>{
          const qty=stocktransferitemqty[inventorydata.id]||1
          let _stocktransfer:stocktransferProps[]=[]
          let qtyflag=false;
          

          const id=stocktransfer.filter(id=>id.id===inventorydata.id)
          if(id.length>0){
            _stocktransfer=stocktransfer.map(id=>{
              if(id.id===inventorydata.id){
                const _qty=id.quantity+qty
                if(_qty>inventorydata.total_units){
                  qtyflag=true
                }
                return{
                  ...id,
                  quantity:_qty,
                }
              }
              return id 

            })
          }
          else{
            const tempstocktransfer:stocktransferProps={
              key:String(inventorydata.id),
              id:inventorydata.id,
              name:inventorydata.name,
              subname: inventorydata.subname as any,
              supplier_name:inventorydata.supplier_name,
              supplier_id:inventorydata.supplier_id,

              quantity:qty,              

            }
            if(qty>inventorydata.total_units){
              qtyflag=true
            }
            
            _stocktransfer=[...stocktransfer,tempstocktransfer]

          }
          if(qtyflag){
            notification.error({
              message:t("Not Enough Inventory")
            })
            return


          }
        setstocktransfer(_stocktransfer)
        
}

const removeitemfromstocktransfer=(inventoryid:number)=>{
   const qty=stocktransferitemdataqty[inventoryid]||1
   let _stocktransfer:stocktransferProps[]=[]
   const item=stocktransfer.filter(item=>item.id===inventoryid)[0]
   
   if(qty>=item.quantity){
    _stocktransfer=stocktransfer.filter(item=>item.id!==inventoryid)
   }

   else{
    _stocktransfer=stocktransfer.map(item=>{
      if(item.id===inventoryid){
             return{
          ...item,
          quantity:item.quantity-qty,
        }
      }
      return item
    })
}
  setstocktransfer(_stocktransfer)
}
const changeinventoryqty=(value:number,inventory_id:number)=>{
  setstocktransferItemQty({
    ...stocktransferitemqty,
    [inventory_id]:value
  })
}

const changeinventoryremoveqty=(value:number,inventory_id:number)=>{
  setstocktransferItemDataQty({
    ...stocktransferitemqty,
    [inventory_id]:value
  })
}


const clearstocktransfer=()=>
{
  setstocktransfer([])
  setstocktransferItemDataQty([])
  form.resetFields()
}

useGetLocation(setFromLocation,setFetching)
useGetLocation(setToLocation,setFetching)

const submitstocktransfer= async ()=>{
if(stocktransfer.length<1){
  notification.error({
    message:"Add Items to Order"
  })}
  else 
  {
    const datatosend={
    from_location_id : fromlocationselected,    
    to_location_id : tolocationselected,
    expected_delivery_date :edd,
    actual_delivery_date :edd,
    type :type,
    status : "Open",
    departure_date: form.getFieldValue("departure_date"),
    internal_reference: form.getFieldValue("internal_reference"),
    project_id: selectedproject,
    transport_company: form.getFieldValue("tranport_company"),
    truck_plate: form.getFieldValue("truck_plate"),
    guide_number: form.getFieldValue("guide_number"),
    container_id: form.getFieldValue("container_id"),
    stocktransferorder_items_data:stocktransfer.map(item=>({
      item_id:item.id,
      quantity:item.quantity,
      supplier_id:item.supplier_id,

      
    }))
 
  }
  
    setLoading(true)
    const headers=getAuthToken() as AuthTokenType
 
    const response:AxiosResponse =await axios.post(stocktransferorderUrl,datatosend,headers).catch(
        (e)=>{ notification.error({
            message:"Operation Error",
            description:e.response?.data.error
        })
        clearstocktransfer()
        form.resetFields()
    }
        ) as AxiosResponse
        setLoading(false)
    
        if(response){
            notification.success({
                message:t("Operation Success"),
                description:t("Order Created Successfully")
            })

        }
        clearstocktransfer() 
      } 
 
}
const [form]=Form.useForm();

useEffect(() => {
  if (fromlocationselected !== '') {
    setInventory([]);
    getInventory();
  }
}, [fromlocationselected]);


const handleChangetype = (event: React.ChangeEvent<HTMLInputElement>) => {
  //Get input value from "event"
  SetType(event.target.value);
  
};

    return (
      <div className="ordercreation">
        <div className="cardcart1">
        <div className="cardHeader">
                <h2 className="headContent">{t("Products")}</h2>
        
      </div>
      <br/>
      <Table 
      className="custom-table"
      size="small"    
      dataSource={formatInventoryProps(inventory,additemtostocktransfer,changeinventoryqty)}
      columns={inventorycolumns}
      /
      >
       
        </div>
        

        <div className="cardcart2">
        <div className="cardHeader">
          <h2 className="headContent">{t("Transfer Order")}</h2>
        </div>
        <br/>
      <Table     
      className="custom-table"
      size="small"
      dataSource={formatstocktransferProps(stocktransfer,removeitemfromstocktransfer,changeinventoryremoveqty)}
      columns={stocktransferordercolumns}
      /
      >
           <Form
            
            layout="vertical"
            
            form={form}
        >
          <br/>
          <Form.Item
          name="project"
          rules={[{ required: true, message: 'Please Select the Project!' }]}
        >
<Select defaultValue="" onChange={(value: any) => setselectedProject(value)}>
    <Option value="" >{t("Project")}</Option>
    {
        project.map((item,index)=><Option value={item.id}key={index}>{item.name}</Option>)
    }
</Select>
        </Form.Item>

            <Form.Item 
            label=""
            name="from_location_id"
            
                        >
            <Select  defaultValue= "" onChange={value => setFromLocationSelected(value)}>
                    <Option value="">{t("From Location")}</Option>
                    {
                            fromlocation.map((item,index)=><Option value={item.id}key={index}>{item.name}</Option>)
                    }
                    
                </Select>
            </Form.Item>
            
            <Form.Item 
            label=""
            name="to_location_id"
                        >
            <Select defaultValue="" onChange={value => setToLocationSelected(value)}>
                    <Option value="" >{t("To Location")}</Option>
                    {
                            tolocation.map((item,index)=><Option value={item.id}key={index}>{item.name}</Option>)
                    }
                    
                </Select>
            </Form.Item>
            <Form.Item
                    label={t('Expected Delivery Date')}
                    name='expected_delivery_date'
>
            <DatePicker selected={edd} onChange={(date) => Setedd(date)}  />
            </Form.Item>
            
            <Form.Item 
            
            name="type"
            rules={[{ required: false, message: 'Type of Order' }]}
            >
                <Input onChange={handleChangetype} placeholder="Type" type="type"/>
                        </Form.Item>

        {showAdditionalFields && (
            <>         
         <Form.Item label={t("Departure Date")} name="departure_date">
          <DatePicker selected={depdate} onChange={(date) => Setdepdate(date)} />
        </Form.Item>    
        <Form.Item
          name="internal_reference"
          rules={[{ required: false, message: 'Please input the Internal Reference!' }]}
        >
          <Input placeholder={t("Internal Reference")} />
        </Form.Item>
       
        <Form.Item
          name="transport_company"
          rules={[{ required: false, message: 'Please input the Transport Company!' }]}
        >
          <Input placeholder={t("Transport Company")} />
        </Form.Item>
        <Form.Item
          name="truck_plate"
          rules={[{ required: false, message: 'Please input the Truck Plate!' }]}
        >
          <Input placeholder={t("Truck Plate")} />
        </Form.Item>
        <Form.Item
          name="guide_number"
          rules={[{ required: false, message: 'Please input the Guide Number!' }]}
        >
          <Input placeholder={t("Guide Number")} />
        </Form.Item>
        <Form.Item
          name="container_id"
          rules={[{ required: false, message: 'Please input the Container id!' }]}
        >
          <Input placeholder={t("Container Id")} />
        </Form.Item>


        </>
          )}
          <Button type="text" onClick={() => setShowAdditionalFields(!showAdditionalFields)}>
            {showAdditionalFields ? t("Hide Additional Fields") : t("Show Additional Fields")}
          </Button>
          <br/>
          <br/>
          </Form>
        <Button className="bbutton" type="primary" onClick={submitstocktransfer}>{t("Create Order")}</Button>
        <Button className="bbuttonclear" danger onClick={clearstocktransfer}>{t("Clear")}</Button> 
        
        </div>

        
      </div>
    )
}
export default StocktransferOrder