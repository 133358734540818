import React, { FC, useState, useEffect } from "react";
import { Drawer, notification, Form, Input, Button, DatePicker, Switch } from "antd";
import { useTranslation } from "react-i18next";
import { AuthTokenType, EditPurchaseOrderProps, purchaseProps } from "../utils/types";
import axios, { AxiosResponse } from "axios";
import { purchaseorderUrl } from "../utils/network";
import { getAuthToken } from "../utils/functions";

const EditPurchaseOrderDrawer: FC<EditPurchaseOrderProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose,
  editingPurchase,
  onCloseWithoutEditing, // new prop
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (editingPurchase) {
      form.setFieldsValue(editingPurchase);
    } else {
      form.resetFields();
    }
  }, [editingPurchase, form]);

  const handleSubmit = async (values: Partial<purchaseProps>) => {
    setLoading(true);
    try {
      let response: AxiosResponse;
      setLoading(true);

      const headers = getAuthToken() as AuthTokenType;

      // Constructing the URL
      response = await axios.patch(purchaseorderUrl+'/'+editingPurchase?.id, values, headers);

      setLoading(false);

      if (response) {
        onSuccessCallBack?.();
        onClose();
        notification.success({
          message: t("Edit Successful"),
          description: t("Changes have been saved successfully."),
        });
      }
    } catch (error) {
      notification.error({
        message: t("Operation Error"),
      });
      setLoading(false);
    }
  };

  const handleDrawerClose = () => {
    onClose();
    if (!form.isFieldsTouched()) {
      onCloseWithoutEditing?.();
    }
  };

  return (
    <Drawer
      title={t("Edit Purchase Order")}
      visible={isVisible}
      onClose={handleDrawerClose}
      destroyOnClose
      width={360}
    >
      <Form layout="vertical" onFinish={handleSubmit} form={form} onValuesChange={() => setHasChanges(true)}>
        <Form.Item label={t("ID")} name="id">
          <Input disabled />
        </Form.Item>
        <Form.Item label={t("PO Number")} name="po_number">
          <Input />
        </Form.Item>
        <Form.Item label={t("Free Days")} name="free_days">
          <Input />
        </Form.Item>
        <Form.Item label={t("Internal Reference")} name="internal_reference">
          <Input />
        </Form.Item>
        <Form.Item label={t("Origin Country")} name="origin_country">
          <Input />
        </Form.Item>
        <Form.Item label={t("Origin Port")} name="origin_port">
          <Input />
        </Form.Item>
        <Form.Item label={t("Destination Port")} name="destination_port">
          <Input />
        </Form.Item>
        <Form.Item label={t("Container Quantity")} name="container_id">
          <Input />
          <Form.Item label={t("Bill of Lading?")} name="bol" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item label={t("Downpayment")} name="downpayment" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item label={t("Din Paid")} name="din_paid" valuePropName="checked">
          <Switch />
        </Form.Item>

        </Form.Item>
        <Form.Item label={t("Bill of Lading")} name="bill_of_lading">
          <Input />
        </Form.Item>
        <Form.Item label={t("DIN Number")} name="din_number">
          <Input />
        </Form.Item>
        <Form.Item label={t("Shipping Company")} name="shipping_company">
          <Input />
        </Form.Item>
        <Form.Item label={t("Certificate of Origin")} name="certificate_of_origin">
          <Input />
        </Form.Item>
        <Form.Item>
          <Button className="bbutton" htmlType="submit" type="primary" block loading={loading}>
            {t("Save")}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default EditPurchaseOrderDrawer;