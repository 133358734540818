import React, { FC, useState, useEffect } from "react";
import { Button, Table, Modal, notification, Select } from "antd";
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import { useGetProduct } from "../utils/hooks";
import { AuthTokenType, product } from "../utils/types";
import { getAuthToken, getProduct } from "../utils/functions";
import AddProductForm from "../components/AddProductForm";
import axios from "axios";
import { productsUrl } from "../utils/network";
import ContentLayout from "../components/ContentLayout";
import ContentLayoutTabs from "../components/ContentLayoutTabs";

const Products: FC = () => {
  const { t } = useTranslation(); // Initialize the useTranslation hook

  const { Option } = Select;
  const columns = [
    {
      title: t("ID"),
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("Material Code Id"),
      dataIndex: "code",
      key: "code",
    },
    {
      title: t("Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Sub Product"),
      dataIndex: "subname",
      key: "subname",
    },
    {
      title: t("SKU Description"),
      dataIndex: "sku_description",
      key: "sku_description",
    },
    {
      title: t("SKU Unit of Measure"),
      dataIndex: "uom",
      key: "uom",
    },
    {
      title: t("Intercompany Purchase Price"),
      dataIndex: "sku_buy_cost",
      key: "sku_buy_cost",
    },
    {
      title: t("SKU Price"),
      dataIndex: "sku_price",
      key: "sku_price",
    },
    {
      title: t("Category"),
      dataIndex: "cat",
      key: "cat",
    },
    {
      title: t("Sub Category"),
      dataIndex: "subcat",
      key: "subcat",
    },
    {
      title: t("Currency"),
      dataIndex: "curr",
      key: "curr",
    },
    {
      title: t("Image"),
      dataIndex: "image",
      key: "image",
      render: (image: string) => (
        image ? <img src={image} alt="Product" style={{width:"4vw" ,height:"4vh"}} /> : t("No Image Available")
      ),
    },
    {
      title: t("Actions"),
      key: "actions",
      render: (text: string, record: product) => (
        <span>
          <a onClick={() => handleEdit(record)}>{t("Edit")}</a>
          <span> | </span>
          <a onClick={() => handleDelete(record.id)}>{t("Delete")}</a>
        </span>
      ),
    },
  ];

  const [products, setProducts] = useState<product[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [formVisible, setFormVisible] = useState(false);
  const [editingProduct, setEditingProduct] = useState<product | null>(null);


  useGetProduct(setProducts, setFetching);

  useEffect(() => {
    // When editingProduct changes, open the form
    if (editingProduct) {
      setFormVisible(true);
    }
  }, [editingProduct]);

  const handleEdit = (record: product) => {
    setEditingProduct(record);
  };

  const onCloseWithoutEditing = () => {
    setEditingProduct(null); // Reset editingCategory when form is closed without editing
  };

  const handleDelete = (productId: number) => {
    Modal.confirm({
      title: t("Confirm Deletion"),
      content: t("Are you sure you want to delete this product?"),
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;

          // Make a DELETE request to the API endpoint for deleting a product
          await axios.delete(`${productsUrl}/${productId}/`, headers);

          // After successful deletion, fetch updated products
          getProduct(setProducts, setFetching);
        } catch (error) {
          // Handle error if the deletion fails
          notification.error({
            message: t("Delete Operation Error"),
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: t("Yes"),
      cancelText: t("No"),
    });
  };

  return (
    <ContentLayoutTabs
      pageTitle={t("Products")}
      buttontitle={t("Add Product")}
      setModalState={setFormVisible}
      dataSource={products as any}
      columns={columns as any}
      fetching={fetching}
      includeFilters={true}
      model_name="product"
    >
      <AddProductForm
        onSuccessCallBack={() => {
          setFormVisible(false);
          getProduct(setProducts, setFetching);
        }}
        isVisible={formVisible}
        onClose={() => setFormVisible(false)}
        editingProduct={editingProduct}
        onCloseWithoutEditing={onCloseWithoutEditing}
      />
    </ContentLayoutTabs>
  );
};

export default Products;