import React, { useState } from 'react';
import { Tabs, Button } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import BudgetCodes from './BudgetCodes';
import Cecos from './cecos';
import DeliveryTerms from './DeliveryTerm';
import Statuses from './Status';
import ProjectPs from './ProjectP';
import ProcessCategorizations from './ProcessCategorizations';
import ProcessSpecifications from './ProcessSpecification';
import CategoryDetails from './Categorydetails';
import BusinessLine from './BusinessLine';

const { TabPane } = Tabs;

const ProcurementTabs: React.FC = () => {
  const [activeKey, setActiveKey] = useState<string>('tab1');

  const tabContents = [
    { key: 'tab1', content: <BusinessLine /> },
    { key: 'tab2', content: <BudgetCodes /> },
    { key: 'tab3', content: <Cecos/> },
    { key: 'tab4', content: <DeliveryTerms/> },
    { key: 'tab5', content: <Statuses/> },
    { key: 'tab6', content: <ProjectPs/> },
    { key: 'tab7', content: <ProcessCategorizations/> },
    { key: 'tab8', content: <ProcessSpecifications/> },
    { key: 'tab9', content: <CategoryDetails/> },

  ];

  const currentIndex = tabContents.findIndex(tab => tab.key === activeKey);

  const handleNext = () => {
    if (currentIndex < tabContents.length - 1) {
      setActiveKey(tabContents[currentIndex + 1].key);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setActiveKey(tabContents[currentIndex - 1].key);
    }
  };

  return (
    <div className="card">
      <div className="cardHeader">
        <h2 className="headContent">Key Data</h2>
        <div className="navigation-buttons">
          <Button onClick={handlePrevious} disabled={currentIndex === 0}>
            <LeftOutlined />
          </Button>
          <Button onClick={handleNext} disabled={currentIndex === tabContents.length - 1}>
            <RightOutlined />
          </Button>
        </div>
      </div>
      <Tabs activeKey={activeKey} onChange={setActiveKey} type="card" className='predicted-tab'>
        {tabContents.map(tab => (
          <TabPane tab="" key={tab.key}>
            {tab.content}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default ProcurementTabs;