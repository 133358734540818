import React, { FC, useState, useEffect } from "react";
import { Drawer, notification, Form, Input, Button, Select } from "antd";
import { AuthTokenType, DataProps, AddCeCoFormProps, BusinessLineprops, UserProps } from "../utils/types";
import { getAuthToken } from "../utils/functions";
import axios, { AxiosResponse } from "axios";
import { CeCoURL } from "../utils/network";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import { useGetBusinessLine, useGetcecoowner, useGetmanagers } from "../utils/hooks";
const { Option } = Select;

const Addcecos: FC<AddCeCoFormProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose,
  editingCeCo,
  onCloseWithoutEditing, // new prop
}) => {
  const { t } = useTranslation(); // Initialize the useTranslation hook
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [businesslines, setbusinesslines] = useState<BusinessLineprops[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [manager, setmanager] = useState<UserProps[]>([]);

  useGetcecoowner(setmanager, setFetching);


  useGetBusinessLine(setbusinesslines,setFetching)
  useEffect(() => {
    if (editingCeCo) {
      form.setFieldsValue({
        ...editingCeCo,
        businessline_id: editingCeCo.businessline?.id})
    } else {
      form.resetFields();
    }
  }, [editingCeCo, form]);

  const handleFormClose = () => {
    form.resetFields();
    onClose?.();
    if (!form.isFieldsTouched()) {
      onCloseWithoutEditing?.(); // Notify the parent about closing without editing
    }
  };

  const onSubmit = async (values: DataProps) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

    try {
      let response: AxiosResponse;

      if (editingCeCo) {
        // Editing location
        response = await axios.put(`${CeCoURL}/${editingCeCo.id}/`, values, headers);
      } else {
        // Adding new location
        response = await axios.post(CeCoURL + '/', values, headers);
      }

      setLoading(false);

      if (response) {
        onSuccessCallBack?.();
        onClose?.(); // Close the drawer
      }
    } catch (error) {
      notification.error({
        message: t("Operation Error"), // Translate the error message
      });
      setLoading(false);
    }
  };

  return (
    <Drawer
      title={editingCeCo ? t("Edit CECO") : t("Add CECO")} 
      visible={isVisible}
      onClose={handleFormClose}
      destroyOnClose
      width={360}
    >
      <Form
        layout="vertical"
        onFinish={onSubmit}
        form={form}
        onValuesChange={() => setHasChanges(true)}
      >

          <Form.Item name="businessline_id" label={t("Business Line")} rules={[{ required: true }]}>
                  <Select >
                    {businesslines.map(line => (
                  <Option key={line.id} value={line.id}>
                    {line.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
        <Form.Item
          label={t("Name")} 
          name="name"
          rules={[{ required: true, message: t('Please input the CECO name!') }]}
        >
          <Input placeholder={t("CECO")} /> 
        </Form.Item>
        <Form.Item
          label={t("CECO Owner")} 
          name="ceco_owner_id"
          rules={[{ required: true, message: t('Please input the Ceco Owner!') }]}
        >
         <Select defaultValue="" >
            <Option value="" >{t("Ceco Owner ")}</Option>
            {
              manager.map((item, index) => <Option value={item.id} key={index}>{item.fullname}</Option>)
            }
          </Select>
 
        </Form.Item>
 
        <Form.Item>
          <Button className="bbutton" htmlType="submit" type="primary" block loading={loading}>
            {editingCeCo ? t("Update") : t("Submit")} 
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default Addcecos;