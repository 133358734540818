import { useEffect, useState } from "react";
import { getAuthToken} from "../utils/functions";
import { AuthTokenType, DataProps, UserProps, location, project } from "../utils/types";
import { notification, Table, Button, Input, Modal, Select, Form, Drawer } from "antd";
import axios, { AxiosResponse } from "axios";
import { GroupAddViewUrl, GroupDeleteViewUrl, GroupLocationAddViewUrl, GroupProjectAddViewUrl, GroupViewUrl, UserGroupAddViewUrl } from "../utils/network"; // Replace with your actual API endpoint for Group
import ContentLayout from "../components/ContentLayout";
import { useGetLocation, useGetProjects, useGetUsers } from "../utils/hooks";
import AddGroupForm from "../components/AddGroupForm";
import { getgroups } from "process";

const { Option } = Select;


interface GroupProps {
  name: string;
  users: { fullname: string,id:string }[];
  locations: { id:string, name:string }[];
  projects: { id:string, name:string }[];
  key?: number;
  id: number;
}


function GroupView() {
  const [groups, setGroups] = useState<GroupProps[]>([]);
  const [fetching, setFetching] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<GroupProps | null>(null);
  const [form] = Form.useForm();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [users,setUsers]=useState<UserProps[]>()
  const [Locations,setLocations]=useState<location[]>()
  const [Projects,setprojects]=useState<project[]>()
    


  const getGroups = async () => {
    const headers = getAuthToken() as AuthTokenType;
    const response: AxiosResponse = await axios.get(GroupViewUrl, headers).catch((e) => {
      notification.error({
        message: "Operation Error",
        description: e.response?.data.error,
      });
    }) as AxiosResponse<GroupProps>;

    if (response) {
      const data = (response.data as GroupProps[]).map((item) => ({
        ...item,
        key: item.id,
      }));

      setGroups(data);
      
      setFetching(false);
    }
  };

  useGetLocation(setLocations,setFetching)
  useGetProjects(setprojects,setFetching)

  useGetUsers(setUsers,setFetching)


  useEffect(() => {
    getGroups();
  }, []);

  const handleEdit = (record: GroupProps) => {
    setSelectedGroup(record);
    setIsModalVisible(true);
    setSelectedUsers(record.users.map(user => user.id));
    setSelectedLocations(record.locations.map(Location => Location.id));
    setSelectedProjects(record.projects.map(Project => Project.id));

  };


  const handleDelete = (groupid: number) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this supplier?",
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;

          // Make a DELETE request to the API endpoint for deleting a supplier
          await axios.delete(`${GroupDeleteViewUrl}/${groupid}`, headers);

          // After successful deletion, fetch updated suppliers
          setFetching(true)
          getGroups() 
        } catch (error) {
          // Handle error if the deletion fails
          notification.error({
            message: "Delete Operation Error",
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: "Yes",
      cancelText: "No",
    });
  };


  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },

    {
      title: 'Group Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Users',
      dataIndex: 'users',
      key: 'users',
      render: (users: { fullname: string }[]) => users.map(user => user.fullname).join(', ')
    },
    {
      title: 'Locations',
      dataIndex: 'locations',
      key: 'locations',
      render: (locations: { name: string }[]) => locations.map(location => location.name).join(', ')
    },
    {
      title: 'Projects',
      dataIndex: 'projects',
      key: 'projects',
      render: (projects: { name: string }[]) => projects.map(project => project.name).join(', ')
    },
    {
      title: 'Edit',
      key: 'edit',
      render: (text: string, record: GroupProps) => (
       <span>
          <a onClick={() => handleEdit(record)}>Edit</a>
          <span> | </span>
          <a onClick={() => handleDelete(record.id)}>Delete</a>
        </span>
      ),
    },
  ];
  const [loading,setLoading]=useState(false)
    
  const handleOk =async () => {
    // Create an array of objects for the selected users
    const usersData = selectedUsers.length > 0 ? selectedUsers.map(userId => ({
      groupId: selectedGroup?.id,
      user: userId
    })) : [{ groupId: selectedGroup?.id, user: '' }];

    
    const LocationsData = selectedLocations.length > 0 ? selectedLocations.map(LocationId => ({
      groupId: selectedGroup?.id,
      location: LocationId
    })) : [{ groupId: selectedGroup?.id, location: '' }]; 

    const ProjectsData = selectedProjects.length > 0 ? selectedProjects.map(ProjectId => ({
      groupId: selectedGroup?.id,
      project: ProjectId
    })) : [{ groupId: selectedGroup?.id, project: '' }]; 

    const headers=getAuthToken() as AuthTokenType

    setLoading(true)
 
    const response:AxiosResponse =await axios.post(UserGroupAddViewUrl,usersData,headers).catch(
        (e)=>{ notification.error({
            message:"Operation Error",
            description:e.response?.data.error
        })
        form.resetFields()
    }
        ) as AxiosResponse
        setLoading(false)
    
        setLoading(true)
 
        const response1:AxiosResponse =await axios.post(GroupLocationAddViewUrl,LocationsData,headers).catch(
            (e)=>{ notification.error({
                message:"Operation Error",
                description:e.response?.data.error
            })
            form.resetFields()
        }
            ) as AxiosResponse
            setLoading(false)

            setLoading(true)
 
            const response2:AxiosResponse =await axios.post(GroupProjectAddViewUrl,ProjectsData,headers).catch(
                (e)=>{ notification.error({
                    message:"Operation Error",
                    description:e.response?.data.error
                })
                form.resetFields()
            }
                ) as AxiosResponse
                setLoading(false)
    
           
            getGroups() 
    setIsModalVisible(false);
    // Here you can add the code to update the group
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const onCreateGroup=()=>{
    setModalState(false)
    setFetching(true)
    getGroups() 
  }



  const[modalState,setModalState]=useState(false)

  return (
    <ContentLayout
      setModalState={setModalState}
      pageTitle="Groups"
      dataSource={(groups as unknown) as DataProps[]}
      columns={(columns as unknown) as DataProps[]}
      fetching={fetching}
      buttontitle="Add Group"
      includeFilters={false}
>
         <AddGroupForm
        onSuccessCallBack={onCreateGroup} 
        isVisible={modalState}
        onClose={()=>setModalState(false)}/>
 <Drawer
  title="Edit Group"
  placement="right"
  closable={false}
  onClose={handleCancel}
  visible={isModalVisible}
  width={360} // Set width according to your preference
>
  <Form form={form}>
    <Form.Item label="Users">
      <Select mode="multiple" value={selectedUsers} onChange={setSelectedUsers}>
        {users?.map((user, index) => (
          <Option key={`${user.id}-${index}`} value={user.id}>{user.fullname}</Option>
        ))}
      </Select>
    </Form.Item>
    <Form.Item label="Locations">
      <Select mode="multiple" value={selectedLocations} onChange={setSelectedLocations}>
        {Locations?.map((Location, index) => (
          <Option key={`${Location.id}-${index}`} value={Location.id}>{Location.name}</Option>
        ))}
      </Select>
    </Form.Item>
    <Form.Item label="Projects">
      <Select mode="multiple" value={selectedProjects} onChange={setSelectedProjects}>
        {Projects?.map((Project, index) => (
          <Option key={`${Project.id}-${index}`} value={Project.id}>{Project.name}</Option>
        ))}
      </Select>
    </Form.Item>
    <Form.Item>

      <Button htmlType='submit' type="primary" block loading={loading} onClick={handleOk}>Submit</Button>
    </Form.Item>
  </Form>
</Drawer>
        </ContentLayout>
  );
}

export default GroupView;