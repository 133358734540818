import React, { FC, useState, useEffect } from "react";
import { Modal, notification } from "antd";
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import { useGetProjectp } from "../utils/hooks";
import { AuthTokenType, Projectp,} from "../utils/types";
import { getAuthToken, getProjectp,  } from "../utils/functions";
import axios from "axios";
import ContentLayout from "../components/ContentLayout";
import { ProjectURL } from "../utils/network";
import AddProjectP from "./AddProjectp";
import ContentLayoutTabs from "../components/ContentLayoutTabs";

const ProjectPs: FC = () => {
  const { t } = useTranslation(); // Initialize the useTranslation hook

  const columns = [
    {
      title: t("ID"), // Translate the title
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("Code Name"), // Translate the title
      dataIndex: "code_name",
      key: "code_name",
    },
    {
      title: t("Client"), // Translate the title
      dataIndex: "client",
      key: "client",
    },
    {
      title: t("Project Manager"), // Translate the title
      dataIndex: "project_manager1",
      key: "project_manager1",
    },
    {
      title: t("MW"), // Translate the title
      dataIndex: "mw",
      key: "mw",
    },

    {
      title: t("Actions"), // Translate the title
      key: "actions",
      render: (text: string, record: Projectp) => (
        <span>
          <a onClick={() => handleEdit(record)}>{t("Edit")}</a>
          <span> | </span>
          <a onClick={() => handleDelete(record.id)}>{t("Delete")}</a>
        </span>
      ),
    },
  ];

  const [Projectps, setProjectps] = useState<Projectp[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingProjectps, setEditingProjectps] = useState<Projectp | null>(null);

  useGetProjectp(setProjectps, setFetching);

  useEffect(() => {
    // When editingLocation changes, open the modal
    if (editingProjectps) {
      setDrawerVisible(true);
    }
  }, [editingProjectps]);

  const handleEdit = (record: Projectp) => {
    setEditingProjectps(record);
  };

  const handleDelete = (ProjectPId: number) => {
    Modal.confirm({
      title: t("Confirm Deletion"), // Translate the title
      content: t("Are you sure you want to delete this record?"), // Translate the content
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;
          // Make a DELETE request to the API endpoint for deleting a location
          await axios.delete(`${ProjectURL}/${ProjectPId}/`, headers);

          // After successful deletion, fetch updated locations
          getProjectp(setProjectps, setFetching);
        } catch (error) {
          // Handle error if the deletion fails
          notification.error({
            message: t("Delete Operation Error"), // Translate the message
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: t("Yes"), // Translate the text
      cancelText: t("No"), // Translate the text
    });
  };

  const onCloseWithoutEditing = () => {
    setEditingProjectps(null); // Reset editingCategory when form is closed without editing
  };


  return (
    <ContentLayoutTabs
      pageTitle={t("Projects")} // Translate the title
      buttontitle={t("Add Project")} // Translate the title
      setModalState={setDrawerVisible}
      dataSource={Projectps as any}
      columns={columns as any}
      fetching={fetching}
      includeFilters={true}
      model_name="ProjectP"
    >
      <AddProjectP
        onSuccessCallBack={() => {
          setDrawerVisible(false);
          getProjectp(setProjectps, setFetching);
        }}
        isVisible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        editingProjectps={editingProjectps}
        onCloseWithoutEditing={onCloseWithoutEditing}
      />
    </ContentLayoutTabs>
  );
};

export default ProjectPs;