import React, { FC, useEffect, useState } from "react";
import { Modal, Tag, notification} from "antd";
import { AuthTokenType, DemurrageControl} from "../utils/types";
import { useGetdemmurage } from "../utils/hooks";
import { getAuthToken,getdemmurage } from "../utils/functions";
import axios from "axios";
import { demmurageURL} from "../utils/network";
import ContentLayout from "../components/ContentLayout";

import { useTranslation } from "react-i18next";
import AddDemurrageForm from "../components/AddDemmurageControl";
import PivotTableUI from "react-pivottable/PivotTableUI";
import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';

const PlotlyRenderers = createPlotlyRenderers(Plot);

const Demmurage: FC = () => {
  const { t } = useTranslation(); // Initialize the useTranslation hook

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingdemmurage, seteditingdemmurage] = useState<DemurrageControl | null>(null);
  const [demmurages, setdemmurages] = useState<DemurrageControl[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);

  const handleEdit = (demmurages: DemurrageControl) => {
    seteditingdemmurage(demmurages);
    setDrawerVisible(true);
  };

  useGetdemmurage(setdemmurages, setFetching);
  useEffect(() => {
    // When editingProduct changes, open the form
    if (editingdemmurage) {
      setDrawerVisible(true);
    }
  }, [editingdemmurage]);

  const onCloseWithoutEditing = () => {
    seteditingdemmurage(null); // Reset editingCategory when form is closed without editing
  };

  const handleDelete = (demmurageId: number) => {
    Modal.confirm({
      title: t("Confirm Deletion"),
      content: t("Are you sure you want to delete this shipment?"),
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;

          // Make a DELETE request to the API endpoint for deleting a shipment
          await axios.delete(`${demmurageURL}/${demmurageId}`, headers);

          // After successful deletion, fetch updated shipments
          getdemmurage(setdemmurages, setFetching);
        } catch (error) {
          // Handle error if the deletion fails
          notification.error({
            message: t("Delete Operation Error"),
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: t("Yes"),
      cancelText: t("No"),
    });
  };
  const columns = [
    {
      title: t("Reference"),
      dataIndex: "reference",
      key: "reference",
    },
    {
      title: t("Project"),
      dataIndex: "project",
      key: "project",
    },
    {
      title: t("Supplier"),
      dataIndex: "supplier",
      key: "supplier",
    },
    {
      title: t("Material"),
      dataIndex: "material",
      key: "material",
    },
    {
      title: t("BL Number"),
      dataIndex: "bl_number",
      key: "bl_number",
    },
    {
      title: t("BL Type"),
      dataIndex: "bl_type",
      key: "bl_type",
    },
    {
      title: t("Shipping Line"),
      dataIndex: "shipping_line",
      key: "shipping_line",
    },
    {
      title: t("Container Quantity"),
      dataIndex: "container_quantity",
      key: "container_quantity",
    },
    {
      title: t("Bill of Lading Quote"),
      dataIndex: "bill_of_lading_quote",
      key: "bill_of_lading_quote",
      render: (bill_of_lading_quote: boolean) => (
        <Tag color={bill_of_lading_quote ? "green" : "red"}>
          {bill_of_lading_quote ? t("Yes") : t("No")}
        </Tag>
      ),

    },
    {
      title: t("Demurrage Payment"),
      dataIndex: "demurrage_payment",
      key: "demurrage_payment",
      render: (demurrage_payment: boolean) => (
        <Tag color={demurrage_payment ? "green" : "red"}>
          {demurrage_payment ? t("Yes") : t("No")}
        </Tag>
        ),

    },
    {
      title: t("Container Return"),
      dataIndex: "container_return",
      key: "container_return",
      render: (container_return: boolean) => (
        <Tag color={container_return ? "green" : "red"}>
          {container_return ? t("Yes") : t("No")}
        </Tag>
        ),

    },
    {
      title: t("Provision Payment"),
      dataIndex: "provision_payment",
      key: "provision_payment",
      render: (provision_payment: boolean) => (
        <Tag color={provision_payment ? "green" : "red"}>
          {provision_payment ? t("Yes") : t("No")}
        </Tag>
        ),
    },
    {
      title: t("DIN Payment"),
      dataIndex: "din_payment",
      key: "din_payment",
      render: (din_payment: boolean) => (
        <Tag color={din_payment ? "green" : "red"}>
          {din_payment ? t("Yes") : t("No")}
        </Tag>
        ),
    },
    {
      title: t("Custom Release"),
      dataIndex: "custom_release",
      key: "custom_release",
      render: (custom_release: boolean) => (
        <Tag color={custom_release ? "green" : "red"}>
          {custom_release ? t("Yes") : t("No")}
        </Tag>
        ),
    },
    {
      title: t("Stock Site WH"),
      dataIndex: "stock_site_wh",
      key: "stock_site_wh",
      render: (stock_site_wh: boolean) => (
        <Tag color={stock_site_wh ? "green" : "red"}>
          {stock_site_wh ? t("Yes") : t("No")}
        </Tag>
        ),
    },
    {
      title: t("Shipping Date"),
      dataIndex: "shipping_date1",
      key: "shipping_date1",
    },
    {
      title: t("Delivery Date"),
      dataIndex: "delivery_date1",
      key: "delivery_date1",
    },
    {
      title: t("Free Days"),
      dataIndex: "free_days",
      key: "free_days",
    },
    {
      title: t("Free Days Expiration"),
      dataIndex: "free_days_expiration1",
      key: "free_days_expiration1",
    },
    {
      title: t("Demmurage Start"),
      dataIndex: "demurrage_start1",
      key: "demurrage_start1",
    },
    {
      title: t("Demurrage Payment Status"),
      dataIndex: "demurrage_payment_status",
      key: "demurrage_payment_status",
    },
    {
      title: t("Demurrage Payment Date"),
      dataIndex: "demurrage_payment_date1",
      key: "demurrage_payment_date1",
    },
    {
      title: t("Purchase Order Demurrage"),
      dataIndex: "purchase_order_demurrage",
      key: "purchase_order_demurrage",
    },
    {
      title: t("Invoice Number"),
      dataIndex: "invoice_number",
      key: "invoice_number",
    },
    {
      title: t("Custom Provision"),
      dataIndex: "custom_provision",
      key: "custom_provision",
    },
    {
      title: t("DIN"),
      dataIndex: "din",
      key: "din",
    },
    {
      title: t("Comments"),
      dataIndex: "comments",
      key: "comments",
    },
    {
      title: t("Actions"),
      key: "actions",
      render: (text: string, record: DemurrageControl) => (
        <span>
          <a onClick={() => handleEdit(record)}>{t("Edit")}</a>
          <span> | </span>
          <a onClick={() => handleDelete(record.id)}>{t("Delete")}</a>
        </span>
      ),
    },
  ];




  return (
    
    <ContentLayout
      pageTitle={t("Demurrage Control")}
      buttontitle={t("Add Demurrage")}
      setModalState={setDrawerVisible}
      dataSource={demmurages as any}
      columns={columns as any}
      fetching={fetching}
      includeFilters={true}
      model_name="demurragecontrol"
    >

      <AddDemurrageForm
        onSuccessCallBack={() => {
          setDrawerVisible(false);
          getdemmurage(setdemmurages, setFetching);
        }}
        isVisible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        editingdemmurage={editingdemmurage}
        onCloseWithoutEditing={onCloseWithoutEditing}
      />  

    </ContentLayout>
  );
};

export default Demmurage;