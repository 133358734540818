import React, { FC, useState, useEffect } from "react";
import { Modal, notification } from "antd";
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import { useGetDeliveryTerms } from "../utils/hooks";
import { AuthTokenType,DeliveryTerm} from "../utils/types";
import { getAuthToken, getDeliveryTerms } from "../utils/functions";
import axios from "axios";
import { ProcessCategorizationURL, DeliveryTermURL } from "../utils/network";
import ContentLayout from "../components/ContentLayout";
import AddDeliveryTerms from "./AddDeliveryTerm";
import ContentLayoutTabs from "../components/ContentLayoutTabs";

const DeliveryTerms: FC = () => {
  const { t } = useTranslation(); // Initialize the useTranslation hook

  const columns = [
    {
      title: t("ID"), // Translate the title
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("Term"), // Translate the title
      dataIndex: "term",
      key: "term",
    },
    {
      title: t("Actions"), // Translate the title
      key: "actions",
      render: (text: string, record: DeliveryTerm) => (
        <span>
          <a onClick={() => handleEdit(record)}>{t("Edit")}</a>
          <span> | </span>
          <a onClick={() => handleDelete(record.id)}>{t("Delete")}</a>
        </span>
      ),
    },
  ];

  const [DeliveryTerms, setDeliveryTerms] = useState<DeliveryTerm[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingDeliveryTerms, setEditingDeliveryTerms] = useState<DeliveryTerm | null>(null);

  useGetDeliveryTerms(setDeliveryTerms, setFetching);

  useEffect(() => {
    // When editingLocation changes, open the modal
    if (editingDeliveryTerms) {
      setDrawerVisible(true);
    }
  }, [editingDeliveryTerms]);

  const handleEdit = (record: DeliveryTerm) => {
console.log(record)
    setEditingDeliveryTerms(record);
  };

  const handleDelete = (DeliveryTermId: number) => {
    Modal.confirm({
      title: t("Confirm Deletion"), // Translate the title
      content: t("Are you sure you want to delete this record?"), // Translate the content
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;
          // Make a DELETE request to the API endpoint for deleting a location
          await axios.delete(`${DeliveryTermURL}/${DeliveryTermId}/`, headers);

          // After successful deletion, fetch updated locations
          getDeliveryTerms(setDeliveryTerms, setFetching);
        } catch (error) {
          // Handle error if the deletion fails
          notification.error({
            message: t("Delete Operation Error"), // Translate the message
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: t("Yes"), // Translate the text
      cancelText: t("No"), // Translate the text
    });
  };

  const onCloseWithoutEditing = () => {
    setEditingDeliveryTerms(null); // Reset editingCategory when form is closed without editing
  };


  return (
    <ContentLayoutTabs
      pageTitle={t("Delivery Terms")} // Translate the title
      buttontitle={t("Add DeliveryTerm")} // Translate the title
      setModalState={setDrawerVisible}
      dataSource={DeliveryTerms as any}
      columns={columns as any}
      fetching={fetching}
      includeFilters={true}
      model_name="DeliveryTerm"
    >
      <AddDeliveryTerms
        onSuccessCallBack={() => {
          setDrawerVisible(false);
          getDeliveryTerms(setDeliveryTerms, setFetching);
        }}
        isVisible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        editingDeliveryTerms={editingDeliveryTerms}
        onCloseWithoutEditing={onCloseWithoutEditing}
      />
    </ContentLayoutTabs>
  );
};

export default DeliveryTerms;