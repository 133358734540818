import React, { FC, useState, useEffect } from "react";
import { Drawer, notification, Form, Input, Button, DatePicker } from "antd";
import { useTranslation } from "react-i18next"; // Importing the translation hook
import { AuthTokenType, EditTransferOrderProps, EditPurchaseOrderProps, purchaseProps, stocktransferProps } from "../utils/types";
import axios, { AxiosResponse } from "axios";
import { purchaseorderUrl, stocktransferorderUrl } from "../utils/network";
import { getAuthToken } from "../utils/functions";

const EditTransferOrderDrawer: FC<EditTransferOrderProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose,
  editingTransfers,
  onCloseWithoutEditing, // new prop
}) => {
  const { t } = useTranslation(); // Using the translation hook
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (editingTransfers) {
      form.setFieldsValue(editingTransfers);
    } else {
      form.resetFields();
    }
  }, [editingTransfers, form]);

  const handleSubmit = async (values: Partial<stocktransferProps>) => {
    setLoading(true);
    try {
      let response: AxiosResponse;
      setLoading(true);

      const headers = getAuthToken() as AuthTokenType;

      // Constructing the URL
      response = await axios.patch(stocktransferorderUrl+'/'+editingTransfers?.id, values,headers);

      setLoading(false);

      if (response) {
        onSuccessCallBack?.();
        onClose();
        notification.success({
          message: t("Edit Successful"), // Using translation for message
          description: t("Changes have been saved successfully."), // Using translation for description
        });
      }
    } catch (error) {
      notification.error({
        message: t("Operation Error"), // Using translation for message
      });
      setLoading(false);
    }
  };

  const handleDrawerClose = () => {
    onClose();
    if (!form.isFieldsTouched()) {
      onCloseWithoutEditing?.();
    }
  };

  return (
    <Drawer
      title={t("Edit Transfer Order")} // Using translation for title
      visible={isVisible}
      onClose={handleDrawerClose}
      destroyOnClose
      width={360}
    >
      <Form layout="vertical" onFinish={handleSubmit} form={form} onValuesChange={() => setHasChanges(true)}>
        <Form.Item label={t("ID")}    name="id">
          <Input disabled />
        </Form.Item>
        <Form.Item label={t("Internal Reference")}  name="internal_reference">
          <Input   />
        </Form.Item>
        <Form.Item
        label={t("Transport Company")}
          name="transport_company"
          rules={[{ required: false, message: t('Please input the Transport Company!') }]}
        >
          <Input  /> {/* Using translation for placeholder */}
        </Form.Item>
        <Form.Item
          label={t("Truck Plate")} 
          name="truck_plate"
          rules={[{ required: false, message: t('Please input the Truck Plate!') }]}
        >
          <Input /> {/* Using translation for placeholder */}
        </Form.Item>
        <Form.Item
          label={t("Guide Number")}
          name="guide_number"
          rules={[{ required: false, message: t('Please input the Guide Number!') }]}
        >
          <Input  /> {/* Using translation for placeholder */}
        </Form.Item>

        <Form.Item>
          <Button className="bbutton" htmlType="submit" type="primary" block loading={loading}>
            {t("Save")} {/* Using translation for button label */}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default EditTransferOrderDrawer;