import React, { FC, useState, useEffect } from "react";
import { Button, Table, Modal, notification } from "antd";
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import { useGetLocation } from "../utils/hooks";
import { AuthTokenType, location } from "../utils/types";
import { getAuthToken, getLocation } from "../utils/functions";
import AddLocationForm from "../components/AddLocationForm";
import axios from "axios";
import { locationsUrl } from "../utils/network";
import ContentLayout from "../components/ContentLayout";
import ContentLayoutTabs from "../components/ContentLayoutTabs";

const Locations: FC = () => {
  const { t } = useTranslation(); // Initialize the useTranslation hook

  const columns = [
    {
      title: t("ID"), // Translate the title
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("Name"), // Translate the title
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Type"), // Translate the title
      dataIndex: "type",
      key: "type",
    },
    {
      title: t("Address"), // Translate the title
      dataIndex: "address",
      key: "address",
    },
    {
      title: t("City"), // Translate the title
      dataIndex: "city",
      key: "city",
    },
    {
      title: t("Site Manager"), // Translate the title
      dataIndex: "site_manager",
      key: "site_manager",
    },
    {
      title: t("Country"), // Translate the title
      dataIndex: "country",
      key: "country",
    },
    {
      title: t("Actions"), // Translate the title
      key: "actions",
      render: (text: string, record: location) => (
        <span>
          <a onClick={() => handleEdit(record)}>{t("Edit")}</a>
          <span> | </span>
          <a onClick={() => handleDelete(record.id)}>{t("Delete")}</a>
        </span>
      ),
    },
  ];

  const [location, setLocation] = useState<location[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingLocation, setEditingLocation] = useState<location | null>(null);

  useGetLocation(setLocation, setFetching);

  useEffect(() => {
    // When editingLocation changes, open the modal
    if (editingLocation) {
      setDrawerVisible(true);
    }
  }, [editingLocation]);

  const handleEdit = (record: location) => {
    setEditingLocation(record);
  };

  const handleDelete = (locationId: number) => {
    Modal.confirm({
      title: t("Confirm Deletion"), // Translate the title
      content: t("Are you sure you want to delete this location?"), // Translate the content
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;
          // Make a DELETE request to the API endpoint for deleting a location
          await axios.delete(`${locationsUrl}/${locationId}/`, headers);

          // After successful deletion, fetch updated locations
          getLocation(setLocation, setFetching);
        } catch (error) {
          // Handle error if the deletion fails
          notification.error({
            message: t("Delete Operation Error"), // Translate the message
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: t("Yes"), // Translate the text
      cancelText: t("No"), // Translate the text
    });
  };

  const onCloseWithoutEditing = () => {
    setEditingLocation(null); // Reset editingCategory when form is closed without editing
  };

  const onCreateLocation = () => {
    setDrawerVisible(true);
    setEditingLocation(null); // Reset editingLocation for new location
  };

  return (
    <ContentLayoutTabs
      pageTitle={t("Locations")} // Translate the title
      buttontitle={t("Add Location")} // Translate the title
      setModalState={setDrawerVisible}
      dataSource={location as any}
      columns={columns as any}
      fetching={fetching}
      includeFilters={true}
      model_name="location"
    >
      <AddLocationForm
        onSuccessCallBack={() => {
          setDrawerVisible(false);
          getLocation(setLocation, setFetching);
        }}
        isVisible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        editingLocation={editingLocation}
        onCloseWithoutEditing={onCloseWithoutEditing}
      />
    </ContentLayoutTabs>
  );
};

export default Locations;