import { ReactElement, useEffect, useState } from "react";
import { getAuthToken } from '../../utils/functions';
import { AuthTokenType } from '../../utils/types';
import axios, { AxiosResponse } from 'axios';
import { SummaryTransactionUrl } from '../../utils/network';
import { notification } from 'antd';
import { Link } from 'react-router-dom';
import { TbTruckDelivery } from "react-icons/tb";
import { TbArrowsTransferUp } from "react-icons/tb";
import { SiPlausibleanalytics } from "react-icons/si";
import { MdOutlineWarehouse } from "react-icons/md";
import { BsFillFastForwardFill } from "react-icons/bs";interface SummaryDataProps {
    [key: string]: {
        title: string,
        count: number
        icon: ReactElement
        linkicon: ReactElement
        link: string
    }
}

const transactionSummary: SummaryDataProps = {
    "total_shipments": {
        title: "Shipments",
        count: 0,
        icon: <TbTruckDelivery style={{ fontSize: 40, color: "crimson" }} />,
        link: "/shipments",
        linkicon:<BsFillFastForwardFill style={{ fontSize: 20, color: "#3182CE" }} />
    },
    "total_purchaseorders": {
        title: "Purchase Orders",
        count: 0,
        icon: <SiPlausibleanalytics style={{ fontSize: 40, color: "darkcyan" }} />,
        link: "/purchasereview",
        linkicon:<BsFillFastForwardFill style={{ fontSize: 20, color: "#3182CE" }} />
    },
    "total_stocktransferorder": {
        title: "Transfer Orders",
        count: 0,
        icon: <TbArrowsTransferUp style={{ fontSize: 40, color: "#6a5acd" }} />,
        link: "/transfersreview",
        linkicon:<BsFillFastForwardFill style={{ fontSize: 20, color: "#3182CE" }} />
    },
    "total_inventory": {
        title: "Inventory Units",
        count: 0,
        icon: <MdOutlineWarehouse style={{ fontSize: 40, color: "deeppink" }} />,
        link: "/onhand",
        linkicon:<BsFillFastForwardFill style={{ fontSize: 20, color: "#3182CE" }} />
    },
}

const SummaryData = () => {
    const [loading, setLoading] = useState(true)
    const [transactionSummaryData, setTransactionSummaryData] = useState(transactionSummary)

    const getTSummaryData = async () => {
        const headers = getAuthToken() as AuthTokenType;
        try {
            const response: AxiosResponse = await axios.get(SummaryTransactionUrl, headers);
            const result = response.data as { [key: string]: number };
            const updatedData = { ...transactionSummaryData };
            Object.keys(result).forEach((key) => {
                updatedData[key].count = result[key];
            });
            setTransactionSummaryData(updatedData);
        } catch (error) {
            notification.error({
                message: "Operation Error",
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getTSummaryData();
    }, [])

    return (
        <div>
            <br />
            <div className="summarycontainer">
                {
                    Object.values(transactionSummaryData).map((item, index) => (
                        <div key={index} className="card summarycontent">
                            <div className='left'>
                                <div className='title' >{item.title}</div>
                                <div className='count'>{item.count}</div>
                                <Link to={item.link}><div className='link'>{item.linkicon}</div></Link>
                            </div>
                            <div className="icon">
                                {item.icon}
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default SummaryData;