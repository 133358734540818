import React, { FC, useState, useEffect } from "react";
import { Drawer, notification, Form, Input, Button } from "antd";
import { useTranslation } from "react-i18next"; // Import the translation hook
import { AddProjectFormProps, AuthTokenType, DataProps } from "../utils/types";
import { getAuthToken } from "../utils/functions";
import axios, { AxiosResponse } from "axios";
import { projectsUrl } from "../utils/network";

const AddProjectsForm: FC<AddProjectFormProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose,
  editingProject,
  onCloseWithoutEditing, // new prop
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const { t } = useTranslation(); // Initialize the translation hook

  const handleFormClose = () => {
    form.resetFields();
    onClose?.();
    if (!form.isFieldsTouched()) {
      onCloseWithoutEditing?.(); // Notify the parent about closing without editing
    }
  };

  useEffect(() => {
    if (editingProject) {
      form.setFieldsValue(editingProject);
    } else {
      form.resetFields();
    }
  }, [editingProject, form]);

  const onSubmit = async (values: DataProps) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

    try {
      let response: AxiosResponse;

      if (editingProject) {
        // Editing customer
        response = await axios.put(`${projectsUrl}/${editingProject.id}/`, values, headers);
      } else {
        // Adding new customer
        response = await axios.post(projectsUrl + '/', values, headers);
      }

      setLoading(false);

      if (response) {
        onSuccessCallBack?.();
        onClose?.(); // Close the drawer
      }
    } catch (error) {
      notification.error({
        message: t("Operation Error"), // Translate the error message
      });
      setLoading(false);
    }
  };

  return (
    <Drawer
      title={editingProject ? t("Edit Customer") : t("Add Customer")} 
      visible={isVisible}
      onClose={handleFormClose}
      destroyOnClose
      width={360}
    >
      <Form
        layout="vertical"
        onFinish={onSubmit}
        form={form}
        onValuesChange={() => setHasChanges(true)}
      >
        <Form.Item
          label={t("Name")}
          name="name"
          rules={[{ required: true, message: t('Please input the project name!') }]} 
        >
          <Input placeholder={t("Project Name")} /> 
        </Form.Item>
        <Form.Item
          label={t("Portfolio")}
          name="portfolio"
          rules={[{ required: true, message: t('Please input the Portfolio!') }]}
        >
          <Input placeholder={t("Portfolio")} />
        </Form.Item>
        <Form.Item
          label={t("Person Responsible")}
          name="person_responsible"
          rules={[{ required: true, message: t('Please input the Person Responsible!') }]}
        >
          <Input placeholder={t("Person Responsible")} />
        </Form.Item>
        <Form.Item
          label={t("City")}
          name="city"
          rules={[{ required: true, message: t('Please input the customer city!') }]}
        >
          <Input placeholder={t("City")} />
        </Form.Item>
        <Form.Item>
          <Button className="bbutton" htmlType="submit" type="primary" block loading={loading}>
            {editingProject ? t("Update") : t("Submit")} 
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddProjectsForm;