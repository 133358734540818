import React, { FC, useState } from 'react';
import { Button, Table, Modal, notification } from 'antd';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import { useGetOnhandIventory } from '../utils/hooks';
import { OnhandInventoryProps } from '../utils/types';
import { getOnhandInventory } from '../utils/functions';
import AddOnHandInventoryForm from '../components/AddOnHandInventoryForm';
import ContentLayout from '../components/ContentLayout';

const OnHandInventory: FC = () => {
  const { t } = useTranslation(); // Initialize the useTranslation hook

  const columns = [
    {
      title: t('ID'), // Translate the title
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('SKU Code'), // Translate the title
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: t('SKU Name'), // Translate the title
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('Sub Product Name'), // Translate the title
      dataIndex: 'subname',
      key: 'subname',
    },

    {
      title: t('Category'), // Translate the title
      dataIndex: 'cat',
      key: 'cat',
    },
    {
      title: t('Sub Category'), // Translate the title
      dataIndex: 'subcat',
      key: 'subcat',
    },
    {
      title: t('Brand'), // Translate the title
      dataIndex: 'brand',
      key: 'brand',
    },
    {
      title: t('Project'), // Translate the title
      dataIndex: 'project1',
      key: 'project1',
    },
    {
      title: t('Storage Location'), // Translate the title
      dataIndex: 'storage',
      key: 'storage',
    },
    {
      title: t('Bill of Lading'), // Translate the title
      dataIndex: 'bill_of_lading',
      key: 'bill_of_lading',
    },
    {
      title: t('Container'), // Translate the title
      dataIndex: 'container_id',
      key: 'container_id',
    },
    {
      title: t('Internal Reference'), // Translate the title
      dataIndex: 'internal_reference',
      key: 'internal_reference',
    },
    {
      title: t('PO Number'), // Translate the title
      dataIndex: 'po_number',
      key: 'po_number',
    },


    {
      title: t('Status'), // Translate the title
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: t('Total Units'), // Translate the title
      dataIndex: 'totalunits',
      key: 'totalunits',
    },
    {
      title: t('Stock Date'), // Translate the title
      dataIndex: 'created_at',
      key: 'created_at',
    },

  ];

  const [onhandinventory, setOnHandInventory] = useState<OnhandInventoryProps[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [drawerVisible, setDrawerVisible] = useState(false);

  useGetOnhandIventory(setOnHandInventory, setFetching);

  const onCreateOnHandInventory = () => {
    setDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setDrawerVisible(false);
  };

  const onSuccessAddOnHandInventory = () => {
    setDrawerVisible(false);
    getOnhandInventory(setOnHandInventory, setFetching); // Refresh on-hand inventory list
  };

  return (
    <ContentLayout
      pageTitle={t('On Hand Inventory')} // Translate the page title
      buttontitle={t('Add Inventory')} // Translate the button title
      setModalState={setDrawerVisible}
      dataSource={onhandinventory as any}
      columns={columns as any}
      fetching={fetching}
      includeFilters={true}
      model_name="onhand"
    >
      <AddOnHandInventoryForm
        isVisible={drawerVisible}
        onClose={onCloseDrawer}
        onSuccessCallBack={onSuccessAddOnHandInventory}
      />
    </ContentLayout>
  );
};

export default OnHandInventory;