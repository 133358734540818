import { FC, useState, useEffect } from "react";
import { Button, notification, Input, Form, Select, Table, Spin } from "antd";
import { AxiosResponse } from "axios";
import axios from "axios";
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import { AuthTokenType, InventoryProps, location, product, project, supplier } from "../utils/types";
import { useGetLocation, useGetProduct, useGetProjects, useGetSupplier } from "../utils/hooks";
import { getAuthToken } from "../utils/functions";
import { InventoryMD04Url} from "../utils/network";
import PivotTableUI from "react-pivottable/PivotTableUI";
import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';

const PlotlyRenderers = createPlotlyRenderers(Plot);

const { Option } = Select;

const InventoryTable: FC = () => {
  const { t } = useTranslation(); // Initialize the useTranslation hook

  const [fetching, setFetching] = useState(false);
  const [inventory, setInventory] = useState<InventoryProps[]>([]);
  const [locations, setLocations] = useState<location[]>([]);
  const [projects, setProjects] = useState<project[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<string>("");
  const [selectedProject, setSelectedProject] = useState<string>("");
  const [products, setProducts] = useState<product[]>([]);
  const [selectedSupplier, setselectedSupplier] = useState<string>("");
  const [suppliers, setSuppliers] = useState<supplier[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [pivotState, setPivotState] = useState({});

  useGetLocation(setLocations, setFetching);
  useGetProjects(setProjects, setFetching);
  useGetProduct(setProducts, setFetching);
  useGetSupplier(setSuppliers,setFetching)
  useEffect(() => {
    handleGetDetails();
  }, []); // Empty dependency array ensures this effect runs only once when component mounts

  const handleGetDetails = async () => {
    try {
      setFetching(true);
      const headers = getAuthToken() as AuthTokenType;
      let requestData: any = {};

      // Check if any filter is selected, if not, make an API call without filters
      if (selectedLocation || selectedProject || selectedProduct || selectedSupplier || status) {
        requestData = {
          location_id: selectedLocation,
          project_id: selectedProject,
          product: selectedProduct,
          status: status,
          brand: selectedSupplier,
        };
      }

      const response: AxiosResponse<InventoryProps[]> = await axios.post(
        InventoryMD04Url,
        requestData,
        headers
      );
      setInventory(response.data);
    } catch (error) {
      notification.error({
        message: t("Operation Error"), // Translate the message
      });
    } finally {
      setFetching(false);
    }
  };
  const getUniqueValues = (data: any[], key: string) => {
    const uniqueValues: string[] = [];
    data.forEach((item: any) => {
      if (!uniqueValues.includes(item[key])) {
        uniqueValues.push(item[key]);
      }
    });
    return uniqueValues;
  };

  const columns = [
    {
      title: t("Material Code Id"), // Translate the title
      dataIndex: "material_code",
      key: "material_code",
      filters: getUniqueValues(inventory, 'material_code').map(value => ({ text: value, value: value })),
      onFilter: (value: any, record: any) => record.material_code.includes(value),
    },
    {
      title: t("Name"), // Translate the title
      dataIndex: "name",
      key: "name",
      filters: getUniqueValues(inventory, 'name').map(value => ({ text: value, value: value })),
      onFilter: (value: any, record: any) => record.name.includes(value),
      
    },
    {
      title: t("Sub Product"), // Translate the title
      dataIndex: "subname",
      key: "subname",
      filters: getUniqueValues(inventory, 'subname').map(value => ({ text: value, value: value })),
      onFilter: (value: any, record: any) => record.name.includes(value),
      
    },
    {
      title: t("Inventory Quantity"), // Translate the title
      dataIndex: "total_units",
      key: "total_units",
    },
    {
      title: t("Category"), // Translate the title
      dataIndex: "category_name",
      key: "category_name",
      filters: getUniqueValues(inventory, 'category_name').map(value => ({ text: value, value: value })),
      onFilter: (value: any, record: any) => record.category_name.includes(value),
    },
    {
      title: t("Sub Category"), // Translate the title
      dataIndex: "sub_category_name",
      key: "sub_category_name",
      filters: getUniqueValues(inventory, 'sub_category_name').map(value => ({ text: value, value: value })),
      onFilter: (value: any, record: any) => record.sub_category_name.includes(value),
    },

    {
      title: t("Brand"), // Translate the title
      dataIndex: "supplier_name",
      key: "supplier_name",
      filters: getUniqueValues(inventory, 'supplier_name').map(value => ({ text: value, value: value })),
      onFilter: (value: any, record: any) => record.supplier_name.includes(value),
    },
    {
      title: t("Location"), // Translate the title
      dataIndex: "location_name",
      key: "location_name",
      filters: getUniqueValues(inventory, 'location_name').map(value => ({ text: value, value: value })),
      onFilter: (value: any, record: any) => record.location_name.includes(value),
    },
    {
      title: t("Project"), // Translate the title
      dataIndex: "project_name",
      key: "project_name",
      filters: getUniqueValues(inventory, 'project_name').map(value => ({ text: value, value: value })),
      onFilter: (value: any, record: any) => record.project_name.includes(value),
    },
    {
      title: t("Status"), // Translate the title
      dataIndex: "status",
      key: "status",
      filters: getUniqueValues(inventory, 'status').map(value => ({ text: value, value: value })),
      onFilter: (value: any, record: any) => record.status.includes(value),
    },

  ];

  const downloadCSV = () => {
    // Translate the column titles
    const translatedColumns = columns.map(column => t(column.title));
  
    // Convert data to CSV format
    const csvRows = filteredData.map(row =>
      columns.map(column => {
        const dataIndex = column.dataIndex as keyof InventoryProps;
        return `"${row[dataIndex]}"`;
      }).join(",")
    );
  
    const csvContent = [
      translatedColumns.join(","),
      ...csvRows
    ].join("\n");
  
    // Create a Blob object containing the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  
    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(blob);
  
    // Create a link element
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "data.csv");
  
    // Append the link to the document body and trigger the click event
    document.body.appendChild(link);
    link.click();
  
    // Clean up by revoking the temporary URL
    window.URL.revokeObjectURL(url);
  };

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };
  
  const filteredData = inventory.filter((record) =>
  Object.values(record).some((value) =>
    value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
  )
);

  return (
    <div className="card">
      <div className="cardHeader">
        <h2 className="headContent">{t("Inventory and Control")}</h2>
        <div className="rightContent">
          <Input
            placeholder="Search..."
            onChange={handleSearch}
            style={{ marginRight: '10px', height:'40px' }}
          />
          <Button
            type="primary"
            onClick={downloadCSV}
            loading={fetching}
            className="bbutton"
          >
            {t("Download")}
          </Button>
        </div>
      </div>
      <br/>
      <div className="filterstyle1">
        <Form layout="inline">
          <Form.Item label="" style={{ marginRight: "2px" }}>
            <Select
              mode="multiple" // Enable multi-select mode
              maxTagCount={1} // Maximum number of displayed tags
              placeholder={t("Select Project")}   
              style={{ width: "17vw", height:'40px' }}
              onChange={(value: string) => setSelectedProject(value)}
            >
                        {projects.map((project, index) => (
                <Option key={index} value={project.id}>
                  {project.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="" style={{ marginRight: "2px" }}>
        <Select
          placeholder={t("Select Location")} // Set placeholder text
          style={{ width: "17vw", height: '40px' }}
          onChange={(value: string) => setSelectedLocation(value)}
          mode="multiple" // Enable multi-select mode
          maxTagCount={1} // Maximum number of displayed tags
        >
          {locations.map((location, index) => (
            <Option key={index} value={location.id}>
              {location.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

               <Form.Item label="" style={{ marginRight: "2px" }}>
            <Select
              mode="multiple" // Enable multi-select mode
              maxTagCount={1} // Maximum number of displayed tags
              placeholder={t("Select Product")} 
              style={{ width: "17vw", height:'40px' }}
              onChange={(value: string) => setSelectedProduct(value)}
            >
              {products.map((product, index) => (
                <Option key={index} value={product.id}>
                  {product.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="" style={{ marginRight: "2px" }}>
            <Select
              mode="multiple" // Enable multi-select mode
              maxTagCount={1} // Maximum number of displayed tags
              placeholder={t("Select Brand")}               
              style={{ width: "17vw", height:'40px' }}
              onChange={(value: string) => setselectedSupplier(value)}
            >
              
              {suppliers.map((product, index) => (
                <Option key={index} value={product.id}>
                  {product.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          </Form>
          <br/>
          <Form layout="inline">
          
          <Form.Item label="" style={{ marginRight: "2px" }}>
            <Select
              mode="multiple" // Enable multi-select mode
              maxTagCount={1} // Maximum number of displayed tags
              placeholder={t("Select Status")} 
              style={{ width: "17vw", height:'40px' }}
              onChange={(value: string) => setStatus(value)}
            >
              <Option value="">{t("Select Status")}</Option>
              <Option value="Available">{t("Available")}</Option>
              <Option value="Intransit">{t("Intransit")}</Option>
              <Option value="InManufacturing">{t("InManufacturing")}</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button className="bbutton"
              type="primary"
              onClick={handleGetDetails}
              loading={fetching}
              
            >
              {t("Get Details")}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <br/>
      <Spin spinning={fetching}>
        <Table
          className="custom-table"
          dataSource={filteredData}
          columns={columns as any}
          pagination={{

            total: filteredData.length, // Total number of items
            showSizeChanger: true, // Option to change page size
            pageSizeOptions: ['10', '50', '100'], // Selectable page sizes
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`, // Display range and total items
            // Optionally, you can add other pagination props like current, onChange, etc.
        }}
          bordered
        />
      </Spin>
      <div className="pivot-table-container">
          <PivotTableUI
            data={filteredData as any}
            onChange={(s) => setPivotState(s)}
            renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
            {...pivotState}
          />
        </div>

    </div>
    
  );
            }
export default InventoryTable;