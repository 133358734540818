import React, { useState } from 'react';
import { Tabs, Button } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import Categories from './Categories';
import Unitofmeasure from './Unitofmeasure';
import Suppliers from './Suppliers';
import Locations from './Locations';
import ProjectPs from '../Procurement/ProjectP';
import Projects from './Projects';
import Products from './Products';
import Currencies from './Currencies';

const { TabPane } = Tabs;

const Logisticstabs: React.FC = () => {
  const [activeKey, setActiveKey] = useState<string>('tab1');

  const tabContents = [
    { key: 'tab1', content: <Categories /> },
    { key: 'tab2', content: <Unitofmeasure/> },
    { key: 'tab3', content: <Suppliers/> },
    { key: 'tab4', content: <Locations/> },
    { key: 'tab5', content: <Projects/> },
    { key: 'tab6', content: <Currencies/> },
    { key: 'tab7', content: <Products/> },
  ];

  const currentIndex = tabContents.findIndex(tab => tab.key === activeKey);

  const handleNext = () => {
    if (currentIndex < tabContents.length - 1) {
      setActiveKey(tabContents[currentIndex + 1].key);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setActiveKey(tabContents[currentIndex - 1].key);
    }
  };

  return (
    <div className="card">
      <div className="cardHeader">
        <h2 className="headContent">Master Data</h2>
        <div className="navigation-buttons">
          <Button onClick={handlePrevious} disabled={currentIndex === 0}>
            <LeftOutlined />
          </Button>
          <Button onClick={handleNext} disabled={currentIndex === tabContents.length - 1}>
            <RightOutlined />
          </Button>
        </div>
      </div>
      <Tabs activeKey={activeKey} onChange={setActiveKey} type="card" className='predicted-tab'>
        {tabContents.map(tab => (
          <TabPane tab="" key={tab.key}>
            {tab.content}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default Logisticstabs;