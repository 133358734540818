import React, { FC, useState, useEffect } from "react";
import { Drawer, notification, Form, Input, Button, DatePicker, Switch, Select } from "antd";
import { useTranslation } from "react-i18next";
import axios, { AxiosResponse } from "axios";
import dayjs from "dayjs";
import { AddDemmurageFormProps, AuthTokenType } from "../utils/types";
import { demmurageURL } from "../utils/network";
import { getAuthToken } from "../utils/functions";

const { Option } = Select;


const AddDemurrageForm: FC<AddDemmurageFormProps> = ({
  isVisible,
  onSuccessCallBack,
  onClose,
  onCloseWithoutEditing,
  editingdemmurage,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (editingdemmurage) {
      form.setFieldsValue({
        ...editingdemmurage,
        shipping_date: editingdemmurage.shipping_date ? dayjs(editingdemmurage.shipping_date) : null,
        delivery_date: editingdemmurage.delivery_date ? dayjs(editingdemmurage.delivery_date) : null,
        free_days_expiration: editingdemmurage.free_days_expiration ? dayjs(editingdemmurage.free_days_expiration) : null,
        demurrage_start: editingdemmurage.demurrage_start ? dayjs(editingdemmurage.demurrage_start) : null,
        demurrage_payment_date: editingdemmurage.demurrage_payment_date ? dayjs(editingdemmurage.demurrage_payment_date) : null,
      });
    } else {
      form.resetFields();
    }
  }, [editingdemmurage, form]);

  const onSubmit = async (values: any) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

    try {
      let response: AxiosResponse;

      if (editingdemmurage) {
        response = await axios.put(`${demmurageURL}/${editingdemmurage.id}`, values,headers);
      } else {
        response = await axios.post(demmurageURL, values,headers);
      }

      setLoading(false);

      if (response) {
        form.resetFields();
        onSuccessCallBack?.();
        onClose?.();
      }
    } catch (error) {
      console.error(error);
      notification.error({
        message: t("Operation Error"),
      });
      setLoading(false);
    }
  };

  const handleFormClose = () => {
    form.resetFields();
    onClose?.();
    if (!form.isFieldsTouched()) {
      onCloseWithoutEditing?.();
    }
  };

  return (
    <Drawer
      title={editingdemmurage ? t("Edit Demurrage") : t("Add Demurrage")}
      visible={isVisible}
      onClose={handleFormClose}
      destroyOnClose
      width={360}
    >
      <Form layout="vertical" onFinish={onSubmit} form={form}>
        <Form.Item label={t("Reference")} name="reference" rules={[{ required: true, message: t("Please input the reference!") }]}>
          <Input />
        </Form.Item>
  
        <Form.Item label={t("Project")} name="project" rules={[{ required: true, message: t("Please input the project!") }]}>
          <Input />
        </Form.Item>
  
        <Form.Item label={t("Supplier")} name="supplier" rules={[{ required: true, message: t("Please input the supplier!") }]}>
          <Input />
        </Form.Item>
  
        <Form.Item label={t("Material")} name="material" rules={[{ required: true, message: t("Please input the material!") }]}>
          <Input />
        </Form.Item>
  
        <Form.Item label={t("BL Number")} name="bl_number" rules={[{ required: true, message: t("Please input the BL number!") }]}>
          <Input />
        </Form.Item>
  
        <Form.Item label={t("BL Type")} name="bl_type" rules={[{ required: true, message: t("Please input the BL type!") }]}>
        <Select placeholder="BL Type">
            <Option value="digital">Digital</Option>
            <Option value="paper">Paper</Option>
          </Select>

        </Form.Item>
  
        <Form.Item label={t("Shipping Line")} name="shipping_line" rules={[{ required: true, message: t("Please input the shipping line!") }]}>
        <Select placeholder="Shipping Line">
            <Option value="costco">COSTO</Option>
            <Option value="cma">CMA</Option>
            <Option value="maesk">MAESK</Option>
            <Option value="evergreen">EVERGREEN</Option>
            <Option value="one">ONE</Option>
            <Option value="hapaglloyd">HAPAGLLOYD</Option>
            <Option value="dhl">DHL</Option>
            <Option value="msc">MSC</Option>

          </Select>




        </Form.Item>
  
        <Form.Item label={t("Container Quantity")} name="container_quantity" rules={[{ required: true, message: t("Please input the container quantity!") }]}>
          <Input type="number" />
        </Form.Item>
  
        <Form.Item label={t("Bill of Lading Quote")} name="bill_of_lading_quote" rules={[{ required: false, message: t("Please input the bill of lading quote!") }]}>
          <Switch />
        </Form.Item>
  
        <Form.Item label={t("Demurrage Payment")} name="demurrage_payment" rules={[{ required: false, message: t("Please input the demurrage payment!") }]}>
        <Switch />
        </Form.Item>
  
        <Form.Item label={t("Container Return")} name="container_return" rules={[{ required: false, message: t("Please input the container return!") }]}>
        <Switch />
        </Form.Item>
  
        <Form.Item label={t("Provision Payment")} name="provision_payment" rules={[{ required: false, message: t("Please input the provision payment!") }]}>
        <Switch />
        </Form.Item>
  
        <Form.Item label={t("DIN Payment")} name="din_payment" rules={[{ required: false, message: t("Please input the DIN payment!") }]}>
        <Switch />
        </Form.Item>
  
        <Form.Item label={t("Custom Release")} name="custom_release" rules={[{ required: false, message: t("Please input the custom release!") }]}>
        <Switch />
        </Form.Item>
  
        <Form.Item label={t("Stock Site WH")} name="stock_site_wh" rules={[{ required: false, message: t("Please input the stock site WH!") }]}>
        <Switch />
        </Form.Item>
  
        <Form.Item label={t("Shipping Date")} name="shipping_date" rules={[{ required: true, message: t("Please select the shipping date!") }]}>
        <DatePicker onChange={(date) => form.setFieldsValue({ shipping_date: date ? dayjs(date) : null })} />
        </Form.Item>
  
        <Form.Item label={t("Delivery Date")} name="delivery_date" rules={[{ required: true, message: t("Please select the delivery date!") }]}>
        <DatePicker onChange={(date) => form.setFieldsValue({ delivery_date: date ? dayjs(date) : null })} />
        </Form.Item>
  
        <Form.Item label={t("Free Days")} name="free_days" rules={[{ required: true, message: t("Please input the free days!") }]}>
          <Input type="number" />
        </Form.Item>
  
        <Form.Item label={t("Demurrage Payment Status")} name="demurrage_payment_status" rules={[{ required: true, message: t("Please input the demurrage payment status!") }]}>
        <Select placeholder="Payment Status">
            <Option value="pending">Pending</Option>
            <Option value="paidclearance">PAID-CLEARENCE</Option>
            <Option value="paidclosed">PAID-Closed</Option>

          </Select>

        </Form.Item>
  
        <Form.Item label={t("Demurrage Payment Date")} name="demurrage_payment_date" rules={[{ required: true, message: t("Please select the demurrage payment date!") }]}>
        <DatePicker onChange={(date) => form.setFieldsValue({ demurrage_payment_date: date ? dayjs(date) : null })} />

        </Form.Item>
  
        <Form.Item label={t("Purchase Order Demurrage")} name="purchase_order_demurrage" rules={[{ required: true, message: t("Please input the purchase order demurrage!") }]}>
          <Input />
        </Form.Item>
        <Form.Item label={t("Total Demmurage USD")} name="total_demurrage_usd" rules={[{ required: true, message: t("Please input the Total Demurrage USD!") }]}>
          <Input />
        </Form.Item>

        <Form.Item label={t("Invoice Number")} name="invoice_number" rules={[{ required: true, message: t("Please input the Invoice Number!") }]}>
          <Input />
        </Form.Item>
        <Form.Item label={t("Custom Provision")} name="custom_provision" rules={[{ required: true, message: t("Please input the Custom Provision!") }]}>
          <Input />
        </Form.Item>
        <Form.Item label={t("Din")} name="din" rules={[{ required: true, message: t("Please input the Din!") }]}>
          <Input />
        </Form.Item>
        <Form.Item label={t("Comments")} name="comments" rules={[{ required: true, message: t("Please input the Comments!") }]}>
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {editingdemmurage ? t("Update") : t("Submit")}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddDemurrageForm;