import React, { useEffect, useState } from "react";
import { Drawer, Row, Col, Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import axios from "axios";
import { useTranslation } from "react-i18next"; // Importing the translation hook
import { getAuthToken } from "../utils/functions";
import { AuthTokenType } from "../utils/types";
import { transferorderfilesUrl } from "../utils/network";
import FileSaver from "file-saver";

interface TransferOrderFilesDrawerProps {
  transferOrderId: number | null;
  visible: boolean;
  onClose: () => void;
}

const TransferOrderFilesDrawer: React.FC<TransferOrderFilesDrawerProps> = ({ transferOrderId, visible, onClose }) => {
  const { t } = useTranslation(); // Using the translation hook
  const [files, setFiles] = useState<any[]>([]);

  const fetchFiles = async () => {
    try {
      const data = { transferorder_id: transferOrderId };
      const headers = getAuthToken() as AuthTokenType;
      const response = await axios.post(transferorderfilesUrl + '/get_files_by_transferorder', data, headers);
      setFiles(response.data);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  useEffect(() => {
    if (visible && transferOrderId) {
      fetchFiles();
    }
  }, [visible, transferOrderId]);

  const handleDownload = async (fileUrl: string, fileName: string) => {
    try {
      const response = await axios.get(fileUrl, {
        responseType: 'blob',
        headers: {
          'Authorization': `Bearer ${getAuthToken()}`
        }
      });
  
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      FileSaver.saveAs(pdfBlob, fileName);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <Drawer
      title={`${t('Files for Transfer Order ID')} ${transferOrderId}`} 
      placement="right"
      visible={visible}
      onClose={onClose}
      width="50%"
      height="100%"
    >
      <div style={{ overflowY: "auto", height: "100%" }}>
        <Row gutter={[16, 16]}>
          {files.map((file, index) => (
            <Col key={index} span={24}>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "16px" }}>
                <span>{file?.pdf_file.substring(file.pdf_file.lastIndexOf('/') + 1)}</span>
                <Button
                className="bbutton"
                  type="primary"
                  icon={<DownloadOutlined />}
                  onClick={() => handleDownload(file?.pdf_file, file?.pdf_file.substring(file.pdf_file.lastIndexOf('/') + 1))}
                >
                  {t('Download')}
                </Button>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </Drawer>
  );
};

export default TransferOrderFilesDrawer;