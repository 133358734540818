import React, { FC, useState, useEffect } from "react";
import { Modal, notification } from "antd";
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import {useGetProcessCategorizations, useGetProcessSpecifications } from "../utils/hooks";
import { AuthTokenType,ProcessSpecification} from "../utils/types";
import { getAuthToken, getLocation, getProcessCategorizations, getProcessSpecifications } from "../utils/functions";
import axios from "axios";
import { ProcessCategorizationURL, ProcessSpecificationURL } from "../utils/network";
import ContentLayout from "../components/ContentLayout";
import AddProcessCategorization from "./AddProcessCategorizations";
import AddProcessSpecification from "./AddProcessSpecification";
import ContentLayoutTabs from "../components/ContentLayoutTabs";

const ProcessSpecifications: FC = () => {
  const { t } = useTranslation(); // Initialize the useTranslation hook

  const columns = [
    {
      title: t("ID"), // Translate the title
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("Name"), // Translate the title
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Actions"), // Translate the title
      key: "actions",
      render: (text: string, record: ProcessSpecification) => (
        <span>
          <a onClick={() => handleEdit(record)}>{t("Edit")}</a>
          <span> | </span>
          <a onClick={() => handleDelete(record.id)}>{t("Delete")}</a>
        </span>
      ),
    },
  ];

  const [ProcessSpecifications, setProcessSpecifications] = useState<ProcessSpecification[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingProcessSpecifications, setEditingProcessSpecifications] = useState<ProcessSpecification | null>(null);

  useGetProcessSpecifications(setProcessSpecifications, setFetching);

  useEffect(() => {
    // When editingLocation changes, open the modal
    if (editingProcessSpecifications) {
      setDrawerVisible(true);
    }
  }, [editingProcessSpecifications]);

  const handleEdit = (record: ProcessSpecification) => {
console.log(record)
    setEditingProcessSpecifications(record);
  };

  const handleDelete = (ProcessSpecificationId: number) => {
    Modal.confirm({
      title: t("Confirm Deletion"), // Translate the title
      content: t("Are you sure you want to delete this record?"), // Translate the content
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;
          // Make a DELETE request to the API endpoint for deleting a location
          await axios.delete(`${ProcessSpecificationURL}/${ProcessSpecificationId}/`, headers);

          // After successful deletion, fetch updated locations
          getProcessSpecifications(setProcessSpecifications, setFetching);
        } catch (error) {
          // Handle error if the deletion fails
          notification.error({
            message: t("Delete Operation Error"), // Translate the message
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: t("Yes"), // Translate the text
      cancelText: t("No"), // Translate the text
    });
  };

  const onCloseWithoutEditing = () => {
    setEditingProcessSpecifications(null); // Reset editingCategory when form is closed without editing
  };


  return (
    <ContentLayoutTabs
      pageTitle={t("Process Specifications")} // Translate the title
      buttontitle={t("Add Process Spec.")} // Translate the title
      setModalState={setDrawerVisible}
      dataSource={ProcessSpecifications as any}
      columns={columns as any}
      fetching={fetching}
      includeFilters={true}
      model_name="processspecification"
    >
      <AddProcessSpecification
        onSuccessCallBack={() => {
          setDrawerVisible(false);
          getProcessSpecifications(setProcessSpecifications, setFetching);
        }}
        isVisible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        editingProcessSpecification={editingProcessSpecifications}
        onCloseWithoutEditing={onCloseWithoutEditing}
      />
    </ContentLayoutTabs>
  );
};

export default ProcessSpecifications;