import { useState, ChangeEvent } from "react"
import {getAuthToken, getpurchase} from "../utils/functions"
import {AuthTokenType,purchaseProps} from "../utils/types"
import { useGetpurchase} from "../utils/hooks"
import { Button,Drawer,Input, Modal, Table, Tag, Upload, notification } from "antd"
import axios,{AxiosResponse} from "axios"
import { ChatbotUrl, masterdatauploadURL, onhandUrl, purchasebulkURL, purchaseorderUrl, sendpoUrl, viewpoUrl} from "../utils/network"
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import EmailIcon from "@mui/icons-material/Email";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import FileSaver from "file-saver"; // Import FileSaver.js library

import EditPurchaseOrderDrawer from "../components/EditPurchaseOrderDrawer"
import { useTranslation } from "react-i18next"
import ChatBot from "../components/Chatbot"


const formatpurchaseDataProps = (
  purchasedata: purchaseProps[],
  onCloseOrder: (purchaseid: purchaseProps) => void,
  onChangeAdd: (value: Date | null, purchase_id: number) => void,
  onViewDetails: (item: purchaseProps) => void,
  onDeleteOrder: (item: purchaseProps) => void,
  onEditOrder: (item: purchaseProps) => void,
  t: (key: string) => string // Add the translate function as a parameter
) => {
  return purchasedata.map((item) => {
    let actionLabel = '';
    let actionHandler = () => {};

    if (item.status === 'Open') {
      actionLabel = t('Intransit'); // Translate the action label
      actionHandler = () => onCloseOrder(item);
    } else if (item.status === 'Intransit') {
      actionLabel = t('Close'); // Translate the action label
      actionHandler = () => onCloseOrder(item);
    }

    const changeDeliveryDateInput = item.status === 'Intransit' ? (
      <div>
        <Input
          style={{ width: "150px" }}
          type="date"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChangeAdd(e.target.valueAsDate, item.id)
          }
        />
      </div>
    ) : null;

    return {
      ...item,
      key: item.id,
      change_delivery_date: changeDeliveryDateInput,
      action: (
        <div>
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={actionHandler}
          >
            {actionLabel}
          </span>{" "}
          |{" "}
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => onViewDetails(item)}
          >
            {t('Details')} {/* Translate the action */}
          </span>{" "}
          |{" "}
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => onDeleteOrder(item)}
          >
            {t('Delete')} {/* Translate the action */}
          </span>
          |{" "} {/* Add Edit button */}
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => onEditOrder(item)} // Call onEditOrder function on click
          >
            {t('Edit')} {/* Translate the action */}
          </span>
        </div>
      ),
    };
  });
};

function PurchaseOrderReview(){
  
  const { t } = useTranslation(); // Initialize the useTranslation hook

  const[modalState,setModalState]=useState(false)
  const[fetching,setFetching]=useState(true)
    
  const [purchase,setpurchase]=useState<purchaseProps[]>([])
  const [purchaseadd,setpurchaseadd]=useState<{[key:number]:Date|null}>([])
  useGetpurchase(setpurchase,setFetching)
  const [loading,setLoading]=useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState<purchaseProps | null>(null);
  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const [pdfData, setPdfData] = useState<any>(null);

  const [editDrawerVisible, setEditDrawerVisible] = useState(false);
  const [editingItem, setEditingItem] = useState<purchaseProps | null>(null);


  const onEditOrder = (item: purchaseProps) => {
    setEditingItem(item);
    setEditDrawerVisible(true);
  };

  const showModal = (item: purchaseProps) => {
    setCurrentItem(item);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const sendemail= async (purchaseorder:purchaseProps|any)=>{

    const datatosend={
      purchase_order_id:purchaseorder.id,
    }
  
      const headers=getAuthToken() as AuthTokenType
      
  
    const response:AxiosResponse =await axios.post(sendpoUrl,datatosend,headers).catch(
        (e)=>{ notification.error({
            message:"Operation Error",
            description:e.response?.data.error
        })
  
    }
        ) as AxiosResponse
  
        setLoading(false)
  
        if(response){
            notification.success({
                message:"Operation Success",
                description:"Email Sent Successfully"
                
            })
  
        }
      }

      const [chatVisible, setChatVisible] = useState(false);

      const handleToggleChat = () => {
        setChatVisible(!chatVisible);
      };

      const downloadpo= async (purchaseorder:purchaseProps|any)=>{

        const datatosend={
          purchase_order_id:purchaseorder.id,
        }
      
          const headers=getAuthToken() as AuthTokenType
          
      
        const response:AxiosResponse =await axios.post(viewpoUrl,datatosend,headers).catch(
            (e)=>{ notification.error({
                message:"Operation Error",
                description:e.response?.data.error
            })
      
        }
            ) as AxiosResponse
          const pdfBlob = new Blob([response.data], { type: "application/pdf" });
          FileSaver.saveAs(pdfBlob, `Purchase_Order_${datatosend.purchase_order_id}.pdf`);
                     setLoading(false)
      
          }
    

          const uniquePortfolios = Array.from(new Set(purchase.map(item => item.portfolio)));
          const uniqueProjects = Array.from(new Set(purchase.map(item => item.project1)));
          const uniqueReferences = Array.from(new Set(purchase.map(item => item.material)));
          const uniqueSuppliers = Array.from(new Set(purchase.map(item => item.from_location ? item.from_location.name : 'N/A')));
          const uniqueStatuses = Array.from(new Set(purchase.map(item => item.status)));


      const purchaseordercolumns = [
        {
            title: t('ID'),
            dataIndex: 'id',
            key: 'id',
          },
          {
            title: t('Project'),
            dataIndex: 'project1',
            key: 'project1',
            filters: uniqueProjects.map(item => ({
              text: item,
              value: item
            })),
            onFilter: (value:any, record:any) => record.project1.toLowerCase().includes(value.toLowerCase()),
          },
          {
            title: t('Purchase Order'),
            dataIndex: 'po_number',
            key: 'po_number',
          },
          {
            title: t('Total Price'),
            dataIndex: 'total_price',
            key: 'total_price',
          },
          {
            title: t('Material'),
            dataIndex: 'material',
            key: 'material',
            filters: uniqueReferences.map(item => ({
              text: item,
              value: item
            })),
            onFilter: (value:any, record:any) => record.internal_reference.toLowerCase().includes(value.toLowerCase()),
          },

          {
            title: t("Downpayment"),
            dataIndex: "downpayment",
            key: "downpayment",
            render: (downpayment: boolean) => (
              <Tag color={downpayment ? "green" : "red"}>
                {downpayment ? t("Yes") : t("No")}
              </Tag>
            ),
          },
          {
            title: t("Second Payment"),
            dataIndex: "secondpayment",
            key: "secondpayment",
            render: (secondpayment: boolean) => (
              <Tag color={secondpayment ? "green" : "red"}>
                {secondpayment ? t("Yes") : t("No")}
              </Tag>
            ),
          },
          {
            title: t("Third payment"),
            dataIndex: "thirdpayment",
            key: "thirdpayment",
            render: (thirdpayment: boolean) => (
              <Tag color={thirdpayment ? "green" : "red"}>
                {thirdpayment ? t("Yes") : t("No")}
              </Tag>
            ),
          },

          {
            title: t("Confirmation of Bill of Lading"),
            dataIndex: "bol",
            key: "bol",
            render: (bol: boolean) => (
              <Tag color={bol ? "green" : "red"}>
                {bol ? t("Yes") : t("No")}
              </Tag>
            ),
          },
          {
            title: t("DIN Paid"),
            dataIndex: "din_paid",
            key: "din_paid",
            render: (din_paid: boolean) => (
              <Tag color={din_paid ? "green" : "red"}>
                {din_paid ? t("Yes") : t("No")}
              </Tag>
            ),
          },
          {
            title: t('Bill of Lading'),
            dataIndex: 'bill_of_lading',
            key: 'bill_of_lading',
          },
          {
            title: t('DIN Number'),
            dataIndex: 'din_number',
            key: 'din_number',
          },

          {
            title: t('Supplier'),
            dataIndex: 'origin',
            key: 'origin',
            filters: uniqueSuppliers.map(item => ({
              text: item,
              value: item
            })),
            onFilter: (value:any, record:any) => record.from_location.name.toLowerCase().includes(value.toLowerCase()),
          },
          
          {
            title: t('Portfolio'),
            dataIndex: 'portfolio',
            key: 'portfolio',
            filters: uniquePortfolios.map(item => ({
              text: item,
              value: item
            })),
            onFilter: (value:any, record:any) => record.portfolio.toLowerCase().includes(value.toLowerCase()),
          },


          
          {
            title: t('Delivery Date'),
            dataIndex: 'expected_delivery_date',
            key: 'expected_delivery_date',
            render: (text: string) => <div style={{ width: "10vw" }}>{text}</div> 

          },
          {
            title: t('Actual Delivery Date'),
            dataIndex: 'actual_delivery_date',
            key: 'actual_delivery_date',
            render: (text: string) => <div style={{ width: "10vw" }}>{text}</div> 

          },

          {
            title: t('Free Days'),
            dataIndex: 'free_days',
            key: 'free_days',
          },
          {
            title: t('Origin Country'),
            dataIndex: 'origin_country',
            key: 'origin_country',
          },
          {
            title: t('Origin Port'),
            dataIndex: 'origin_port',
            key: 'origin_port',
          },
          {
            title: t('Warehouse'),
            dataIndex: 'destination',
            key: 'destination',

          },

          {
            title: t('Destination Port'),
            dataIndex: 'destination_port',
            key: 'destination_port',
          },
          {
            title: t('Container Quantity'),
            dataIndex: 'container_id',
            key: 'container_id',
          },

         
          {
            title: t('Shipping Company'),
            dataIndex: 'shipping_company',
            key: 'shipping_company',
          },
          {
            title: t('Certificate of Origin'),
            dataIndex: 'certificate_of_origin',
            key: 'certificate_of_origin',
          },

          {
            title: t('Status'),
            dataIndex: 'status',
            key: 'status',
            filters: uniqueStatuses.map(item => ({
              text: item,
              value: item
            })),
            onFilter: (value:any, record:any) => record.status.toLowerCase().includes(value.toLowerCase()),
          },
          {
            title: t('Change Delivery Date'),
            dataIndex: 'change_delivery_date',
            key: 'change_delivery_date',
          },

          {
            title: t('Actions'),
            dataIndex: 'action',
            key: 'action',
            render: (text: string) => <div style={{ width: "20vw" }}>{text}</div> 
          },
        ];

        
        const Closeorder = async (purchaseorder: purchaseProps | any) => {
          let statusToUpdate = '';
        
          if (purchaseorder.status === 'Open') {
            statusToUpdate = 'Intransit';
            for (let i = 0; i < purchaseorder.purchaseorder_items.length; i++) {
              const datatosend2inv = {
                sku_code_id: purchaseorder.purchaseorder_items[i].item.id,
                storage_location_id: purchaseorder.to_location.id,
                supplier_id: purchaseorder.from_location.id,
                internal_reference: purchaseorder.internal_reference,
                po_number: purchaseorder.po_number,
                bill_of_lading: purchaseorder.bill_of_lading,
                project_id: purchaseorder.project.id,
                status: "Intransit",
                totalunits: purchaseorder.purchaseorder_items[i].quantity
              };
              const datatosend2inv2 = {
                sku_code_id: purchaseorder.purchaseorder_items[i].item.id,
                storage_location_id: purchaseorder.to_location.id,
                supplier_id: purchaseorder.from_location.id,
                internal_reference: purchaseorder.internal_reference,
                po_number: purchaseorder.po_number,
                bill_of_lading: purchaseorder.bill_of_lading,
                project_id: purchaseorder.project.id,
                status: "InManufacturing",
                totalunits: -purchaseorder.purchaseorder_items[i].quantity
              };

              const headers = getAuthToken() as AuthTokenType;
              await axios.post(onhandUrl, datatosend2inv, headers).catch((e) => {
                notification.error({
                  message: t("Operation Error"),
                  description: e.response?.data.error
                });
              });
              await axios.post(onhandUrl, datatosend2inv2, headers).catch((e) => {
                notification.error({
                  message: t("Operation Error"),
                  description: e.response?.data.error
                });
              });
            }
          } else if (purchaseorder.status === 'Intransit') {
            statusToUpdate = 'Closed';
            // Update inventory only if transitioning from 'Intransit' to 'Closed'
            for (let i = 0; i < purchaseorder.purchaseorder_items.length; i++) {
              const datatosend2inv = {
                sku_code_id: purchaseorder.purchaseorder_items[i].item.id,
                storage_location_id: purchaseorder.to_location.id,
                supplier_id: purchaseorder.from_location.id,
                internal_reference: purchaseorder.internal_reference,
                po_number: purchaseorder.po_number,
                bill_of_lading: purchaseorder.bill_of_lading,
                project_id: purchaseorder.project.id,
                status: "Available",
                totalunits: purchaseorder.purchaseorder_items[i].quantity
              };
              const datatosend2inv2 = {
                sku_code_id: purchaseorder.purchaseorder_items[i].item.id,
                storage_location_id: purchaseorder.to_location.id,
                supplier_id: purchaseorder.from_location.id,
                internal_reference: purchaseorder.internal_reference,
                po_number: purchaseorder.po_number,
                bill_of_lading: purchaseorder.bill_of_lading,
                project_id: purchaseorder.project.id,
                status: "Intransit",
                totalunits: -purchaseorder.purchaseorder_items[i].quantity
              };
              const headers = getAuthToken() as AuthTokenType;
              await axios.post(onhandUrl, datatosend2inv, headers).catch((e) => {
                notification.error({
                  message: t("Operation Error"),
                  description: e.response?.data.error
                });
              });
              await axios.post(onhandUrl, datatosend2inv2, headers).catch((e) => {
                notification.error({
                  message: t("Operation Error"),
                  description: e.response?.data.error
                });
              });
              
            }
          }
        
          const datatosend: any = {
            id: purchaseorder.id,
            status: statusToUpdate,
          };
        
          if (statusToUpdate === 'Closed') {
            datatosend.actual_delivery_date = purchaseadd[purchaseorder.id];
          }
        
          setLoading(true);
          const headers = getAuthToken() as AuthTokenType;
        
          const response: AxiosResponse = await axios.patch(purchaseorderUrl + '/' + datatosend.id, datatosend, headers).catch((e) => {
            notification.error({
              message: t("Operation Error"),
              description: e.response?.data.error
            });
          }) as AxiosResponse;
        
          setLoading(false);
        
          if (response) {
            notification.success({
              message: t("Operation Success"),
              description: t("Order ") + (statusToUpdate === 'Closed' ? "Closed" : "Updated to Intransit") + t(" Successfully")
            });
          }
        
          getpurchase(setpurchase, setFetching);
        };
        

        const DeleteOrder = async (purchaseorder: purchaseProps | any) => {
          const confirmDeletion = () => {
            Modal.confirm({
              title: t("Confirm Deletion"),
              content: t("Are you sure you want to delete this order?"),
              onOk: async () => {
                try {
                  const datatosend = {
                    id: purchaseorder.id,
                  };
        
                  const headers = getAuthToken() as AuthTokenType;
        
                  const response: AxiosResponse = await axios.delete(purchaseorderUrl + '/' + datatosend.id, headers );
        
                  setLoading(false);
        
                  if (response) {
                    notification.success({
                      message: t("Operation Success"),
                      description: t("Order Deleted Successfully")
                    });
                  }
        
                  getpurchase(setpurchase, setFetching);
                } catch (error) {
                  notification.error({
                    message: t("Operation Error"),
                  });
                }
              },
              onCancel: () => {
                // Do nothing if the user cancels the deletion
              },
              okText: t("Yes"),
              cancelText: t("No"),
            });
          };
        
          confirmDeletion();
        };

        const onCloseWithoutEditing = () => {
          setEditingItem(null); // Reset editingCategory when form is closed without editing
        };
      
             
  const onChangeAdd=(value:Date|null,purchase_id:number)=>{
          setpurchaseadd({
            ...purchaseadd,
            [purchase_id]:value
          })

        }
        const [searchQuery, setSearchQuery] = useState("");

        const filteredData = purchase.filter((record) =>
        Object.values(record).some((value) =>
          value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
      );

        
      const downloadCSV = () => {
        // Convert data to CSV format
        const csvContent = purchaseordercolumns.map(column => column.title).join('\t') + '\n' +
          filteredData.map(row => purchaseordercolumns.map(column => {
            return `"${row[column.dataIndex as keyof purchaseProps]}"`;
          }).join('\t')).join('\n');
      
        // Create a Blob object containing the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      
        // Create a temporary URL for the Blob
        const url = window.URL.createObjectURL(blob);
      
        // Create a link element
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", "data.csv");
      
        // Append the link to the document body and trigger the click event
        document.body.appendChild(link);
        link.click();
      
        // Clean up by revoking the temporary URL
        window.URL.revokeObjectURL(url);
      };
      const userRole = localStorage.getItem('token1');
      
      const handleDeleteAll = async () => {
        Modal.confirm({
          title: t("Confirm Deletion"),
          content: t("Are you sure you want to delete all Purchase Orders?"),
          onOk: async () => {
            try {
              const headers = getAuthToken() as AuthTokenType;
    
              // Make a DELETE request to the API endpoint for deleting all shipments
              await axios.delete(purchasebulkURL, headers);
    
              // After successful deletion, fetch updated shipments
              getpurchase(setpurchase, setFetching);
            } catch (error) {
              // Handle error if the deletion fails
              notification.error({
                message: t("Delete Operation Error"),
              });
            }
          },
          onCancel: () => {
            // Do nothing if the user cancels the deletion
          },
          okText: t("Yes"),
          cancelText: t("No"),
        });
      };
    

      const handleUploadpos = async (file: File ) => {
        const formData = new FormData();
        formData.append('file', file);
    
        formData.append('model', 'purchaseorderupload' || '');
    
        try {
          let response: AxiosResponse;
          
          const headers = getAuthToken() as AuthTokenType;
    
          response = await axios.post(masterdatauploadURL, formData, headers);
    
          if (response) {
            notification.success({ message: 'File uploaded successfully' });
            window.location.reload();
          } else {
            notification.error({ message: 'Failed to upload file'});
          }
        } catch (error) {
          notification.error({ message: 'Failed to upload file' });
        }
      };

      const handleUploadposdetail = async (file: File ) => {
        const formData = new FormData();
        formData.append('file', file);
    
        formData.append('model', 'purchaseorderdetailupload' || '');
    
        try {
          let response: AxiosResponse;
          
          const headers = getAuthToken() as AuthTokenType;
    
          response = await axios.post(masterdatauploadURL, formData, headers);
    
          if (response) {
            notification.success({ message: 'File uploaded successfully' });
            window.location.reload();
          } else {
            notification.error({ message: 'Failed to upload file'});
          }
        } catch (error) {
          notification.error({ message: 'Failed to upload file' });
        }
      };


        return (
          <>
              <EditPurchaseOrderDrawer
              isVisible={editDrawerVisible}
              onClose={() => setEditDrawerVisible(false)}
              editingPurchase={editingItem}
              onSuccessCallBack={() => {
                setEditDrawerVisible(false);
                getpurchase(setpurchase, setFetching);

              }}
              onCloseWithoutEditing={onCloseWithoutEditing}

            />
          <div className="card">
              <div className="cardHeader">
                  <h2 className="headContent">{t("Purchase Orders")}</h2>
                  <div className="rightContent">
                  <Input
              placeholder="Search..."
              onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}
              style={{ marginRight: '10px' }}
            />
            <Button onClick={downloadCSV}>Download</Button>
            <Button
  type="primary"
  className="bbutton"
  onClick={handleDeleteAll}
  style={{ display: userRole === 'admin' ? 'inline-block' : 'none' }}
>
  {t("Bulk Delete")}
</Button>
<Upload
                beforeUpload={file => {
                  handleUploadpos(file);
                  return false;
                }}
                showUploadList={false}
              >
                <Button>Upload</Button>
              </Upload>
              <Upload
                beforeUpload={file => {
                  handleUploadposdetail(file);
                  return false;
                }}
                showUploadList={false}
              >
                <Button>Details</Button>
              </Upload>

                        </div>

              </div>
              <br/>
              <Table className="custom-table"
              dataSource={(formatpurchaseDataProps(purchase, Closeorder, onChangeAdd, showModal, DeleteOrder, onEditOrder, t)).filter(record =>
                Object.values(record).some(value =>
                  value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
                ))}                
                columns={purchaseordercolumns as any}
              size="small"
              loading ={fetching}/>

<Drawer
  title="Details"
  visible={isModalVisible}
  placement="right"
  onClose={handleOk}
  style={{ borderRadius: '10px' }} // Add rounded corners to the drawer
>
  {currentItem && (
    <>
      <div style={{ marginBottom: '20px', borderBottom: '1px solid #e8e8e8', paddingBottom: '10px' }}>
        <p style={{ fontSize: '16px', marginBottom: '8px', fontWeight: 'bold' }}>ID: {currentItem.id}</p>
        <p style={{ fontSize: '16px', marginBottom: '8px' }}>Supplier: {currentItem.from_location?.name}</p>
        <p style={{ fontSize: '16px', marginBottom: '8px' }}>To Location: {currentItem.to_location?.name}</p>
      </div>
      <div>
        <p style={{ fontSize: '16px', marginBottom: '8px', fontWeight: 'bold' }}>Items:</p>
        <Table
          className="custom-table"
          dataSource={currentItem.purchaseorder_items}
          columns={[
            {
              title: t('Item Name'),
              dataIndex: ['item', 'name'],
              key: 'name',
              render: (text: string) => <span>{JSON.stringify(text)}</span>,
            },
            {
              title: t('SUb Product Name'),
              dataIndex: ['item', 'subname'],
              key: 'subname',
              render: (text: string) => <span>{JSON.stringify(text)}</span>,
            },
            {
              title: t('Quantity'),
              dataIndex: 'quantity',
              key: 'quantity',
              render: (text: number) => <span>{JSON.stringify(text)}</span>,
            },
          ]}
          pagination={false}
          bordered
          size="small"
          style={{ marginTop: '20px' }}
        />
      </div>
    </>
  )}
   <SpeedDial
            ariaLabel="SpeedDial"
            icon={<SpeedDialIcon />}
            onClose={() => setSpeedDialOpen(false)}
            onOpen={() => setSpeedDialOpen(true)}
            open={speedDialOpen}
            direction="up"
            FabProps={{ size: 'small' }} 
          >
  <SpeedDialAction
    key="Send Email"
    icon={<EmailIcon />}
    tooltipTitle="Send Email"
    onClick={() => sendemail(currentItem)}
  />
  <SpeedDialAction
    key="Download"
    icon={<PictureAsPdfIcon />}
    tooltipTitle="Download "
    onClick={() => downloadpo(currentItem)}
  />
          </SpeedDial>
</Drawer>
          </div>
          {chatVisible && <ChatBot chatbotUrl={ChatbotUrl} />}
      {/* Add a button to toggle the chat */}
      <Button
        type="primary"
        style={{
          position: 'fixed',
          bottom: 10,
          right: 20,
          borderRadius: 10,
          zIndex: 999, // Ensure the button is above other elements
        }}
        onClick={handleToggleChat}
      >
        {chatVisible ? 'Hide' : 'Ask Nancy'}
      </Button>
        </> 
        )
    }
    
    export default PurchaseOrderReview