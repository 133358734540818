import React, { FC, useState, useEffect } from "react";
import { Drawer, notification, Form, Input, InputNumber, Select, Button } from "antd";
import { AuthTokenType, DataProps, AddProductFormProps, unitofmeasure, category, currency } from "../utils/types";
import { getAuthToken } from "../utils/functions";
import axios, { AxiosResponse } from "axios";
import { productsUrl } from "../utils/network";
import { useGetCategory, useGetCurrency, useGetUnitofmeasure } from "../utils/hooks";
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
const ImageHolder: string = require("../assets/ImageHolder.svg").default;

const AddProductForm: FC<AddProductFormProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose,
  editingProduct,
  onCloseWithoutEditing, // new prop
}) => {
  const { t } = useTranslation();
  const { Option } = Select;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<category[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [imageloading, setImageLoading] = useState(false);
  const fileSelect = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  useGetCategory(setCategories, setFetching);

  const [currency, setCurrency] = useState<currency[]>([]);

  useGetCurrency(setCurrency, setFetching);

  const [unitofmeasures, setUnitofmeasures] = useState<unitofmeasure[]>([]);

  useGetUnitofmeasure(setUnitofmeasures, setFetching);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    // Populate form fields if editing a product
    if (editingProduct) {
      form.setFieldsValue({
        ...editingProduct,
       
        sku_uom_id: editingProduct.sku_uom.id,
        category_id: editingProduct.category.id,
        currency_id: editingProduct.currency.id,
      });
      // Set the image URL if the product has an image
      setImageUrl(editingProduct.image || null);
    } else {
      form.resetFields();
      setImageUrl(null); // Reset the image URL when adding a new product
    }
  }, [editingProduct, form]);


  const handleFormClose = () => {
    form.resetFields();
    onClose?.();
    if (!form.isFieldsTouched()) {
      onCloseWithoutEditing?.(); // Notify the parent about closing without editing
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      const selectedImage = fileList[0];
      setImage(selectedImage); // Update state with the selected file

      // Create a URL object for the selected image and set it as the background
      const imageUrl = URL.createObjectURL(selectedImage);
      setImageUrl(imageUrl);
    }
  };

  const onSubmit = async (values: DataProps) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

    try {
      let formData = new FormData(); // Create a new FormData object

      // Append other form values to the FormData object
      Object.entries(values).forEach(([key, value]) => {
        if (value !== undefined && value !== null  && key !== 'image') { // Check if the value is neither undefined nor null
          formData.append(key, value.toString()); // Convert value to string before appending
        }
      });

      // Append the image file to the FormData object
      if (image) {
        formData.append('image', image);
      }
      let response: AxiosResponse;

      if (editingProduct) {
        // Editing product
        response = await axios.put(`${productsUrl}/${editingProduct.id}/`, formData, headers);
      } else {
        // Adding new product
        response = await axios.post(productsUrl + '/', formData, headers);
      }

      setLoading(false);
      if (response) {
        onSuccessCallBack?.();
        onClose?.(); // Close the drawer
      }
    } catch (error) {
      notification.error({
        message: t('Operation Error'),
      });
      setLoading(false);
    }
  };

  return (
    <Drawer
      title={editingProduct ? t("Edit Product") : t("Add Product")}
      visible={isVisible}
      onClose={handleFormClose}

      destroyOnClose
      width={360}
    >
      <Form
        layout="vertical"
        onFinish={onSubmit}
        form={form}
        onValuesChange={() => setHasChanges(true)}
      >
        <Form.Item
          label={t("Product Photo")}
          name="image"
        >
          <div
            className="imageview"
            onClick={() => !loading && fileSelect.current?.click()}
            style={{
              backgroundImage: `url(${imageUrl ? imageUrl : ImageHolder})`
            }}
          />
          <input type="file" style={{ display: "none" }} ref={fileSelect} onChange={handleFileChange} />
        </Form.Item>
        <Form.Item
          label={t("Name")}
          name="name"
          rules={[{ required: true, message: t('Please input the product name!') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("Sub Product Name")}
          name="subname"
          rules={[{ required: true, message: t('Please input the SUb Product Name!') }]}
        >
          <Input />
        </Form.Item>
  

        <Form.Item
          label={t("Material Code ID")}
          name="code"
          rules={[{ required: true, message: t('Please input the product code!') }]}
        >
          <Input />
        </Form.Item>
    
        <Form.Item
          label={t("SKU Description")}
          name="sku_description"
          rules={[{ required: true, message: t('Please input the SKU description!') }]}
        >
          <Input />
        </Form.Item>
  
        <Form.Item
          label={t("SKU Unit of Measure")}
          name="sku_uom_id"
          rules={[{ required: true, message: t('Please select the SKU UOM!') }]}
        >
          <Select>
            <Select.Option value="" disabled>{t("Select a unit of measure")}</Select.Option>
            {unitofmeasures.map(uom => (
              <Select.Option key={uom.id} value={uom.id}>
                {uom.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
  
        <Form.Item
          label={t("Intercompany Purchase Price")}
          name="sku_buy_cost"
          rules={[{ required: true, message: 'Please input the SKU buy cost!' }]}
        >
          <InputNumber />
        </Form.Item>
  
        <Form.Item
          label={t("SKU Price")}
          name="sku_price"
          rules={[{ required: true, message: 'Please input the SKU price!' }]}
        >
          <InputNumber />
        </Form.Item>
  
        <Form.Item
          label={t("Category")}
          name="category_id"
          rules={[{ required: true, message: 'Please select the category!' }]}
        >
          <Select>
            <Select.Option value="" disabled>Select a category</Select.Option>
            {categories.map(category => (
              <Select.Option key={category.id} value={category.id}>
                {category.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
  
        <Form.Item
          label={t("Currency")}
          name="currency_id"
          rules={[{ required: true, message: 'Please select the currency!' }]}
        >
          <Select>
            <Select.Option value="" disabled>Select a currency</Select.Option>
            {currency.map(curr => (
              <Select.Option key={curr.id} value={curr.id}>
                {curr.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
  
        <Form.Item>
        <Button className="bbutton" htmlType="submit" type="primary" block loading={loading}>
    {editingProduct ? t("Update") : t("Submit")} {/* Translate the button text */}
  </Button>
          </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddProductForm;