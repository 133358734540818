import { useContext } from "react";
import { store } from "../utils/store";
import SummaryData from "../components/Dashboard/summaryData";
import Sankeychart from "../components/Dashboard/Sankeychart";
import Treechart from "../components/Dashboard/TreeChart";
import InventoryTable from "./InventoryTable";

function Home(){
  const{state}=useContext(store)
  return (
      <div>
          <div>
              <SummaryData/>
          </div>
              
              <div >
                <InventoryTable/>
              </div>
              <br/>
          </div>
  )
}
export default Home