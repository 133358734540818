import React, { FC, useState, useEffect } from "react";
import { Drawer, notification, Form, Input, Button, Switch, Select } from "antd";
import { AuthTokenType, DataProps, AddCategoryFormProps, category } from "../utils/types";
import { getAuthToken, getCategory } from "../utils/functions";
import axios, { AxiosResponse } from "axios";
import { categoriesUrl } from "../utils/network";
import { useGetCategory } from "../utils/hooks";
import { useTranslation } from "react-i18next"; // Import useTranslation hook

const AddCategoryForm: FC<AddCategoryFormProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose,
  onCloseWithoutEditing, // new prop
  editingCategory,
}) => {
  const { t } = useTranslation(); // Initialize the useTranslation hook
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [fetching, setFetching] = useState<boolean>(true);

  const [categories, setCategories] = useState<category[]>([]);
  useGetCategory(setCategories, setFetching);

  useEffect(() => {
    if (editingCategory) {
      form.setFieldsValue({
        ...editingCategory,
        is_active: editingCategory.is_active === true,
      });
    } else {
      form.resetFields();
    }
  }, [editingCategory, form]);

  const onSubmit = async (values: DataProps) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

    try {
      let response: AxiosResponse;

      if (editingCategory) {
        response = await axios.put(`${categoriesUrl}/${editingCategory.id}/`, values, headers);
      } else {
        response = await axios.post(categoriesUrl + '/', values, headers);
      }

      setLoading(false);

      if (response) {
        form.resetFields()
        getCategory(setCategories, setFetching);
        onSuccessCallBack?.();
        onClose?.(); // Close the drawer
      }
    } catch (error) {
      notification.error({
        message: t("Operation Error"), // Translate the error message
      });
      setLoading(false);
    }
  };

  const handleFormClose = () => {
    form.resetFields();
    onClose?.();
    if (!form.isFieldsTouched()) {
      onCloseWithoutEditing?.(); // Notify the parent about closing without editing
    }
  };

  return (
    <Drawer
      title={editingCategory ? t("Edit Category") : t("Add Category")} // Translate the title
      visible={isVisible}
      onClose={handleFormClose}
      destroyOnClose
      width={360}
    >
      <Form layout="vertical" onFinish={onSubmit} form={form} onValuesChange={() => setHasChanges(true)}>
        <Form.Item label={t("Name")} name="name" rules={[{ required: true, message: t('Please input the category name!') }]}>
          <Input placeholder={t("Category Name")} />
        </Form.Item>
        <Form.Item
          label={t("Parent")}
          name="parent_id"
          rules={[{ required: false, message: t('Please select the category!') }]}
        >
          <Select>
            <Select.Option value="" disabled>{t("Select a Parent")}</Select.Option>
            {categories.map(category => (
              <Select.Option key={category.id} value={category.id}>
                {category.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label={t("Is Active")} name="is_active" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item>
          <Button className="bbutton" htmlType="submit" type="primary" block loading={loading}>
            {editingCategory ? t("Update") : t("Submit")}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddCategoryForm;