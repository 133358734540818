import { BrowserRouter, Route, Routes  } from 'react-router-dom';
import Login from "./pages/Login"
import CheckUser from "./pages/CheckUser"
import Home from "./pages/Home"
import AuthRoute from './components/AuthRoute';
import User from './pages/User';
import UpdateUserPassword from './pages/UpdateUserPassword';
import Categories from './pages/Categories';
import Unitofmeasure from './pages/Unitofmeasure';
import Currencies from './pages/Currencies';
import Locations from './pages/Locations';
import Suppliers from './pages/Suppliers';
import Products from './pages/Products';
import PurchaseOrder from './pages/AddPurchase';
import PurchaseOrderReview from './pages/PurchaseOrderReview';
import StocktransferOrderReview from './pages/TransferOrderReview';
import StocktransferOrder from './pages/AddTransfers';
import GroupView from './pages/UserGroups';
import OnHandInventory from './pages/OnHandInventory';
import TransferOrderfiles from './pages/TransferOrderFiles';
import PurchaseOrderfiles from './pages/PurchaseOrderFiles';
import InventoryTable from './pages/InventoryTable';
import Projects from './pages/Projects';
import Shipments from './pages/Shipment';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import ProcessCategorizations from './Procurement/ProcessCategorizations';
import ProcessSpecifications from './Procurement/ProcessSpecification';
import Cecos from './Procurement/cecos';
import ProjectPs from './Procurement/ProjectP';
import BudgetCodes from './Procurement/BudgetCodes';
import Statuses from './Procurement/Status';
import DeliveryTerms from './Procurement/DeliveryTerm';
import Requirements from './Procurement/Requirements';
import Analysis from './Procurement/Analysis';
import Demmurage from './pages/DemmurageControl';
import DemurrageAnalysis from './pages/DemurrageAnalysis';
import ProcurementTabs from './Procurement/ProcurementTabs';
import Logisticstabs from './pages/LogisticsTabs';
import RFQtabs from './Procurement/RFQtabs';


function Router() {
  return (
  <BrowserRouter>
   
      <Routes>
      
      <Route path="/Login"  element={<Login/>}/>
      <Route path="/check-user"  element={<CheckUser/>}/>
      <Route path="/create-password"  element={<UpdateUserPassword/>}/>
      <Route path="/forgotpassword" element={<ForgotPassword/>}/>
      <Route path="/resetpassword" element={<ResetPassword/>}/>


      <Route path="/" element={<AuthRoute><Home/></AuthRoute>}/>
      <Route path="/users" element={<AuthRoute><User/></AuthRoute>}/>
      <Route path="/permissions" element={<AuthRoute><GroupView/></AuthRoute>}/>
      <Route path="/category" element={<AuthRoute><Categories/></AuthRoute>}/>
      <Route path="/uom" element={<AuthRoute><Unitofmeasure/></AuthRoute>}/>
      <Route path="/currency" element={<AuthRoute><Currencies/></AuthRoute>}/>
      <Route path="/location" element={<AuthRoute><Locations/></AuthRoute>}/>
      <Route path="/project" element={<AuthRoute><Projects/></AuthRoute>}/>
      <Route path="/supplier" element={<AuthRoute><Suppliers/></AuthRoute>}/>
      <Route path="/product" element={<AuthRoute><Products/></AuthRoute>}/>
      <Route path="/purchase" element={<AuthRoute><PurchaseOrder/></AuthRoute>}/>
      <Route path="/purchasereview" element={<AuthRoute><PurchaseOrderReview/></AuthRoute>}/>
      <Route path="/transfers" element={<AuthRoute><StocktransferOrder/></AuthRoute>}/>
      <Route path="/transfersreview" element={<AuthRoute><StocktransferOrderReview/></AuthRoute>}/>
      <Route path="/onhand" element={<AuthRoute><OnHandInventory/></AuthRoute>}/>
      <Route path="/transferfiles" element={<AuthRoute><TransferOrderfiles/></AuthRoute>}/>
      <Route path="/purchasefiles" element={<AuthRoute><PurchaseOrderfiles/></AuthRoute>}/>
      <Route path="/onhandinventory" element={<AuthRoute><InventoryTable/></AuthRoute>}/>
      <Route path="/shipments" element={<AuthRoute><Shipments/></AuthRoute>}/>
      <Route path="/processcategorization" element={<AuthRoute><ProcessCategorizations/></AuthRoute>}/>
      <Route path="/processspecification" element={<AuthRoute><ProcessSpecifications/></AuthRoute>}/>
      <Route path="/ceco" element={<AuthRoute><Cecos/></AuthRoute>}/>
      <Route path="/projectp" element={<AuthRoute><ProjectPs/></AuthRoute>}/>
      <Route path="/budgetcodes" element={<AuthRoute><BudgetCodes/></AuthRoute>}/>
      <Route path="/status" element={<AuthRoute><Statuses/></AuthRoute>}/>
      <Route path="/deliveryterm" element={<AuthRoute><DeliveryTerms/></AuthRoute>}/>
      <Route path="/requirement" element={<AuthRoute><Requirements/></AuthRoute>}/>
      <Route path="/analytics" element={<AuthRoute><Analysis/></AuthRoute>}/>
      <Route path="/demmurage" element={<AuthRoute><Demmurage/></AuthRoute>}/>
      <Route path="/demmurageanalysis" element={<AuthRoute><DemurrageAnalysis/></AuthRoute>}/>
      <Route path="/ptabs" element={<AuthRoute><ProcurementTabs/></AuthRoute>}/>
      <Route path="/ltabs" element={<AuthRoute><Logisticstabs/></AuthRoute>}/>
      <Route path="/rfqtabs" element={<AuthRoute><RFQtabs/></AuthRoute>}/>



      </Routes>
   
  </BrowserRouter>
  )
}
export default Router