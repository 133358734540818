import React, { useState, useEffect, useRef } from "react";
import { Table, message, Popconfirm, Button, Select, Modal, List, Typography } from "antd";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import axios from "axios";
import { getAuthToken, getpurchase } from "../utils/functions";
import { AuthTokenType, purchaseProps } from "../utils/types";
import { useGetpurchase } from "../utils/hooks";
import { purchaseorderfilesUrl } from "../utils/network";
import PurchaseOrderFilesDrawer from "../components/PurchaseOrderFilesDrawer";
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const PurchaseOrderfiles = () => {
  const { t } = useTranslation();

  const [fetching, setFetching] = useState(true);
  const [purchaseorder, setpurchaseorder] = useState<purchaseProps[]>([]);
  const [fileLists, setFileLists] = useState<{ [key: number]: { files: any[], folder: string } }>({});
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedPurchaseOrderId, setSelectedPurchaseOrderId] = useState<number | null>(null);
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const [currentRecordId, setCurrentRecordId] = useState<number | null>(null);
  const [selectedFolder, setSelectedFolder] = useState<string>('Others');
  
  useGetpurchase(setpurchaseorder, setFetching);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleOpenUploadModal = (recordId: number) => {
    setCurrentRecordId(recordId);
    setUploadModalVisible(true);
  };

  const handleUploadImages = async (recordId: number) => {
    if (!fileLists[recordId]?.files.length) return;
    const headers = getAuthToken() as AuthTokenType;
    const folder = selectedFolder;
    const files = fileLists[recordId].files;
    try {
      const formData = new FormData();
      formData.append("purchaseorder_id", recordId.toString());
      formData.append("folder", folder);
      files.forEach((file: any) => {
        formData.append("files", file);
      });
      await axios.post(purchaseorderfilesUrl, formData, headers);
      message.success(t("Files submitted successfully"));
      setFileLists(prevFileLists => ({
        ...prevFileLists,
        [recordId]: { files: [], folder: 'Others' },
      }));
      setUploadModalVisible(false);
    } catch (error) {
      console.error("Error submitting files:", error);
      message.error(t("Failed to submit files"));
    }
    getpurchase(setpurchaseorder, setFetching);
  };

  const handleFileChange = (recordId: number, files: FileList | null) => {
    if (!files) return;
    const newFiles = Array.from(files);
    setFileLists(prevFileLists => ({
      ...prevFileLists,
      [recordId]: {
        files: [...(prevFileLists[recordId]?.files || []), ...newFiles],
        folder: selectedFolder
      },
    }));
    const fileNames = newFiles.map(file => file.name).join(", ");
    message.success(t(`Uploaded images for Purchase order ID ${recordId}: ${fileNames}`));
  };

  const handleFolderChange = (folder: string) => {
    setSelectedFolder(folder);
  };

  const handleDeleteImage = (recordId: number, file: any) => {
    const newFileList = fileLists[recordId].files.filter((f: any) => f !== file);
    setFileLists(prevFileLists => ({
      ...prevFileLists,
      [recordId]: {
        ...prevFileLists[recordId],
        files: newFileList
      }
    }));
    message.success(t(`Deleted File ${file.name}`));
  };

  const handleViewFiles = (recordId: number) => {
    setSelectedPurchaseOrderId(recordId);
    setDrawerVisible(true);
  };
  const uniquePortfolios = Array.from(new Set(purchaseorder.map(item => item.portfolio)));
  const uniqueProjects = Array.from(new Set(purchaseorder.map(item => item.project1)));
  const uniqueReferences = Array.from(new Set(purchaseorder.map(item => item.material)));
  const uniqueSuppliers = Array.from(new Set(purchaseorder.map(item => item.from_location ? item.from_location.name : 'N/A')));
  const uniqueStatuses = Array.from(new Set(purchaseorder.map(item => item.status)));
  const uniquePONumbers = Array.from(new Set(purchaseorder.map(item => item.po_number)));

  const purchaseOrderColumns = [
    {
      title: t("ID"),
      dataIndex: "id",
      key: "id",
    },
    {
      title: t('Portfolio'),
      dataIndex: 'portfolio',
      key: 'portfolio',
      filters: uniquePortfolios.map(item => ({
        text: item,
        value: item
      })),
      onFilter: (value:any, record:any) => record.portfolio.toLowerCase().includes(value.toLowerCase()),
    },

    {
      title: t('Project'),
      dataIndex: 'project1',
      key: 'project1',
      filters: uniqueProjects.map(item => ({
        text: item,
        value: item
      })),
      onFilter: (value:any, record:any) => record.project1.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: t('Purchase Order'),
      dataIndex: 'po_number',
      key: 'po_number',
      filters: uniquePONumbers.map(item => ({
        text: item,
        value: item
      })),
      onFilter: (value: any, record: any) => record.po_number && record.po_number.includes(value),
    },
    {
      title: t('Supplier'),
      dataIndex: ['from_location','name'],
      key: 'from_location',
      filters: purchaseorder.map(item => ({
        text: item.from_location ? item.from_location.name : 'N/A',
        value: item.from_location ? item.from_location.name : 'N/A'
      })),
      onFilter: (value:any, record:any) => record.from_location.name.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
    },
    {
      title: t("Action"),
      key: "action",
      render: (text: any, record: purchaseProps) => (
        <>
          <a onClick={() => handleOpenUploadModal(record.id)} style={{ marginRight: 8 }}>
            {t("Upload")}
          </a>
          <a onClick={() => handleViewFiles(record.id)}><EyeOutlined /> {t("View")}</a>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (uploadModalVisible && fileInputRef.current) {
      fileInputRef.current.value = ''; // Clear the file input when the modal is reopened
    }
  }, [uploadModalVisible]);

  return (
    <>
      <div className="card">
        <div className="cardHeader">
          <h2 className="headContent">{t("Purchase Orders")}</h2>
        </div>
        <br />
        <Table
          className="custom-table"
          dataSource={purchaseorder}
          columns={purchaseOrderColumns as any}
          size="small"
          loading={fetching}
        />
      </div>
      <Modal
        title={t("Upload Files")}
        visible={uploadModalVisible}
        onCancel={() => setUploadModalVisible(false)}
        onOk={() => handleUploadImages(currentRecordId!)}
      >
        <Select
          defaultValue="Others"
          style={{ width: '100%', marginBottom: 16 }}
          onChange={handleFolderChange}
        >
          <Option value="Commercial invoice">{t("Commercial invoice")}</Option>
          <Option value="Purchase order">{t("Purchase order")}</Option>
          <Option value="Insurance">{t("Insurance")}</Option>
          <Option value="Packing list">{t("Packing list")}</Option>
          <Option value="Bill of lading">{t("Bill of lading")}</Option>
          <Option value="Certificate of origin">{t("Certificate of origin")}</Option>
          <Option value="Others">{t("Others")}</Option>
        </Select>
        <input
          type="file"
          multiple
          onChange={(e) => handleFileChange(currentRecordId!, e.target.files)}
          ref={fileInputRef}
        />
        <List
          size="small"
          bordered
          dataSource={currentRecordId !== null && fileLists[currentRecordId] ? fileLists[currentRecordId].files : []}
          renderItem={(file: any) => (
            <List.Item
              actions={[
                <Popconfirm
                  title={t("Are you sure to delete this file?")}
                  onConfirm={() => handleDeleteImage(currentRecordId!, file)}
                  okText={t("Yes")}
                  cancelText={t("No")}
                >
                  <DeleteOutlined />
                </Popconfirm>
              ]}
            >
              <Typography.Text>{file.name}</Typography.Text>
            </List.Item>
          )}
          style={{ marginTop: 16 }}
        />
      </Modal>
      <PurchaseOrderFilesDrawer
        purchaseorderId={selectedPurchaseOrderId}
        visible={drawerVisible}
        onClose={() => {
          setSelectedPurchaseOrderId(null);
          setDrawerVisible(false);
        }}
      />
    </>
  );
};

export default PurchaseOrderfiles