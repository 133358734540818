import React, { useState, useRef, useEffect } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { styled } from '@mui/system';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import MicIcon from '@mui/icons-material/Mic';
import axios, { AxiosResponse } from 'axios';
import { ChatbotUrl } from '../utils/network';
import { getAuthToken } from '../utils/functions';
import { AuthTokenType } from '../utils/types';

const ChatContainer = styled(Paper)({
  position: 'fixed',
  bottom: 40,
  right: 20,
  width: 500,
  height: 500,
  borderRadius: 10,
  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
  overflow: 'hidden',
  zIndex: 1000,
  display: 'flex',
  flexDirection: 'column'
});

const ChatHeader = styled('div')({
  background: '#075e54',
  color: '#fff',
  padding: '8px 16px',
  display: 'flex',
  alignItems: 'center',
});

const ChatBody = styled('div')({
  maxHeight: 400,
  overflowY: 'auto',
  flexGrow: 1,
  padding: '8px 16px',
  background: '#f5f5f5',
  fontSize: '16px',
});

const ChatInput = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  borderTop: '1px solid #ddd',
  padding: '8px 16px',
  background: '#fff',
});

const InputField = styled(TextField)({
  flex: 1,
  marginRight: 10,
  '& textarea': {
    minHeight: 50,
    fontSize: '16px',
  },
  '& .MuiInputBase-input': {
    fontSize: '16px',
  },
});

interface MessageBubbleProps {
  sender: string;
}

const MessageBubble = styled('div')<MessageBubbleProps>(({ sender }) => ({
  maxWidth: '90%',
  marginBottom: 8,
  padding: '8px 12px',
  borderRadius: 8,
  backgroundColor: sender === 'You' ? '#dcf8c6' : '#fff',
  alignSelf: sender === 'You' ? 'flex-start' : 'flex-end',
  whiteSpace: 'pre-wrap' // Preserve whitespace and newlines
}));

interface ChatBotProps {
  chatbotUrl: string;
}

const ChatBot: React.FC<ChatBotProps> = ({ chatbotUrl }) => {
  const [messages, setMessages] = useState<{ text: string; sender: string }[]>([]);
  const [inputMessage, setInputMessage] = useState<string>('');
  const [isTyping, setIsTyping] = useState<boolean>(false);

  const { transcript, resetTranscript } = useSpeechRecognition();

  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Scroll to bottom on messages update
    if (chatContainerRef.current) {
      const chatBody = chatContainerRef.current.querySelector('.chat-body');
      if (chatBody) {
        chatBody.scrollTop = chatBody.scrollHeight;
      }
    }
  }, [messages]);

  const handleMessageSend = async () => {
    if (inputMessage.trim() !== '') {
      setMessages(prevMessages => [...prevMessages, { text: inputMessage, sender: 'You' }]);
      setInputMessage('');
      setIsTyping(true);

      try {
        const headers = getAuthToken() as AuthTokenType;
        const response: AxiosResponse = await axios.post(
          chatbotUrl,
          { message: inputMessage },
          headers
        );
        if (response.data) {
          // Split response data on '**' and process each part
          const formattedText = response.data.split('**').join('<br /><br />');
          setMessages(prevMessages => [
            ...prevMessages,
            { text: formattedText, sender: 'AI Companion' },
          ]);
        } else {
          console.error('Unexpected response format:', response);
          setMessages(prevMessages => [
            ...prevMessages,
            { text: 'Error: Unexpected response format', sender: 'System' },
          ]);
        }
      } catch (error) {
        console.error('Error sending message to backend:', error);
        setMessages(prevMessages => [
          ...prevMessages,
          { text: 'Error: Failed to send message to backend', sender: 'System' },
        ]);
      } finally {
        setIsTyping(false);
      }
    }
  };

  const handleStartListening = () => {
    SpeechRecognition.startListening({ continuous: true });
  };

  const handleStopListening = () => {
    SpeechRecognition.stopListening();
    if (transcript.trim() !== '') {
      setInputMessage(prevInputMessage => prevInputMessage + ' ' + transcript);
    }
    resetTranscript();
  };

  return (
    <ChatContainer ref={chatContainerRef}>
      <ChatHeader>Solek AI Assistance</ChatHeader>
      <ChatBody className="chat-body">
        {messages.map((message, index) => (
          <MessageBubble key={index} sender={message.sender}>
            <div dangerouslySetInnerHTML={{ __html: message.text }} />
          </MessageBubble>
        ))}
        {isTyping && <MessageBubble sender="AI Companion">Typing...</MessageBubble>}
      </ChatBody>
      <ChatInput>
        <InputField
          placeholder="Type your message..."
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          variant="outlined"
          multiline
          rows={3}
        />
        <IconButton color="primary" onClick={handleMessageSend}>
          <SendIcon />
        </IconButton>
        <IconButton
          color="primary"
          onMouseDown={handleStartListening}
          onMouseUp={handleStopListening}
          onMouseOut={handleStopListening}
        >
          <MicIcon />
        </IconButton>
      </ChatInput>
    </ChatContainer>
  );
};

export default ChatBot;