import React, { FC, useState, useEffect } from "react";
import { Drawer, notification, Form, Input, Button, Switch } from "antd";
import { useTranslation } from "react-i18next"; // Import the translation hook
import { AuthTokenType, DataProps, unitofmeasure, AddUnitofmeasureFormProps } from "../utils/types";
import { getAuthToken } from "../utils/functions";
import axios, { AxiosResponse } from "axios";
import { unitofmeasuresUrl } from "../utils/network";

const AddUnitofmeasureForm: FC<AddUnitofmeasureFormProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose,
  editingUnitofmeasure,
  onCloseWithoutEditing, // new prop
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(); // Initialize the translation hook

  useEffect(() => {
    if (editingUnitofmeasure) {
      form.setFieldsValue({
        ...editingUnitofmeasure,
        status: editingUnitofmeasure.is_active === true, // Convert to boolean
      });
    } else {
      form.resetFields();
    }
  }, [editingUnitofmeasure, form]);

  const handleFormClose = () => {
    form.resetFields();
    onClose?.();
    if (!form.isFieldsTouched()) {
      onCloseWithoutEditing?.(); // Notify the parent about closing without editing
    }
  };

  const onSubmit = async (values: DataProps) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

    try {
      let response: AxiosResponse;

      if (editingUnitofmeasure) {
        // Editing unit of measure
        response = await axios.put(`${unitofmeasuresUrl}/${editingUnitofmeasure.id}/`, values, headers);
      } else {
        // Adding new unit of measure
        response = await axios.post(unitofmeasuresUrl + '/', values, headers);
      }

      setLoading(false);

      if (response) {
        onSuccessCallBack?.();
        onClose?.(); // Close the drawer
      }
    } catch (error) {
      notification.error({
        message: t("Operation Error"), // Translate the error message
      });
      setLoading(false);
    }
  };

  return (
    <Drawer
      title={editingUnitofmeasure ? t("Edit Unit of Measure") : t("Add Unit of Measure")} // Translate the title
      visible={isVisible}
      onClose={handleFormClose}
      destroyOnClose
      width={360}
    >
      <Form
        layout="vertical"
        onFinish={onSubmit}
        form={form}
      >
        <Form.Item
          label={t("Name")} // Translate the label
          name="name"
          rules={[{ required: true, message: t('Please input the unit of measure name!') }]} 
        >
          <Input placeholder={t("Unit of Measure Name")} /> 
        </Form.Item>
        <Form.Item
          label={t("Is Active")} // Translate the label
          name="is_active"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item>
          <Button className="bbutton" htmlType="submit" type="primary" block loading={loading}>
            {editingUnitofmeasure ? t("Update") : t("Submit")}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddUnitofmeasureForm;