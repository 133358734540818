import React, { FC, useState, useEffect } from "react";
import { Button, Input, Modal, notification } from "antd";
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import { useGetRequirements, useGetRequirementscecoowner, useGetRequirementsmanager } from "../utils/hooks";
import { AuthTokenType,Requirement} from "../utils/types";
import { getAuthToken, getRequirements, getRequirementscecoowner, getRequirementsmanager } from "../utils/functions";
import axios from "axios";
import { PChatbotUrl, RequirementURL, RequirementViewSetapprovalsURL } from "../utils/network";
import ContentLayout from "../components/ContentLayout";
import AddRequirements from "./AddRequirements";
import ChatBot from "../components/Chatbot";
import ContentLayoutTabs from "../components/ContentLayoutTabs";
import RequirementDrawer from "./RequirementsDrawer";

const RequirementsCECOOwner: FC = () => {
  const { t } = useTranslation(); // Initialize the useTranslation hook


  const columns = [
    {
      title: t("ID"), // Translate the title
      dataIndex: "id",
      key: "id",
      fixed: "left", // Fix the column to the right

      sorter: (a: Requirement, b: Requirement) => a.id - b.id, 
    },
    {
      title: t("Requester"), // Translate the title
      dataIndex: "requester1",
      key: "requester1",
    },
    {
      title: t("Project"), // Translate the title
      dataIndex: "project1",
      key: "project1",
    },
    {
      title: t("Project MW"), // Translate the title
      dataIndex: "projectmw",
      key: "projectmw",
    },
    {
      title: t("Budget Code"), // Translate the title
      dataIndex: "budgetcode1",
      key: "budgetcode1",
    },

    {
      title: t("CECO"), // Translate the title
      dataIndex: "ceco1",
      key: "ceco1",
    },
    {
      title: t("Requirement Id"), // Translate the title
      dataIndex: "r_id",
      key: "r_id",
    },  
    {
      title: t("Item Description"), // Translate the title
      dataIndex: "item_description",
      key: "item_description",
    },
    {
      title: t("Requirement Type"), // Translate the title
      dataIndex: "requirement_type",
      key: "requirement_type",
    },    
    {
      title: t("Process Specification"), // Translate the title
      dataIndex: "process_specification1",
      key: "process_specification1",
    },
    {
      title: t("Original Date"), // Translate the title
      dataIndex: "original_date1",
      key: "original_date1",
    },
    {
      title: t("Days from Req"), // Translate the title
      dataIndex: "days",
      key: "days",
    },
    {
      title: t("Buyer"), // Translate the title
      dataIndex: "buyer1",
      key: "buyer1",
    },
    {
      title: t("Status"), // Translate the title
      dataIndex: "status1",
      key: "status1",
    },
    {
      title: t("PO Number"), // Translate the title
      dataIndex: "po_number",
      key: "po_number",
    },
    {
      title: t("Supplier"), // Translate the title
      dataIndex: "supplier",
      key: "supplier",
    },

    {
      title: t("Net Value"), // Translate the title
      dataIndex: "net_value",
      key: "net_value",
    },

    {
      title: t("Delivery Term"), // Translate the title
      dataIndex: "deliveryterm1",
      key: "deliveryterm1",
    },    

    {
      title: t("Project Manager"), // Translate the title
      dataIndex: "project_manager1",
      key: "project_manager1",
    },   

  
    {
      title: t("Client"), // Translate the title
      dataIndex: "projectclient",
      key: "projectclient",
    },

    {
      title: t("Business Line"), // Translate the title
      dataIndex: ["business_line","name"],
      key: ["business_line","name"],
    },    
    {
      title: t("Category"), // Translate the title
      dataIndex: "category",
      key: "category",
    },    
    {
      title: t("Sub Category"), // Translate the title
      dataIndex: "subcategory",
      key: "subcategory",
    },    
    {
      title: t("Sub Supplies"), // Translate the title
      dataIndex: "sub_supplies",
      key: "sub_supplies",
    },    
    {
      title: t("CECO Owner's Approval"), // Translate the title
      dataIndex: "ceco_owner_approval",
      key: "ceco_owner_approval",
    },
    {
      title: t("CECO Owner's Comments"), // Translate the title
      dataIndex: "ceco_owner_comments",
      key: "ceco_owner_comments",
    },
    {
      title: t("Manager's Approval"), // Translate the title
      dataIndex: "manager_approval",
      key: "manager_approval",
    },
    {
      title: t("Manager's Comments"), // Translate the title
      dataIndex: "manager_comments",
      key: "manager_comments",
    },
    
    {
      title: t("Controller's Approval"), // Translate the title
      dataIndex: "controller_approval",
      key: "controller_approval",

    },  
    {
      title: t("Controller's Comments"), // Translate the title
      dataIndex: "controller_comments",
      key: "controller_comments",
    },
    {
      title: t("Buyer's Comments"), // Translate the title
      dataIndex: "buyer_comments",
      key: "buyer_comments",
    },

    {
      title: t("Currency"), // Translate the title
      dataIndex: "currency1",
      key: "currency1",
    },

    {
      title: t("Process Categorization"), // Translate the title
      dataIndex: "process_categorization1",
      key: "process_categorization1",
    },

    {
      title: t("Cerrada"), // Translate the title
      dataIndex: "cerrada",
      key: "cerrada",
    },
 
    {
      title: t("Actions"), // Translate the title
      key: "actions",
      fixed: "right", // Fix the column to the right

      render: (text: string, record: Requirement) => {
        // Determine if manager_approval is Approved
        const iscecoownerapproved = record.ceco_owner_approval === "Approved";
    
        return (
          
                    <span>
        <a onClick={() => handleViewFiles(record)}>{t("View")}</a>

    <span> | </span>

            {/* Render Approve link only if manager_approval is not Approved */}
            {!iscecoownerapproved && (
              <>
                <a onClick={() => handleApprove(record.id)}>{t("Approve")}</a>
                <span> | </span>
                <a onClick={() => handleReject(record.id)}>{t("Reject")}</a>
                <span> | </span>
              </>
            )}
          </span>
        );
      },
    },
  ];

  const [Requirements, setRequirements] = useState<Requirement[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [drawerVisible1, setDrawerVisible1] = useState(false);
  const [selectedrequirementId, setSelectedrequirementId] = useState<Requirement | null>(null);
  
  const handleViewFiles = (record:Requirement) => {
    setSelectedrequirementId(record);
    setDrawerVisible1(true);
  };

  useGetRequirementscecoowner(setRequirements, setFetching);

  const [comment, setComment] = useState(""); // State for manager comment

  const handleReject = (RequirementId: number) => {
    const localComment = { value: "" }; // Use an object to hold the comment
  
    Modal.confirm({
      title: t("Confirm Reject"),
      content: (
        <>
          <p>{t("Are you sure you want to Reject this record?")}</p>
          <Input.TextArea
            rows={4}
            placeholder={t("Enter your comment here...")}
            onChange={(e) => (localComment.value = e.target.value)} // Update local variable on change
          />
        </>
      ),
      onOk: async () => {
        try {
          const datatosend: any = {
            ceco_owner_approval: "Rejected",
            ceco_owner_comments: localComment.value, // Use the value from the object
          };
  
          const headers = getAuthToken() as AuthTokenType;
          await axios.patch(`${RequirementURL}/${RequirementId}/`, datatosend, headers);
  
          // Fetch updated records after rejection
          getRequirementsmanager(setRequirements, setFetching);
        } catch (error) {
          notification.error({
            message: t("Approval Operation Error"),
          });
        }
      },
      onCancel: () => {
        // Reset state if needed
      },
      okText: t("Yes"),
      cancelText: t("No"),
    });
  };
  
  const handleApprove = (RequirementId: number) => {
    const localComment = { value: "" }; // Use an object to hold the comment
  
    Modal.confirm({
      title: t("Confirm Approve"),
      content: (
        <>
          <p>{t("Are you sure you want to Approve this record?")}</p>
          <Input.TextArea
            rows={4}
            placeholder={t("Enter your comment here...")}
            onChange={(e) => (localComment.value = e.target.value)} // Update local variable on change
          />
        </>
      ),
      onOk: async () => {
        try {
          const datatosend: any = {
            ceco_owner_approval: "Approved",
            ceco_owner_comments: localComment.value, // Use the value from the object
          };
  
          const headers = getAuthToken() as AuthTokenType;
          await axios.patch(`${RequirementViewSetapprovalsURL}${RequirementId}/`, datatosend, headers);
  
          // Fetch updated records after approval
          getRequirementsmanager(setRequirements, setFetching);
        } catch (error) {
          notification.error({
            message: t("Approval Operation Error"),
          });
        }
      },
      onCancel: () => {
        // Reset state if needed
      },
      okText: t("Yes"),
      cancelText: t("No"),
    });
  };
  const handleDelete = (RequirementId: number) => {
    Modal.confirm({
      title: t("Confirm Deletion"), // Translate the title
      content: t("Are you sure you want to delete this record?"), // Translate the content
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;
          // Make a DELETE request to the API endpoint for deleting a location
          await axios.delete(`${RequirementViewSetapprovalsURL}${RequirementId}/`, headers);

          // After successful deletion, fetch updated locations
          getRequirementsmanager(setRequirements, setFetching);
        } catch (error) {
          // Handle error if the deletion fails
          notification.error({
            message: t("Delete Operation Error"), // Translate the message
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: t("Yes"), // Translate the text
      cancelText: t("No"), // Translate the text
    });
  };

 


  return (
    <div>
    <div>
    <ContentLayoutTabs
      pageTitle={t("CECO Owner's View")} // Translate the title
      dataSource={Requirements as any}
      columns={columns as any}
      fetching={fetching}
      includeFilters={true}

    >

    </ContentLayoutTabs>
      </div>  
      <RequirementDrawer
        requirement={selectedrequirementId}
        visible={drawerVisible1}
        onClose={() => {
          setSelectedrequirementId(null);
          setDrawerVisible1(false);
        }}
      />
      </div>
  );
  
};

export default RequirementsCECOOwner;