import ReactDOM from "react-dom";
import App from "./App"
import React from 'react';
import StoreProvider from "./utils/store";
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en.json'; // English translation file
import esTranslation from './locales/es.json'; // Spanish translation file
import csTranslation from './locales/cs.json'; // Spanish translation file

// Initialize i18n
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      es: {
        translation: esTranslation,
      },
      cs: {
        translation: csTranslation,
      },
    },
    lng: 'en', // Default language
    interpolation: {
      escapeValue: false,
    },
  });

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root')
);