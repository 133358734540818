import React, { FC, useEffect, useState } from "react";
import { Button, Table, Modal, notification, Drawer } from "antd";
import { useGetUsers } from "../utils/hooks";
import { AuthTokenType, UserProps } from "../utils/types";
import { getAuthToken, getUsers } from "../utils/functions";
import AddUserForm from "../components/AddUserForm";
import axios from "axios";
import {UsersUrl } from "../utils/network";

const User: FC = () => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Name',
      dataIndex: 'fullname',
      key: 'fullname',
    },
    {
      title: 'Is_Active',
      dataIndex: 'is_active',
      key: 'is_active',
    },
    {
      title: 'Last Login',
      dataIndex: 'last_login',
      key: 'last_login',
    },
    {
      title: 'Sub Module',
      dataIndex: 'submodule',
      key: 'submodule',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Designation',
      dataIndex: 'designation',
      key: 'designation',
    },
    {
      title: 'Created_At',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text: string, record: UserProps) => (
        <span>
          <a onClick={() => handleEdit(record)}>Edit</a>
          <span> | </span>
          <a onClick={() => handleDelete(record.id)}>Delete</a>
        </span>
      ),
    },
  ];

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingUser, setEditingUser] = useState<UserProps | null>(null);
  const [fetching, setFetching] = useState(true);
  const [users, setUsers] = useState<UserProps[] | undefined>();

  useGetUsers(setUsers, setFetching);

  const onCloseWithoutEditing = () => {
    setEditingUser(null); // Reset editingCategory when form is closed without editing
  };

  useEffect(() => {
    // When editingUser changes, open the drawer
    if (editingUser) {
      setDrawerVisible(true);
    }
  }, [editingUser]);

  const handleEdit = (record: UserProps) => {
    setEditingUser(record);
  };

  const handleDelete = (userId: number) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this user?",
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;
          // Make a DELETE request to the API endpoint for deleting a user
          await axios.delete(`${UsersUrl}/${userId}`, headers);

          // After successful deletion, fetch updated users
          getUsers(setUsers, setFetching);
        } catch (error) {
          // Handle error if the deletion fails
          notification.error({
            message: "Delete Operation Error",
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: "Yes",
      cancelText: "No",
    });
  };

  const onCreateUser = () => {
    setDrawerVisible(true);
    setEditingUser(null); // Reset editingUser for new user
  };

  return (
    <div className="card">
      <div className="cardHeader">
        <h2 className="headContent">Users</h2>
        <div className="rightContent">
          <Button onClick={onCreateUser}>Add User</Button>
        </div>
      </div>
      <br />
      <Table
        className="custom-table"
        dataSource={(users as unknown) as UserProps[]}
        columns={columns}
        pagination={{ pageSize: 20 }}
        size="small"
        loading={fetching}
      />
      <AddUserForm
        onSuccessCallBack={() => {
          setDrawerVisible(false);
          getUsers(setUsers, setFetching);
        }}
        isVisible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        editingUser={editingUser}
        onCloseWithoutEditing={onCloseWithoutEditing}

      />
    </div>
  );
};

export default User;