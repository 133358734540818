import React, { FC, useState, useEffect } from "react";
import { Drawer, notification, Form, Input, Button } from "antd";
import { AuthTokenType, DataProps, AddDeliveryTermFormProps } from "../utils/types";
import { getAuthToken } from "../utils/functions";
import axios, { AxiosResponse } from "axios";
import { DeliveryTermURL } from "../utils/network";
import { useTranslation } from "react-i18next"; // Import useTranslation hook

const AddDeliveryTerms: FC<AddDeliveryTermFormProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose,
  editingDeliveryTerms,
  onCloseWithoutEditing, // new prop
}) => {
  const { t } = useTranslation(); // Initialize the useTranslation hook
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (editingDeliveryTerms) {
      form.setFieldsValue(editingDeliveryTerms);
    } else {
      form.resetFields();
    }
  }, [editingDeliveryTerms, form]);

  const handleFormClose = () => {
    form.resetFields();
    onClose?.();
    if (!form.isFieldsTouched()) {
      onCloseWithoutEditing?.(); // Notify the parent about closing without editing
    }
  };

  const onSubmit = async (values: DataProps) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

    try {
      let response: AxiosResponse;

      if (editingDeliveryTerms) {
        // Editing location
        response = await axios.put(`${DeliveryTermURL}/${editingDeliveryTerms.id}/`, values, headers);
      } else {
        // Adding new location
        response = await axios.post(DeliveryTermURL + '/', values, headers);
      }

      setLoading(false);

      if (response) {
        onSuccessCallBack?.();
        onClose?.(); // Close the drawer
      }
    } catch (error) {
      notification.error({
        message: t("Operation Error"), // Translate the error message
      });
      setLoading(false);
    }
  };

  return (
    <Drawer
      title={editingDeliveryTerms ? t("Edit DeliveryTerm") : t("Add DeliveryTerm")} 
      visible={isVisible}
      onClose={handleFormClose}
      destroyOnClose
      width={360}
    >
      <Form
        layout="vertical"
        onFinish={onSubmit}
        form={form}
        onValuesChange={() => setHasChanges(true)}
      >
        <Form.Item
          label={t("Term")} 
          name="term"
          rules={[{ required: true, message: t('Please input the DeliveryTerm name!') }]}
        >
          <Input placeholder={t("DeliveryTerm")} /> 
        </Form.Item>
 
        <Form.Item>
          <Button className="bbutton" htmlType="submit" type="primary" block loading={loading}>
            {editingDeliveryTerms ? t("Update") : t("Submit")} 
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddDeliveryTerms;